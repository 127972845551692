/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends " $fragmentName" | "__typename"
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /**
     * The `Date` scalar type represents a Date
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Date: { input: string; output: string };
    /**
     * The `DateTime` scalar type represents a DateTime
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    DateTime: { input: string; output: string };
    EmailAddress: { input: string; output: string };
    /**
     * Allows use of a JSON String for input / output from the GraphQL schema.
     *
     * Use of this type is *not recommended* as you lose the benefits of having a defined, static
     * schema (one of the key benefits of GraphQL).
     */
    JSONString: { input: string; output: string };
    PhoneNumber: { input: string; output: string };
    /**
     * The `Time` scalar type represents a Time value as
     * specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Time: { input: string; output: string };
};

export type AcceptedDocument = {
    invoiceCode: Scalars["String"]["output"];
    uid: Scalars["ID"]["output"];
};

export type AccessError = {
    message: Scalars["String"]["output"];
};

export type ActivateLightEventsRequest = {
    groupIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type ActivateLightEventsResponse =
    | AccessError
    | LightEventsActivated
    | NotFoundError;

export type ActivatePromoCodeRequest = {
    code: Scalars["ID"]["input"];
    endDt: Scalars["DateTime"]["input"];
    startDt: Scalars["DateTime"]["input"];
};

export type ActivatePromoCodeResponse = NotFoundError | PromoCodeActivated;

export type AddAssociatedUserRequest = {
    invoiceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
    userId: Scalars["ID"]["input"];
};

export type AddAssociatedUserResponse =
    | AccessError
    | AssociatedUserAdded
    | NotFoundError;

export type AddBookingGuestRequest = {
    bookingId: Scalars["ID"]["input"];
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    guestName: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    tenantId: Scalars["ID"]["input"];
    userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AddBookingGuestResponse =
    | AccessError
    | BookingGuestAdded
    | NotFoundError;

export type AddBookingGuestToBookingsRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    guestName: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    tenantId: Scalars["ID"]["input"];
    userId: Scalars["ID"]["input"];
};

export type AddBookingGuestToBookingsResponse =
    | AccessError
    | BookingGuestAddedToBookings
    | NotFoundError;

export type AddMemberToOrganisationRequest = {
    capabilities: Array<Scalars["String"]["input"]>;
    memberEmail: Scalars["EmailAddress"]["input"];
    metadata: Scalars["JSONString"]["input"];
    organisationId: Scalars["ID"]["input"];
    role?: InputMaybe<MemberRole>;
};

export type AddMemberToOrganisationResponse =
    | AccessError
    | MemberAlreadyAddedError
    | NotFoundError
    | OrganisationMemberAdded;

export type AddMemberToOrganisationWithoutFirebaseRequest = {
    capabilities: Array<Scalars["String"]["input"]>;
    memberName: Scalars["String"]["input"];
    memberUserId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    organisationId: Scalars["ID"]["input"];
};

export type AddMemberToOrganisationWithoutFirebaseResponse =
    | AccessError
    | MemberAlreadyAddedError
    | NotFoundError
    | OrganisationMemberAdded;

export type AddOn = {
    created: Scalars["DateTime"]["output"];
    isInStock: Scalars["Boolean"]["output"];
    maxQuantity?: Maybe<Scalars["Int"]["output"]>;
    name: Scalars["String"]["output"];
    price: Scalars["Int"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type AddOnAlreadyArchived = {
    message: Scalars["String"]["output"];
};

export type AddOnArchived = {
    addOnId: Scalars["ID"]["output"];
};

export type AddOnCreated = {
    addOnId: Scalars["ID"]["output"];
    maxQuantity?: Maybe<Scalars["Int"]["output"]>;
    name: Scalars["String"]["output"];
    price: Scalars["Int"]["output"];
};

export type AddOnIsInStockSet = {
    addOnId: Scalars["ID"]["output"];
    isInStock: Scalars["Boolean"]["output"];
};

export type AddOnModified = {
    addOnId: Scalars["ID"]["output"];
    maxQuantity?: Maybe<Scalars["Int"]["output"]>;
    name: Scalars["String"]["output"];
    price: Scalars["Int"]["output"];
};

export type AddPaymentMethodRequest = {
    availability: Scalars["Boolean"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type AddPaymentMethodResponse = AccessError | PaymentMethodAdded;

export type AddServiceResourceRequest = {
    optionKey?: InputMaybe<Scalars["String"]["input"]>;
    resourceId: Scalars["ID"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type AddServiceResourceResponse =
    | AccessError
    | NotFoundError
    | ServiceResourceAdded
    | UnableToAddServiceResource;

export type Address = {
    addressLine1: Scalars["String"]["input"];
    addressLine2?: InputMaybe<Scalars["String"]["input"]>;
    addressLine3?: InputMaybe<Scalars["String"]["input"]>;
    city: Scalars["String"]["input"];
    postcode: Scalars["String"]["input"];
    state: Scalars["String"]["input"];
};

export type AllowDatesRule = Rule & {
    created: Scalars["DateTime"]["output"];
    dates: Array<Scalars["Date"]["output"]>;
    uid: Scalars["ID"]["output"];
};

export type AllowFromDatetimeRule = Rule & {
    created: Scalars["DateTime"]["output"];
    effectiveFrom: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type AllowMaximumAdvancedBookingTimeRule = Rule & {
    created: Scalars["DateTime"]["output"];
    maxAdvancedBookingDays: Scalars["Int"]["output"];
    uid: Scalars["ID"]["output"];
};

export type AllowMinimumAdvancedBookingTimeRule = Rule & {
    created: Scalars["DateTime"]["output"];
    minAdvancedBookingMinutes: Scalars["Int"]["output"];
    uid: Scalars["ID"]["output"];
};

export type AllowToDatetimeRule = Rule & {
    created: Scalars["DateTime"]["output"];
    effectiveTo: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

/** An enumeration. */
export type Amenities =
    | "AED"
    | "AIRCON"
    | "CARPARK"
    | "CARWASH"
    | "CHANGINGROOM"
    | "DRINKS"
    | "EVCHARGER"
    | "FOOD"
    | "HOSTEL"
    | "NONSMOKING"
    | "PERFORMANCETRAININGCENTRE"
    | "PETFRIENDLY"
    | "PRIVATEBATHROOM"
    | "REHABILITATIONCENTRE"
    | "SHOP"
    | "SHOWER"
    | "SURAU"
    | "TOILETRIES"
    | "TOILETS"
    | "TV"
    | "TWOSINGLEBEDS"
    | "WIFI";

export type ApproveOrganisationRequest = {
    organisationId: Scalars["ID"]["input"];
};

export type ApproveOrganisationResponse =
    | AccessError
    | NotFoundError
    | OrganisationApproved;

export type ApprovedOrganisationsFilter = {
    byCourtsitePartner?: InputMaybe<Scalars["Boolean"]["input"]>;
    byLocations?: InputMaybe<Array<LocationFilter>>;
    byMaxDistance?: InputMaybe<Scalars["Int"]["input"]>;
    byServices?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type ArchiveAddOnRequest = {
    addOnId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveAddOnResponse =
    | AccessError
    | AddOnAlreadyArchived
    | AddOnArchived
    | NotFoundError;

export type ArchiveCustomerRequest = {
    customerId?: InputMaybe<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveCustomerResponse =
    | AccessError
    | CustomerAlreadyArchived
    | CustomerArchived
    | NotFoundError;

export type ArchiveEventsRequest = {
    groupId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveEventsResponse = AccessError | EventsArchived;

export type ArchiveGameRequest = {
    uid: Scalars["ID"]["input"];
};

export type ArchiveGameResponse = AccessError | GameArchived | NotFoundError;

export type ArchiveLocationRequest = {
    locationId: Scalars["ID"]["input"];
};

export type ArchiveLocationResponse =
    | AccessError
    | LocationArchived
    | NotFoundError;

export type ArchiveMemberRequest = {
    memberId: Scalars["ID"]["input"];
    organisationId: Scalars["ID"]["input"];
};

export type ArchiveMemberResponse =
    | AccessError
    | CannotModifySelfError
    | MemberAlreadyArchivedError
    | MemberArchived
    | NotFoundError;

export type ArchivePaymentMethodRequest = {
    paymentMethodId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchivePaymentMethodResponse =
    | AccessError
    | NotFoundError
    | PaymentMethodAlreadyArchived
    | PaymentMethodArchived;

export type ArchivePromoCodeRequestV2 = {
    promoCodeId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchivePromoCodeResponseV2 =
    | AccessError
    | NotFoundError
    | PromoCodeArchivedV2;

export type ArchiveResourceLinksRequest = {
    resourceLinkIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveResourceLinksResponse =
    | AccessError
    | NotFoundError
    | ResourceLinksAlreadyArchived
    | ResourceLinksArchived;

export type ArchiveResourceRequest = {
    resourceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveResourceResponse =
    | AccessError
    | ResourceArchived
    | UnableToArchiveResource;

export type ArchiveResourceTypeRequest = {
    resourceTypeId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveResourceTypeResponse =
    | AccessError
    | NotFoundError
    | ResourceTypeArchived
    | UnableToArchiveResourceType;

export type ArchiveScheduleRequest = {
    scheduleId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveScheduleResponse =
    | AccessError
    | NotFoundError
    | ScheduleArchived;

export type ArchiveServiceRequest = {
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveServiceResourceRequest = {
    resourceId: Scalars["ID"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveServiceResourceResponse =
    | AccessError
    | NotFoundError
    | ServiceResourceArchived;

export type ArchiveServiceResponse =
    | AccessError
    | NotFoundError
    | ServiceAlreadyArchived
    | ServiceArchived;

export type ArchiveSettlementRequest = {
    settlementId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveSettlementResponse =
    | AccessError
    | NotFoundError
    | SettlementArchived;

export type ArchiveTagRequest = {
    tagId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ArchiveTagResponse = AccessError | NotFoundError | TagArchived;

export type ArchiveTriggerRequest = {
    tenantId: Scalars["ID"]["input"];
    triggerId: Scalars["ID"]["input"];
};

export type ArchiveTriggerResponse =
    | AccessError
    | NotFoundError
    | TriggerArchived;

export type AssignServiceToCategoryRequest = {
    categoryId: Scalars["ID"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type AssignServiceToCategoryResponse =
    | AccessError
    | NotFoundError
    | ServiceCategoryAssigned;

export type AssociatedUserAdded = {
    invoiceId: Scalars["ID"]["output"];
};

export type BadRequestError = {
    message: Scalars["String"]["output"];
};

export type BillplzSessionInitiated = {
    externalId: Scalars["ID"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    paymentUrl: Scalars["String"]["output"];
};

export type BookedSlot = {
    addons?: Maybe<Array<Maybe<BookingAddOn>>>;
    bookingId: Scalars["ID"]["output"];
    bookingSource?: Maybe<BookingSource>;
    endDt: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    recurringId?: Maybe<Scalars["ID"]["output"]>;
    selectedResourceIds: Array<Scalars["ID"]["output"]>;
    serviceId: Scalars["ID"]["output"];
    startDt: Scalars["DateTime"]["output"];
};

export type Booking = {
    activities?: Maybe<Array<Maybe<BookingActivity>>>;
    addons?: Maybe<Array<Maybe<BookingAddOn>>>;
    bookingSource?: Maybe<BookingSource>;
    cancelled?: Maybe<Scalars["DateTime"]["output"]>;
    cancelledReason?: Maybe<CancelledReason>;
    confirmed?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    endDt: Scalars["DateTime"]["output"];
    guest?: Maybe<BookingGuest>;
    guests?: Maybe<Array<Maybe<BookingGuest>>>;
    metadata: Scalars["JSONString"]["output"];
    recurringId?: Maybe<Scalars["ID"]["output"]>;
    referenceId: Scalars["String"]["output"];
    resources: Array<BookingResource>;
    service?: Maybe<Service>;
    serviceId?: Maybe<Scalars["ID"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type BookingActivity = {
    occuredAt: Scalars["DateTime"]["output"];
    performedBy: Scalars["String"]["output"];
};

export type BookingAddOn = {
    addonId: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    perUnitPrice: Scalars["Int"]["output"];
    quantity: Scalars["Int"]["output"];
    uid: Scalars["ID"]["output"];
};

export type BookingAddOnRequest = {
    addonId: Scalars["ID"]["input"];
    perUnitPrice: Scalars["Int"]["input"];
    quantity: Scalars["Int"]["input"];
};

export type BookingAlreadyCancelledError = {
    message: Scalars["String"]["output"];
};

export type BookingAlreadyConfirmedError = {
    message: Scalars["String"]["output"];
};

export type BookingAlreadyUnconfirmedError = {
    message: Scalars["String"]["output"];
};

export type BookingCancelled = {
    bookingCancelled: Scalars["DateTime"]["output"];
    bookingId: Scalars["ID"]["output"];
};

export type BookingCancelledActivity = BookingActivity & {
    cancelledReason?: Maybe<CancelledReason>;
    newEndDt?: Maybe<Scalars["DateTime"]["output"]>;
    newResourceIds?: Maybe<Array<Scalars["ID"]["output"]>>;
    newStartDt?: Maybe<Scalars["DateTime"]["output"]>;
    occuredAt: Scalars["DateTime"]["output"];
    oldEndDt?: Maybe<Scalars["DateTime"]["output"]>;
    oldResourceIds?: Maybe<Array<Scalars["ID"]["output"]>>;
    oldStartDt?: Maybe<Scalars["DateTime"]["output"]>;
    performedBy: Scalars["String"]["output"];
};

export type BookingCancelledReasonModfied = {
    bookings: Array<Booking>;
};

export type BookingConfirmed = {
    bookingId: Scalars["ID"]["output"];
};

export type BookingConfirmedActivity = BookingActivity & {
    occuredAt: Scalars["DateTime"]["output"];
    performedBy: Scalars["String"]["output"];
};

export type BookingConnection = {
    edges: Array<BookingEdge>;
    pageInfo: PageInfo;
};

export type BookingCreatedActivity = BookingActivity & {
    endDt: Scalars["DateTime"]["output"];
    occuredAt: Scalars["DateTime"]["output"];
    performedBy: Scalars["String"]["output"];
    recurringId?: Maybe<Scalars["ID"]["output"]>;
    serviceId: Scalars["ID"]["output"];
    startDt: Scalars["DateTime"]["output"];
};

export type BookingEdge = {
    cursor: Scalars["String"]["output"];
    node: Booking;
};

export type BookingFilter = {
    byCancelReason?: InputMaybe<CancelledReason>;
    byName?: InputMaybe<Scalars["String"]["input"]>;
    byPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    byRecurringId?: InputMaybe<Scalars["ID"]["input"]>;
    byReferenceId?: InputMaybe<Scalars["ID"]["input"]>;
    byResourceId?: InputMaybe<Scalars["ID"]["input"]>;
    byServiceId?: InputMaybe<Scalars["ID"]["input"]>;
    byStartDate?: InputMaybe<DateRangeFilter>;
    byStatus?: InputMaybe<BookingStatus>;
};

export type BookingFilterCount = {
    bookingCount: Scalars["Int"]["output"];
    categoryId: Scalars["ID"]["output"];
};

export type BookingGroupModification = {
    bookingModifications: Array<BookingModification>;
    recurringSeriesModification?: InputMaybe<RecurringSeriesModification>;
};

export type BookingGuest = {
    created: Scalars["DateTime"]["output"];
    email?: Maybe<Scalars["EmailAddress"]["output"]>;
    name: Scalars["String"]["output"];
    phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    uid: Scalars["ID"]["output"];
    userId?: Maybe<Scalars["ID"]["output"]>;
};

export type BookingGuestAdded = {
    bookingId: Scalars["ID"]["output"];
};

export type BookingGuestAddedToBookings = {
    bookingIds: Array<Scalars["ID"]["output"]>;
};

export type BookingGuestChangedActivity = BookingActivity & {
    email?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    occuredAt: Scalars["DateTime"]["output"];
    oldEmail?: Maybe<Scalars["String"]["output"]>;
    oldName: Scalars["String"]["output"];
    oldPhoneNumber?: Maybe<Scalars["String"]["output"]>;
    performedBy: Scalars["String"]["output"];
    phoneNumber?: Maybe<Scalars["String"]["output"]>;
};

export type BookingGuestModification = {
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    guestId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type BookingGuestModified = {
    guestId: Scalars["ID"]["output"];
};

export type BookingGuestRemoved = {
    bookingIds: Array<Scalars["ID"]["output"]>;
};

export type BookingGuestRequest = {
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type BookingMetadataChangedActivity = BookingActivity & {
    changedMetadata: Scalars["JSONString"]["output"];
    occuredAt: Scalars["DateTime"]["output"];
    oldMetadata?: Maybe<Scalars["JSONString"]["output"]>;
    performedBy: Scalars["String"]["output"];
};

export type BookingMetadataUpdated = {
    bookingId: Scalars["ID"]["output"];
    metadata: Scalars["JSONString"]["output"];
};

export type BookingModification = {
    bookingId: Scalars["ID"]["input"];
    endDt: Scalars["DateTime"]["input"];
    guestChanges: Array<BookingGuestModification>;
    metadata: Scalars["JSONString"]["input"];
    resourceChanges: Array<BookingResourceChangeInput>;
    startDt: Scalars["DateTime"]["input"];
};

export type BookingNotCancelledError = {
    message: Scalars["String"]["output"];
};

export type BookingReferenceIdUpdated = {
    bookingId: Scalars["ID"]["output"];
    referenceId: Scalars["String"]["output"];
};

export type BookingReferenceIdChangedActivity = BookingActivity & {
    occuredAt: Scalars["DateTime"]["output"];
    performedBy: Scalars["String"]["output"];
    referenceId: Scalars["ID"]["output"];
};

export type BookingRescheduled = {
    bookingId: Scalars["ID"]["output"];
};

export type BookingRescheduledActivity = BookingActivity & {
    endDt: Scalars["DateTime"]["output"];
    newResourceIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
    occuredAt: Scalars["DateTime"]["output"];
    oldEndDt?: Maybe<Scalars["DateTime"]["output"]>;
    oldResourceIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
    oldStartDt?: Maybe<Scalars["DateTime"]["output"]>;
    performedBy: Scalars["String"]["output"];
    startDt: Scalars["DateTime"]["output"];
};

export type BookingResource = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    resource: Resource;
    resourceId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type BookingResourceChangeInput = {
    newResourceId: Scalars["ID"]["input"];
    targetResourceId: Scalars["ID"]["input"];
};

export type BookingSelectorRangeInput = {
    end: Scalars["Int"]["input"];
    start: Scalars["Int"]["input"];
};

export type BookingSlotRequest = {
    addons?: InputMaybe<Array<InputMaybe<BookingAddOnRequest>>>;
    endDt: Scalars["DateTime"]["input"];
    metadata: Scalars["JSONString"]["input"];
    selectedResourceIds: Array<Scalars["ID"]["input"]>;
    serviceId?: InputMaybe<Scalars["ID"]["input"]>;
    startDt: Scalars["DateTime"]["input"];
};

/** An enumeration. */
export type BookingSource = "ADMIN" | "WEB";

export type BookingStatistics = {
    bookingsHours: Scalars["Int"]["output"];
    bookingsMade: Scalars["Int"]["output"];
};

export type BookingStatus = {
    cancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
    confirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
    expired?: InputMaybe<Scalars["Boolean"]["input"]>;
    rescheduled?: InputMaybe<Scalars["Boolean"]["input"]>;
    unavailable?: InputMaybe<Scalars["Boolean"]["input"]>;
    unconfirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BookingSummary = {
    cancelled?: Maybe<Scalars["DateTime"]["output"]>;
    confirmed?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    endDt: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    referenceId: Scalars["String"]["output"];
    resources: Array<ResourceBookingSummary>;
    service: ServiceBookingSummary;
    startDt: Scalars["DateTime"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type BookingUnavailable = {
    reason: ServiceUnavailabilityReason;
};

export type BookingUnconfirmed = {
    bookingId: Scalars["ID"]["output"];
};

export type BookingUnconfirmedActivity = BookingActivity & {
    occuredAt: Scalars["DateTime"]["output"];
    performedBy: Scalars["String"]["output"];
};

export type BookingsCancelled = {
    bookings: Array<Booking>;
};

export type BookingsConfirmed = {
    bookings: Array<Booking>;
};

export type BookingsMade = {
    bookedSlots: Array<BookedSlot>;
    bookingIds: Array<Scalars["ID"]["output"]>;
    referenceId: Scalars["ID"]["output"];
};

export type BookingsModified = {
    bookings: Array<Booking>;
};

export type BookingsReferenceIdUpdated = {
    bookings: Array<Booking>;
};

export type BookingsRevived = {
    bookings: Array<Booking>;
};

export type BookingsUnavailable = {
    reason: ServiceUnavailabilityReason;
    unavailableSlots: Array<UnavailableBookingSlot>;
};

export type CancelBookingRequest = {
    bookingId: Scalars["ID"]["input"];
    cancelReason?: InputMaybe<CancelledReason>;
    newEndDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    newResourceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    newStartDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    oldEndDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    oldResourceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    oldStartDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type CancelBookingResponse =
    | AccessError
    | BookingAlreadyCancelledError
    | BookingCancelled
    | NotFoundError;

export type CancelBookingWorkflowBookingNotCancellable = {
    message: Scalars["String"]["output"];
};

export type CancelBookingWorkflowCompleted = {
    state: CancelBookingWorkflowState;
};

export type CancelBookingWorkflowState = {
    bookingId: Scalars["ID"]["output"];
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type CancelBookingsRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    cancelReason?: InputMaybe<CancelledReason>;
    newEndDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    newResourceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    newStartDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    oldEndDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    oldResourceIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    oldStartDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type CancelBookingsResponse =
    | AccessError
    | BookingAlreadyCancelledError
    | BookingsCancelled
    | NotFoundError;

export type CancelBookingsState = {
    bookingIds: Array<Scalars["String"]["output"]>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userId: Scalars["ID"]["output"];
};

export type CancelBookingsWorkflowBookingsNotCancellable = {
    message: Scalars["String"]["output"];
};

export type CancelBookingsWorkflowCompleted = {
    state: CancelBookingsState;
};

export type CancelCapturePaymentWorkflowRequest = {
    bookingIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    workflowId: Scalars["ID"]["input"];
};

export type CancelCapturePaymentWorkflowResponse =
    | CapturePaymentCancelled
    | WorkflowNotFound
    | WorkflowNotReady;

export type CancelCourtsiteCheckoutRequest = {
    checkoutId: Scalars["ID"]["input"];
};

export type CancelCourtsiteCheckoutResponse =
    | CourtsiteCheckoutNotCancellable
    | WorkflowCancelled
    | WorkflowNotFound;

export type CancelInvoiceRequest = {
    invoiceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CancelInvoiceResponse =
    | AccessError
    | InvoiceAlreadyCancelledError
    | InvoiceCancelled
    | NotFoundError;

export type CancelKonsolCheckoutRequest = {
    checkoutId: Scalars["ID"]["input"];
};

export type CancelKonsolCheckoutResponse =
    | KonsolCheckoutNotCancellable
    | WorkflowCancelled
    | WorkflowNotFound;

export type CancelKonsolPayInvoiceRequest = {
    workflowId: Scalars["ID"]["input"];
};

export type CancelKonsolPayInvoiceResponse =
    | KonsolPayInvoiceNotCancellable
    | WorkflowCancelled
    | WorkflowNotFound;

export type CancelPaymentRequest = {
    invoiceId: Scalars["ID"]["input"];
    paymentId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CancelPaymentResponse =
    | AccessError
    | NotFoundError
    | PaymentAlreadyCancelled
    | PaymentCancelled;

export type CancelPaymentSessionRequest = {
    paymentSessionId: Scalars["ID"]["input"];
    reason: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CancelPaymentSessionResponse =
    | AccessError
    | NotFoundError
    | PaymentSessionCancelled
    | PaymentSessionNotCancellable;

export type CancelPaymentWorkflowCompleted = {
    state: CancelPaymentWorkflowState;
};

export type CancelPaymentWorkflowPaymentNotCancellable = {
    message: Scalars["String"]["output"];
};

export type CancelPaymentWorkflowState = {
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    invoiceId: Scalars["ID"]["output"];
    paymentId: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

/** An enumeration. */
export type CancelledReason =
    | "CANCELLED"
    | "EXPIRED"
    | "RESCHEDULED"
    | "UNAVAILABLE";

export type CannotModifySelfError = {
    message: Scalars["String"]["output"];
};

export type CapturePaymentCancelled = {
    state: CapturePaymentState;
};

export type CapturePaymentConfirmed = {
    state: CapturePaymentState;
};

export type CapturePaymentForceRetried = {
    state: CapturePaymentState;
};

export type CapturePaymentProviderDetailsProvided = {
    state: CapturePaymentState;
};

export type CapturePaymentState = {
    amountToCapture?: Maybe<Scalars["Int"]["output"]>;
    attempts: Scalars["Int"]["output"];
    billplzPaymentUrl?: Maybe<Scalars["String"]["output"]>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    expired?: Maybe<Scalars["Time"]["output"]>;
    id: Scalars["ID"]["output"];
    invoiceId: Scalars["ID"]["output"];
    ipay88BackendUrl?: Maybe<Scalars["String"]["output"]>;
    ipay88RequestSignature?: Maybe<Scalars["String"]["output"]>;
    ipay88ResponseUrl?: Maybe<Scalars["String"]["output"]>;
    isCancellable: Scalars["Boolean"]["output"];
    kiplepayCallbackUrl?: Maybe<Scalars["String"]["output"]>;
    kiplepayHashValue?: Maybe<Scalars["String"]["output"]>;
    kiplepayMerchantId?: Maybe<Scalars["String"]["output"]>;
    kiplepayReferenceNo?: Maybe<Scalars["String"]["output"]>;
    kiplepayReturnUrl?: Maybe<Scalars["String"]["output"]>;
    mastercardSessionId?: Maybe<Scalars["String"]["output"]>;
    paymentSessionCreated?: Maybe<Scalars["Time"]["output"]>;
    paymentSessionId?: Maybe<Scalars["ID"]["output"]>;
    razorpayOrderId?: Maybe<Scalars["String"]["output"]>;
    razorpayResponseUrl?: Maybe<Scalars["String"]["output"]>;
    runId: Scalars["ID"]["output"];
    spayCallbackUrl?: Maybe<Scalars["String"]["output"]>;
    spayEncryptedPayload?: Maybe<Scalars["String"]["output"]>;
    spayOrderNo?: Maybe<Scalars["String"]["output"]>;
    spayQrCode?: Maybe<Scalars["String"]["output"]>;
    spayReturnUrl?: Maybe<Scalars["String"]["output"]>;
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type Category = {
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    onlineServices?: Maybe<Array<Maybe<Service>>>;
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CategoryOnlineServicesArgs = {
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CategoryCreated = {
    categoryId: Scalars["ID"]["output"];
};

export type CategoryFilter = {
    serviceMode?: InputMaybe<Scalars["String"]["input"]>;
    serviceVisibility?: InputMaybe<ServiceVisibility>;
};

export type CategoryMetadataUpdated = {
    categoryId: Scalars["ID"]["output"];
};

export type CategoryRenamed = {
    categoryId: Scalars["ID"]["output"];
};

export type ChangeResourceTypeRequest = {
    resourceId: Scalars["ID"]["input"];
    resourceTypeId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ChangeResourceTypeResponse =
    | AccessError
    | NotFoundError
    | ResourceTypeChanged
    | UnableToChangeResourceType;

export type CheckPaymentSessionRequest = {
    lastChancePoll?: InputMaybe<Scalars["Boolean"]["input"]>;
    paymentSessionId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CheckPaymentSessionResponse = {
    paymentSession: PaymentSession;
};

export type CheckoutDiscount = {
    discountType: Scalars["String"]["output"];
    discountedAmount: Scalars["Int"]["output"];
    initialAmount: Scalars["Int"]["output"];
    items: Array<CheckoutDiscountItemBreakdown>;
    total: Scalars["Int"]["output"];
};

export type CheckoutDiscountItem = {
    itemTypeId: Scalars["ID"]["input"];
    perUnitPrice: Scalars["Int"]["input"];
    quantity: Scalars["Int"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CheckoutDiscountItemBreakdown = {
    discountAmount: Scalars["Int"]["output"];
    itemTypeId: Scalars["ID"]["output"];
    perUnitPrice: Scalars["Int"]["output"];
    quantity: Scalars["Int"]["output"];
    subtotal: Scalars["Int"]["output"];
    tenantId: Scalars["ID"]["output"];
    total: Scalars["Int"]["output"];
};

export type Command = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    end: Scalars["DateTime"]["output"];
    light?: Maybe<Light>;
    lightId: Scalars["ID"]["output"];
    metadata: Scalars["JSONString"]["output"];
    start: Scalars["DateTime"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ConfigurationChangeSortOrderEnum = "BY_CREATED_ON_DATE_DESC";

export type ConfirmBookingRequest = {
    bookingId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ConfirmBookingResponse =
    | AccessError
    | BookingAlreadyCancelledError
    | BookingConfirmed
    | NotFoundError;

export type ConfirmBookingsRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type ConfirmBookingsResponse =
    | AccessError
    | BookingAlreadyCancelledError
    | BookingsConfirmed
    | NotFoundError;

export type ConfirmCapturePaymentRequest = {
    queryParams: Scalars["JSONString"]["input"];
    workflowId: Scalars["ID"]["input"];
};

export type ConfirmCapturePaymentResponse =
    | CapturePaymentConfirmed
    | WorkflowCancelled
    | WorkflowNotFound
    | WorkflowNotReady;

export type ConfirmPaymentSessionRequest = {
    paymentSessionId: Scalars["ID"]["input"];
    queryParams: Scalars["JSONString"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ConfirmPaymentSessionResponse =
    | AccessError
    | InternalError
    | NotFoundError
    | PaymentSessionConfirmed
    | PaymentSessionUnconfirmed;

export type Coordinates = {
    latitude: Scalars["Float"]["output"];
    longitude: Scalars["Float"]["output"];
};

export type CourtsiteBookingSlotInput = {
    endDt: Scalars["Time"]["input"];
    metadata: Scalars["JSONString"]["input"];
    selectedResourceIds: Array<Scalars["ID"]["input"]>;
    serviceId: Scalars["ID"]["input"];
    startDt: Scalars["Time"]["input"];
};

export type CourtsiteCheckoutAddOn = {
    addOnId: Scalars["ID"]["output"];
    claimDt: Scalars["Time"]["output"];
    name: Scalars["String"]["output"];
    price: Scalars["Int"]["output"];
    quantity: Scalars["Int"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type CourtsiteCheckoutAddOnInput = {
    addOnId: Scalars["ID"]["input"];
    claimDt: Scalars["Time"]["input"];
    quantity: Scalars["Int"]["input"];
};

export type CourtsiteCheckoutBooking = {
    deposit: Scalars["Int"]["output"];
    endDt: Scalars["Time"]["output"];
    price: Scalars["Int"]["output"];
    remainder: Scalars["Int"]["output"];
    resourceIds: Array<Scalars["String"]["output"]>;
    serviceId: Scalars["String"]["output"];
    startDt: Scalars["Time"]["output"];
    uid: Scalars["String"]["output"];
};

export type CourtsiteCheckoutBookingsUnavailable = {
    message: Scalars["String"]["output"];
};

export type CourtsiteCheckoutCustomEventLogged = {
    state: CourtsiteCheckoutState;
};

export type CourtsiteCheckoutExpired = {
    message: Scalars["String"]["output"];
};

export type CourtsiteCheckoutGuestInput = {
    email: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourtsiteCheckoutNotCancellable = {
    message: Scalars["String"]["output"];
};

export type CourtsiteCheckoutPaymentDetailsProvided = {
    state: CourtsiteCheckoutState;
};

export type CourtsiteCheckoutSlotAddOnInput = {
    addonID: Scalars["String"]["input"];
    perUnitPrice: Scalars["Int"]["input"];
    quantity: Scalars["Int"]["input"];
};

export type CourtsiteCheckoutSlotInput = {
    addons?: InputMaybe<Array<InputMaybe<CourtsiteCheckoutSlotAddOnInput>>>;
    endDt: Scalars["Time"]["input"];
    selectedResourceIds: Array<Scalars["ID"]["input"]>;
    serviceId: Scalars["ID"]["input"];
    startDt: Scalars["Time"]["input"];
};

export type CourtsiteCheckoutStarted = {
    state: CourtsiteCheckoutState;
};

export type CourtsiteCheckoutState = {
    addOns?: Maybe<Array<CourtsiteCheckoutAddOn>>;
    bookingIds?: Maybe<Array<Scalars["ID"]["output"]>>;
    bookingsMade: Array<CourtsiteCheckoutBooking>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    capturePaymentState?: Maybe<CapturePaymentState>;
    capturePaymentWorkflowId?: Maybe<Scalars["ID"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    customEvents: Array<Scalars["String"]["output"]>;
    discountedAmount?: Maybe<Scalars["Int"]["output"]>;
    expired?: Maybe<Scalars["Time"]["output"]>;
    hasDeposit: Scalars["Boolean"]["output"];
    id: Scalars["ID"]["output"];
    invoiceId?: Maybe<Scalars["ID"]["output"]>;
    invoiceIds: Array<Scalars["ID"]["output"]>;
    paymentAmount: Scalars["Int"]["output"];
    promocode?: Maybe<Scalars["ID"]["output"]>;
    referenceId?: Maybe<Scalars["String"]["output"]>;
    remainingPaymentAmount: Scalars["Int"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userId: Scalars["ID"]["output"];
};

export type CourtsiteGameWorkflowStarted = {
    state: CourtsiteGameWorkflowState;
};

export type CourtsiteGameWorkflowState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    gameId?: Maybe<Scalars["ID"]["output"]>;
    id: Scalars["ID"]["output"];
    lastEventId?: Maybe<Scalars["ID"]["output"]>;
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    userId: Scalars["ID"]["output"];
};

export type CourtsitePartnersFilter = {
    byOrganisationIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type CourtsiteRescheduleBookingsConflictingWorkflow = {
    message: Scalars["String"]["output"];
};

export type CourtsiteRescheduleBookingsUnavailable = {
    message: Scalars["String"]["output"];
};

export type CourtsiteRescheduleExpired = {
    message: Scalars["String"]["output"];
};

export type CourtsiteReschedulePaymentDetailsProvided = {
    state: CourtsiteRescheduleState;
};

export type CourtsiteRescheduleStarted = {
    state: CourtsiteRescheduleState;
};

export type CourtsiteRescheduleState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    capturePaymentState?: Maybe<CapturePaymentState>;
    capturePaymentWorkflowId?: Maybe<Scalars["ID"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    expired?: Maybe<Scalars["Time"]["output"]>;
    id: Scalars["ID"]["output"];
    newBookingId?: Maybe<Scalars["ID"]["output"]>;
    newInvoiceId?: Maybe<Scalars["ID"]["output"]>;
    oldBookingId: Scalars["ID"]["output"];
    priceDifference?: Maybe<Scalars["Int"]["output"]>;
    redirectUrl?: Maybe<Scalars["String"]["output"]>;
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
};

export type CourtsiteSearchRequest = {
    latitude?: InputMaybe<Scalars["Float"]["input"]>;
    longitude?: InputMaybe<Scalars["Float"]["input"]>;
    maxDistance?: InputMaybe<Scalars["Int"]["input"]>;
    services?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type CreateAddOnRequest = {
    maxQuantity?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    price: Scalars["Int"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateAddOnResponse = AccessError | AddOnCreated;

export type CreateCategoryRequest = {
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
};

export type CreateCategoryResponse = AccessError | CategoryCreated;

export type CreateCustomerRequest = {
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    metadata?: InputMaybe<Scalars["JSONString"]["input"]>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    tags?: InputMaybe<Array<CustomerTagInput>>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateCustomerResponse =
    | AccessError
    | CustomerCreated
    | InternalError;

export type CreateCustomersRequest = {
    customers: Array<CreateCustomerRequest>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateCustomersResponse = {
    results: Array<CreateCustomerResponse>;
};

export type CreateDailyServiceRequest = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    endTime: Scalars["Time"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    resources: Array<ServiceResourceInput>;
    startTime: Scalars["Time"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateDailyServiceResponse =
    | AccessError
    | DailyServiceCreated
    | NotFoundError;

export type CreateGrpCashSalesTxDetail = {
    account: Scalars["String"]["input"];
    drTermEndDate: Scalars["Date"]["input"];
    drTermStartDate: Scalars["Date"]["input"];
    qty: Scalars["Float"]["input"];
    subaccount: Scalars["String"]["input"];
    transactionDescription: Scalars["String"]["input"];
    unitPrice: Scalars["Int"]["input"];
};

export type CreateGameRequest = {
    categoryId: Scalars["ID"]["input"];
    description: Scalars["String"]["input"];
    endDt: Scalars["DateTime"]["input"];
    location: LocationInput;
    maxPlayers: Scalars["Int"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    organizerDetails: GamePlayerInput;
    private?: InputMaybe<Scalars["Boolean"]["input"]>;
    reservedPlayers?: InputMaybe<Scalars["Int"]["input"]>;
    startDt: Scalars["DateTime"]["input"];
};

export type CreateGameResponse = AccessError | GameCreated | InternalError;

export type CreateInvoiceItemRelatedEntityInput = {
    relatedEntityId: Scalars["String"]["input"];
    relatedEntityType: InvoiceItemRelatedEntityType;
};

export type CreateInvoiceItemRequest = {
    description: Scalars["String"]["input"];
    metadata: Scalars["JSONString"]["input"];
    perUnitPrice: Scalars["Int"]["input"];
    quantity: Scalars["Int"]["input"];
    relatedEntity?: InputMaybe<CreateInvoiceItemRelatedEntityInput>;
};

export type CreateInvoiceRequest = {
    expiresOn?: InputMaybe<Scalars["DateTime"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    invoiceeId?: InputMaybe<Scalars["ID"]["input"]>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    isCourtsiteOwned?: InputMaybe<Scalars["Boolean"]["input"]>;
    items: Array<CreateInvoiceItemRequest>;
    metadata: Scalars["JSONString"]["input"];
    settlementRate?: InputMaybe<Scalars["Float"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateInvoiceResponse =
    | AccessError
    | InternalError
    | InvoiceCreated;

export type CreateLightRequest = {
    boardId: Scalars["ID"]["input"];
    disableToggling: Scalars["Boolean"]["input"];
    isUserActivated: Scalars["Boolean"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    relayId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateLightResponse = AccessError | LightCreated;

export type CreateLocationRequest = {
    city?: InputMaybe<Scalars["String"]["input"]>;
    latitude: Scalars["Float"]["input"];
    longitude: Scalars["Float"]["input"];
    name: Scalars["String"]["input"];
};

export type CreateLocationResponse = AccessError | LocationCreated;

export type CreateOrganisationRequest = {
    latitude: Scalars["Float"]["input"];
    longitude: Scalars["Float"]["input"];
    metadata: Scalars["JSONString"]["input"];
    organisationName: Scalars["String"]["input"];
    ownerName: Scalars["String"]["input"];
    services: Array<Scalars["String"]["input"]>;
};

export type CreateOrganisationResponse = AccessError | OrganisationCreated;

export type CreateOverrideRequest = {
    end: Scalars["DateTime"]["input"];
    lightId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    start: Scalars["DateTime"]["input"];
    state: LightState;
    tenantId: Scalars["ID"]["input"];
};

export type CreateOverrideResponse =
    | AccessError
    | InternalError
    | NotFoundError
    | OverrideCreated;

export type CreatePsmgrpCashSalesTxRequest = {
    date: Scalars["Date"]["input"];
    description: Scalars["String"]["input"];
    details: Array<CreateGrpCashSalesTxDetail>;
    paymentRef: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreatePsmgrpCashSalesTxResponse =
    | AccessError
    | InternalError
    | PsmgrpCashSalesTxCreated;

export type CreatePriceRuleDurationRequest = {
    deposit?: InputMaybe<Scalars["Int"]["input"]>;
    depositType?: InputMaybe<DepositType>;
    end: DayTimeInput;
    rates: Array<PriceRateInput>;
    start: DayTimeInput;
};

export type CreatePriceRulePerDayRequest = {
    deposit?: InputMaybe<Scalars["Int"]["input"]>;
    depositType?: InputMaybe<DepositType>;
    perDayRate: Scalars["Int"]["input"];
    weekday: Scalars["Int"]["input"];
};

export type CreatePriceRulePerUnitRequest = {
    deposit?: InputMaybe<Scalars["Int"]["input"]>;
    depositType?: InputMaybe<DepositType>;
    end: DayTimeInput;
    perUnitRate: Scalars["Int"]["input"];
    start: DayTimeInput;
};

export type CreatePriceRulesetsForServicesRequest = {
    appliesTo: Array<Scalars["ID"]["input"]>;
    requests: Array<CreatePriceRulesetsRequestRuleset>;
    tenantId: Scalars["ID"]["input"];
};

export type CreatePriceRulesetsRequestRuleset = {
    effectiveFrom: Scalars["DateTime"]["input"];
    perDayRules?: InputMaybe<Array<CreatePriceRulePerDayRequest>>;
    perUnitRules?: InputMaybe<Array<CreatePriceRulePerUnitRequest>>;
    rules?: Array<CreatePriceRuleDurationRequest>;
};

export type CreatePromoCodeRequest = {
    code: Scalars["ID"]["input"];
    discount: Scalars["Int"]["input"];
    endDt: Scalars["DateTime"]["input"];
    itemTypeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
    metadata: Scalars["JSONString"]["input"];
    startDt: Scalars["DateTime"]["input"];
    tenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
    type: DiscountType;
};

export type CreatePromoCodeRequestV2 = {
    applicableServiceIds: Array<Scalars["String"]["input"]>;
    bookingEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
    bookingStart?: InputMaybe<Scalars["DateTime"]["input"]>;
    code: Scalars["String"]["input"];
    discountAmount: Scalars["Int"]["input"];
    discountType: DiscountTypeV2;
    endDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    name: Scalars["String"]["input"];
    redemptionLimit: Scalars["Int"]["input"];
    startDt: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreatePromoCodeResponse = PromoCodeAlreadyExists | PromoCodeCreated;

export type CreatePromoCodeResponseV2 =
    | AccessError
    | DuplicatePromoCodeError
    | PromoCodeCreatedV2;

export type CreateResourceLinkInput = {
    metadata: Scalars["JSONString"]["input"];
    resourceId1: Scalars["ID"]["input"];
    resourceId2: Scalars["ID"]["input"];
};

export type CreateResourceLinksRequest = {
    links: Array<CreateResourceLinkInput>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateResourceLinksResponse =
    | AccessError
    | NotFoundError
    | ResourceLinksAlreadyExist
    | ResourceLinksCreated;

export type CreateResourceRequest = {
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    resourceTypeId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateResourceResponse =
    | AccessError
    | NotFoundError
    | ResourceCreated;

export type CreateResourceTypeRequest = {
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateResourceTypeResponse = AccessError | ResourceTypeCreated;

export type CreateRulesetsForServicesResponse =
    | AccessError
    | MissingPriceRules
    | MissingService
    | PriceRulesetsForServicesCreated;

export type CreateScheduleRequest = {
    daytimeSlots: Array<DayTimeSlotInput>;
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    rules: Array<RuleInput>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateScheduleResponse =
    | AccessError
    | NotFoundError
    | ScheduleCreated
    | UnhandledRuleTypeError;

export type CreateServiceRequest = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    metadata: Scalars["JSONString"]["input"];
    minRescheduleNotice?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    resources: Array<ServiceResourceInput>;
    tenantId: Scalars["ID"]["input"];
};

export type CreateServiceResponse =
    | AccessError
    | NotFoundError
    | ServiceCreated;

export type CreateSettlementConfigurationRequest = {
    commissionPercentage: Scalars["Float"]["input"];
    metadata: Scalars["JSONString"]["input"];
    monthlyFee: Scalars["Int"]["input"];
    settlementRange: SettlementRangeConfigurationInput;
    slug: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateSettlementConfigurationResponse =
    | AccessError
    | NotFoundError
    | SettlementConfigurationCreated;

export type CreateSettlementConfigurationWithApprovalRequest = {
    commissionPercentage: Scalars["Float"]["input"];
    metadata: Scalars["JSONString"]["input"];
    monthlyFee: Scalars["Int"]["input"];
    settlementRange: SettlementRangeConfigurationInput;
    slug: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateSettlementConfigurationWithApprovalResponse =
    | AccessError
    | NotFoundError
    | SettlementConfigurationWithApprovalCreated;

export type CreateSettlementRequest = {
    amount: Scalars["Int"]["input"];
    endDate: Scalars["DateTime"]["input"];
    isAutoGen?: InputMaybe<Scalars["Boolean"]["input"]>;
    isResellerSettlement?: InputMaybe<Scalars["Boolean"]["input"]>;
    metadata: Scalars["JSONString"]["input"];
    reportUrl: Scalars["String"]["input"];
    startDate: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateSettlementResponse = AccessError | SettlementCreated;

export type CreateTagRequest = {
    name: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateTagResponse = AccessError | TagCreated;

export type CreateTriggerRequest = {
    lightId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    resourceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type CreateTriggerResponse =
    | AccessError
    | InternalError
    | NotFoundError
    | TriggerCreated;

export type CreateUserProfileManualRequest = {
    dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    firebaseId: Scalars["ID"]["input"];
    gender?: InputMaybe<Gender>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type CreateUserProfileManualResponse =
    | AccessError
    | InvalidValue
    | UserAlreadyExists
    | UserProfileCreated;

export type CreateUserProfileRequest = {
    dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    gender?: InputMaybe<Gender>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type CreateUserProfileResponse =
    | AccessError
    | InvalidValue
    | UserAlreadyExists
    | UserProfileCreated;

export type Customer = {
    created: Scalars["DateTime"]["output"];
    customerTags: Array<CustomerTag>;
    email?: Maybe<Scalars["EmailAddress"]["output"]>;
    metadata?: Maybe<Scalars["JSONString"]["output"]>;
    name: Scalars["String"]["output"];
    phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    serviceIds?: Maybe<Array<Scalars["ID"]["output"]>>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type CustomerAlreadyArchived = {
    message: Scalars["String"]["output"];
};

export type CustomerArchived = {
    customerId: Scalars["String"]["output"];
};

export type CustomerCreated = {
    customerId: Scalars["String"]["output"];
};

export type CustomerFilter = {
    byCustomerEmail?: InputMaybe<Scalars["String"]["input"]>;
    byCustomerName?: InputMaybe<Scalars["String"]["input"]>;
    byCustomerPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    byTagIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CustomerInfoEdited = {
    customerId: Scalars["String"]["output"];
};

export type CustomerMetadataUpdated = {
    customerId: Scalars["ID"]["output"];
    metadata?: Maybe<Scalars["JSONString"]["output"]>;
};

export type CustomerTag = {
    created: Scalars["DateTime"]["output"];
    expiresOn?: Maybe<Scalars["DateTime"]["output"]>;
    tag: Tag;
    tagId: Scalars["ID"]["output"];
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type CustomerTagInput = {
    expiresOn?: InputMaybe<Scalars["DateTime"]["input"]>;
    tagId: Scalars["ID"]["input"];
};

export type DailyServiceCreated = {
    serviceId: Scalars["ID"]["output"];
};

export type DailyServiceTimesSet = {
    endTime: Scalars["Time"]["output"];
    serviceId: Scalars["ID"]["output"];
    startTime: Scalars["Time"]["output"];
};

export type DateRangeFilter = {
    endDt: Scalars["DateTime"]["input"];
    startDt: Scalars["DateTime"]["input"];
};

export type DatetimeRange = {
    end: Scalars["DateTime"]["output"];
    start: Scalars["DateTime"]["output"];
};

export type DayTime = {
    hour: Scalars["Int"]["output"];
    hourOffset: Scalars["Int"]["output"];
    isoWeekday: Scalars["Int"]["output"];
    minute: Scalars["Int"]["output"];
    /** @deprecated Moving to isoWeekday */
    weekday: Scalars["Int"]["output"];
};

export type DayTimeInput = {
    hour: Scalars["Int"]["input"];
    hourOffset?: InputMaybe<Scalars["Int"]["input"]>;
    isoWeekday?: InputMaybe<Scalars["Int"]["input"]>;
    minute?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DayTimeSlot = {
    durationMinutes: Scalars["Int"]["output"];
    startMinuteOfWeek: Scalars["Int"]["output"];
};

export type DayTimeSlotInput = {
    durationMinutes: Scalars["Int"]["input"];
    startMinuteOfWeek: Scalars["Int"]["input"];
};

export type DeactivateLightEventsRequest = {
    groupIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type DeactivateLightEventsResponse =
    | AccessError
    | LightEventsDeactivated
    | NotFoundError;

/** An enumeration. */
export type DepositType = "FLAT" | "PERCENTAGE";

/** An enumeration. */
export type DiscountType = "FLAT" | "PERCENT";

export type DiscountTypeV2 = "FLAT" | "NONE" | "PERCENT";

export type DuplicatePromoCodeError = {
    code: Scalars["String"]["output"];
    message: Scalars["String"]["output"];
};

export type DurationOption = {
    end: DayTime;
    start: DayTime;
};

export type DurationOptionInput = {
    end: DayTimeInput;
    start: DayTimeInput;
};

export type EditCustomerInfoRequest = {
    customerId?: InputMaybe<Scalars["ID"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    metadata?: InputMaybe<Scalars["JSONString"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    tags?: InputMaybe<Array<CustomerTagInput>>;
    tenantId: Scalars["ID"]["input"];
};

export type EditCustomerInfoResponse =
    | AccessError
    | CustomerInfoEdited
    | NotFoundError;

export type EditGameOrganizerRequest = {
    gameId: Scalars["ID"]["input"];
    organizerDetails: GameOrganizerInput;
};

export type EditGameOrganizerResponse =
    | AccessError
    | GameOrganizerEdited
    | NotFoundError;

export type EditGameRequest = {
    description: Scalars["String"]["input"];
    endDt: Scalars["DateTime"]["input"];
    location?: InputMaybe<LocationInput>;
    maxPlayers: Scalars["Int"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    private?: InputMaybe<Scalars["Boolean"]["input"]>;
    reservedPlayers?: InputMaybe<Scalars["Int"]["input"]>;
    startDt: Scalars["DateTime"]["input"];
    uid: Scalars["ID"]["input"];
};

export type EditGameResponse = AccessError | GameEdited | NotFoundError;

export type EditLocationRequest = {
    city?: InputMaybe<Scalars["String"]["input"]>;
    latitude: Scalars["Float"]["input"];
    locationId: Scalars["ID"]["input"];
    longitude: Scalars["Float"]["input"];
    name: Scalars["String"]["input"];
};

export type EditLocationResponse = AccessError | LocationEdited | NotFoundError;

export type EditPaymentMethodAvailabilityRequest = {
    availability: Scalars["Boolean"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditPaymentMethodAvailabilityResponse =
    | AccessError
    | NotFoundError
    | PaymentMethodAvailabilityEdited;

export type EditPaymentMethodMetadataRequest = {
    metadata: Scalars["JSONString"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditPaymentMethodMetadataResponse =
    | AccessError
    | NotFoundError
    | PaymentMethodMetadataEdited;

export type EditPaymentMethodNameRequest = {
    name: Scalars["String"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditPaymentMethodNameResponse =
    | AccessError
    | NotFoundError
    | PaymentMethodNameEdited;

export type EditPromoCodeRequestV2 = {
    applicableServiceIds: Array<Scalars["String"]["input"]>;
    bookingEnd?: InputMaybe<Scalars["DateTime"]["input"]>;
    bookingStart?: InputMaybe<Scalars["DateTime"]["input"]>;
    code: Scalars["String"]["input"];
    discountAmount: Scalars["Int"]["input"];
    discountType: DiscountTypeV2;
    endDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    name: Scalars["String"]["input"];
    promoCodeId: Scalars["ID"]["input"];
    redemptionLimit: Scalars["Int"]["input"];
    startDt: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditPromoCodeResponseV2 =
    | AccessError
    | DuplicatePromoCodeError
    | NotFoundError
    | PromoCodeEditedV2;

export type EditSettlementConfigurationRequest = {
    commissionPercentage: Scalars["Float"]["input"];
    configurationId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    monthlyFee: Scalars["Int"]["input"];
    settlementRange: SettlementRangeConfigurationInput;
    slug: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditSettlementConfigurationResponse =
    | AccessError
    | NotFoundError
    | SettlementConfigurationEdited;

export type EditSettlementConfigurationWithApprovalRequest = {
    commissionPercentage: Scalars["Float"]["input"];
    configurationId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    monthlyFee: Scalars["Int"]["input"];
    settlementRange: SettlementRangeConfigurationInput;
    slug: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditSettlementConfigurationWithApprovalResponse =
    | AccessError
    | NotFoundError
    | SettlementConfigurationWithApprovalEdited;

export type EditTagRequest = {
    name: Scalars["String"]["input"];
    tagId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type EditTagResponse = AccessError | NotFoundError | TagEdited;

export type Error = {
    code: Scalars["String"]["output"];
    message: Scalars["String"]["output"];
};

export type Event = Command & {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    end: Scalars["DateTime"]["output"];
    groupId: Scalars["ID"]["output"];
    light?: Maybe<Light>;
    lightId: Scalars["ID"]["output"];
    metadata: Scalars["JSONString"]["output"];
    resourceId: Scalars["ID"]["output"];
    start: Scalars["DateTime"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type EventInput = {
    end: Scalars["DateTime"]["input"];
    groupId: Scalars["ID"]["input"];
    resourceId: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
};

export type EventsArchived = {
    events: Array<Event>;
};

export type EventsSynced = {
    events: Array<Event>;
};

export type ExpirePromoCodeRedemptionRequest = {
    promoCodeId: Scalars["ID"]["input"];
    promoCodeRedemptionId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ExpirePromoCodeRedemptionResponse =
    | AccessError
    | BadRequestError
    | NotFoundError
    | PromoCodeRedemptionExpired;

export type FederationWorkaround = {
    id: Scalars["ID"]["output"];
};

export type FixedDurationOptionsUpdated = {
    serviceId: Scalars["ID"]["output"];
};

export type ForceRetryCapturePaymentRequest = {
    paymentProvider?: InputMaybe<PaymentProvider>;
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    workflowId: Scalars["ID"]["input"];
};

export type ForceRetryCapturePaymentResponse =
    | CapturePaymentForceRetried
    | WorkflowNotFound
    | WorkflowNotReady;

export type Game = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    categoryId: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    description: Scalars["String"]["output"];
    endDt: Scalars["DateTime"]["output"];
    events: Array<GameEvent>;
    isPrivate: Scalars["Boolean"]["output"];
    location: GameLocation;
    maxPlayers: Scalars["Int"]["output"];
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    organizer: Organizer;
    players?: Maybe<Array<GamePlayer>>;
    reservedPlayers?: Maybe<Scalars["Int"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GameArchived = {
    game: Game;
};

export type GameArchivedEvent = GameEvent & {
    created: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GameConnection = {
    edges: Array<GameEdge>;
    pageInfo: PageInfo;
};

export type GameCreated = {
    game: Game;
};

export type GameCreatedEvent = GameEvent & {
    categoryId: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    description: Scalars["String"]["output"];
    endDt: Scalars["DateTime"]["output"];
    gameId: Scalars["ID"]["output"];
    gameMetadata: Scalars["JSONString"]["output"];
    isPrivate?: Maybe<Scalars["Boolean"]["output"]>;
    location: GameLocation;
    maxPlayers: Scalars["Int"]["output"];
    name: Scalars["String"]["output"];
    organizer: Organizer;
    reservedPlayers?: Maybe<Scalars["Int"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GameDetailsSetEvent = GameEvent & {
    created: Scalars["DateTime"]["output"];
    description: Scalars["String"]["output"];
    endDt: Scalars["DateTime"]["output"];
    gameMetadata: Scalars["JSONString"]["output"];
    isPrivate?: Maybe<Scalars["Boolean"]["output"]>;
    location: GameLocation;
    maxPlayers: Scalars["Int"]["output"];
    name: Scalars["String"]["output"];
    reservedPlayers?: Maybe<Scalars["Int"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GameEdge = {
    cursor: Scalars["String"]["output"];
    node: Game;
};

export type GameEdited = {
    game: Game;
};

export type GameEvent = {
    created: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GameFilter = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    includeArchivedGames?: InputMaybe<Scalars["Boolean"]["input"]>;
    onlyInclude?: InputMaybe<GameMember>;
    startDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type GameFilterCount = {
    categoryId: Scalars["ID"]["output"];
    gameCount: Scalars["Int"]["output"];
};

export type GameJoined = {
    game: Game;
};

export type GameLeft = {
    game: Game;
};

export type GameLocation = {
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
};

/** An enumeration. */
export type GameMember = "ORGANIZER" | "PLAYER";

export type GameOrganizerDetailsSetEvent = GameEvent & {
    created: Scalars["DateTime"]["output"];
    organizer: Organizer;
    uid: Scalars["ID"]["output"];
};

export type GameOrganizerEdited = {
    game: Game;
};

export type GameOrganizerInput = {
    displayPicture?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
};

export type GamePlayer = {
    displayPicture?: Maybe<Scalars["String"]["output"]>;
    email?: Maybe<Scalars["EmailAddress"]["output"]>;
    joined: Scalars["DateTime"]["output"];
    name: Scalars["String"]["output"];
    phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    playerId: Scalars["ID"]["output"];
};

export type GamePlayerInput = {
    displayPicture?: InputMaybe<Scalars["String"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
};

export type GameStatistics = {
    gamesMade: Scalars["Int"]["output"];
};

/** An enumeration. */
export type Gender = "FEMALE" | "MALE";

export type GeneratePsmGrpCashSaleTxCompleted = {
    state: GeneratePsmGrpCashSaleTxState;
};

export type GeneratePsmGrpCashSaleTxState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
};

export type GenerateSettlementDownloadLinkRequest = {
    filename?: InputMaybe<Scalars["String"]["input"]>;
    settlementId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type GenerateSettlementDownloadLinkResponse =
    | AccessError
    | NotFoundError
    | SettlementDownloadLinkGenerated;

export type GenerateSettlementReportCompleted = {
    state: GenerateSettlementReportState;
};

export type GenerateSettlementReportRequest = {
    endDt: Scalars["Time"]["input"];
    includeSubscription: Scalars["Boolean"]["input"];
    settlementConfigurationId: Scalars["ID"]["input"];
    startDt: Scalars["Time"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type GenerateSettlementReportResponse =
    | GenerateSettlementReportCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type GenerateSettlementReportState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    resellerSettlementReportId?: Maybe<Scalars["ID"]["output"]>;
    runId: Scalars["ID"]["output"];
    settlementReportId?: Maybe<Scalars["ID"]["output"]>;
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userId: Scalars["ID"]["output"];
};

export type GetLhdnSubmissionStatus = {
    status: LhdnSubmissionStatus;
    submissionId: Scalars["ID"]["output"];
};

export type GroupActivation = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created?: Maybe<Scalars["DateTime"]["output"]>;
    groupId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type GroupIdEventsActivationStatus = {
    groupId: Scalars["ID"]["output"];
    isActivated: Scalars["Boolean"]["output"];
    requiresUserActivation: Scalars["Boolean"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type GroupedBookings = {
    bookings: Array<BookingSummary>;
    endDt: Scalars["DateTime"]["output"];
    referenceId: Scalars["String"]["output"];
    startDt: Scalars["DateTime"]["output"];
};

export type GroupedBookingsConnection = {
    edges: Array<GroupedBookingsEdge>;
    pageInfo: PageInfo;
};

export type GroupedBookingsEdge = {
    cursor: Scalars["String"]["output"];
    node: GroupedBookings;
};

export type IPay88SessionInitiated = {
    backendUrl: Scalars["String"]["output"];
    externalId: Scalars["ID"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    requestSignature: Scalars["String"]["output"];
    responseUrl: Scalars["String"]["output"];
};

export type IdType = "ARMY" | "BRN" | "NRIC" | "PASSPORT";

export type InitiatePaymentSessionRequest = {
    amount: Scalars["Int"]["input"];
    customReferenceId?: InputMaybe<Scalars["String"]["input"]>;
    description: Scalars["String"]["input"];
    expiryInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
    metadata: Scalars["JSONString"]["input"];
    payerEmail: Scalars["EmailAddress"]["input"];
    payerName: Scalars["String"]["input"];
    payerPhoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    provider: PaymentProvider;
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type InitiatePaymentSessionResponse =
    | AccessError
    | BillplzSessionInitiated
    | IPay88SessionInitiated
    | InternalError
    | InvalidRedirectUrlError
    | KiplepaySessionInitiated
    | MastercardSessionInitiated
    | RazorpaySessionInitiated
    | SPaySessionInitiated;

export type InternalError = {
    message: Scalars["String"]["output"];
};

export type InvalidBooking = {
    bookingId: Scalars["ID"]["output"];
    reason: Scalars["String"]["output"];
};

export type InvalidCourtsiteReschedulePaymentDetailsRequest = {
    message: Scalars["String"]["output"];
};

export type InvalidRedirectUrlError = {
    message: Scalars["String"]["output"];
};

export type InvalidServiceModeError = {
    message: Scalars["String"]["output"];
};

export type InvalidValue = {
    message: Scalars["String"]["output"];
};

export type Invoice = {
    amountPaid: Scalars["Int"]["output"];
    associatedUsers: Array<InvoiceAssociatedUser>;
    cancelled?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    expiresOn?: Maybe<Scalars["DateTime"]["output"]>;
    invoiceeEmail?: Maybe<Scalars["EmailAddress"]["output"]>;
    invoiceeId?: Maybe<Scalars["ID"]["output"]>;
    invoiceeName: Scalars["String"]["output"];
    invoiceePhoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    isCourtsiteOwned: Scalars["Boolean"]["output"];
    items: Array<InvoiceItem>;
    metadata: Scalars["JSONString"]["output"];
    outstandingBalance: Scalars["Int"]["output"];
    payments: Array<Payment>;
    printNumber?: Maybe<Scalars["Int"]["output"]>;
    promos: Array<InvoicePromo>;
    settlementRate?: Maybe<Scalars["Float"]["output"]>;
    subtotal: Scalars["Int"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    total: Scalars["Int"]["output"];
    uid: Scalars["ID"]["output"];
};

export type InvoiceAlreadyCancelledError = {
    message: Scalars["String"]["output"];
};

export type InvoiceAlreadyPaidError = {
    invoice: Invoice;
};

export type InvoiceAssociatedUser = {
    created: Scalars["DateTime"]["output"];
    userId: Scalars["ID"]["output"];
};

export type InvoiceCancelled = {
    invoice: Invoice;
};

export type InvoiceConnection = {
    edges: Array<InvoiceEdge>;
    pageInfo: PageInfo;
};

export type InvoiceCourtsiteOwnershipRequestError = {
    message: Scalars["String"]["output"];
};

export type InvoiceCourtsiteOwnershipUpdated = {
    invoice: Invoice;
};

export type InvoiceCreated = {
    invoice: Invoice;
};

export type InvoiceDetail = {
    invoiceDatetime: Scalars["DateTime"]["input"];
    invoiceItems: Array<InvoiceLineItem>;
    invoiceNumber: Scalars["String"]["input"];
};

export type InvoiceEdge = {
    cursor: Scalars["String"]["output"];
    node: Invoice;
};

export type InvoiceFilter = {
    byCreatedDate?: InputMaybe<DateRangeFilter>;
    byInvoicePrintNumber?: InputMaybe<Scalars["Int"]["input"]>;
    byInvoiceeName?: InputMaybe<Scalars["String"]["input"]>;
    byIsCourtsiteOwned?: InputMaybe<Scalars["Boolean"]["input"]>;
    byRelatedEntityId?: InputMaybe<Scalars["String"]["input"]>;
    byRelatedEntityIds?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    byStatus?: InputMaybe<InvoiceStatus>;
};

export type InvoiceItem = {
    created: Scalars["DateTime"]["output"];
    description: Scalars["String"]["output"];
    metadata: Scalars["JSONString"]["output"];
    perUnitPrice: Scalars["Int"]["output"];
    quantity: Scalars["Int"]["output"];
    relatedEntity?: Maybe<InvoiceItemRelatedEntity>;
    subtotal: Scalars["Int"]["output"];
    uid: Scalars["ID"]["output"];
};

export type InvoiceItemRelatedEntity = {
    created: Scalars["DateTime"]["output"];
    entityId: Scalars["ID"]["output"];
    entityType: InvoiceItemRelatedEntityType;
    uid: Scalars["ID"]["output"];
};

/** An enumeration. */
export type InvoiceItemRelatedEntityType =
    | "ADDON"
    | "BOOKING"
    | "COMPETITION_REGISTRATION"
    | "PROMOCODE"
    | "UNKNOWN";

export type InvoiceItemRelatedEntityUpdated = {
    invoice: Invoice;
    newInvoiceItemRelatedEntityId: Scalars["ID"]["output"];
};

export type InvoiceItemsModified = {
    invoice: Invoice;
};

export type InvoiceLineItem = {
    description: Scalars["String"]["input"];
    total: Scalars["Int"]["input"];
    unitPrice: Scalars["Int"]["input"];
};

export type InvoiceMetadataUpdated = {
    invoiceId: Scalars["ID"]["output"];
    metadata: Scalars["JSONString"]["output"];
};

export type InvoicePaid = {
    invoice: Invoice;
};

export type InvoicePromo = {
    code: Scalars["String"]["output"];
    discountedAmount: Scalars["Int"]["output"];
};

export type InvoiceStatistics = {
    invoicePerYear: Scalars["Int"]["output"];
};

export type InvoiceStatus = {
    cancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
    paid?: InputMaybe<Scalars["Boolean"]["input"]>;
    unpaid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type InvoiceUncancelled = {
    invoice: Invoice;
};

export type JoinGameRequest = {
    playerDetails: GamePlayerInput;
    uid: Scalars["ID"]["input"];
};

export type JoinGameResponse =
    | AccessError
    | GameJoined
    | NoPlayerSlotError
    | NotFoundError;

export type KiplepaySessionInitiated = {
    backendUrl: Scalars["String"]["output"];
    externalId: Scalars["ID"]["output"];
    merchantId: Scalars["ID"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    requestMerchantHashvalue: Scalars["String"]["output"];
    responseUrl: Scalars["String"]["output"];
};

export type KiplepaySettlementDetailsResult = {
    jsonData: Scalars["JSONString"]["output"];
};

export type KiplepaySettlementNotification = {
    created: Scalars["DateTime"]["output"];
    notificationData: Scalars["JSONString"]["output"];
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type KonsolCancelInvoiceCompleted = {
    state: KonsolCancelInvoiceState;
};

export type KonsolCancelInvoiceState = {
    bookingIds?: Maybe<Array<Scalars["ID"]["output"]>>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    invoiceId: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type KonsolCheckoutInvalidBooking = {
    message: Scalars["String"]["output"];
};

export type KonsolCheckoutNotCancellable = {
    message: Scalars["String"]["output"];
};

export type KonsolCheckoutStarted = {
    state: KonsolCheckoutState;
};

export type KonsolCheckoutState = {
    bookingIds: Array<Scalars["ID"]["output"]>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    capturePaymentState?: Maybe<CapturePaymentState>;
    capturePaymentWorkflowId?: Maybe<Scalars["ID"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    expired?: Maybe<Scalars["Time"]["output"]>;
    id: Scalars["ID"]["output"];
    invoiceId?: Maybe<Scalars["ID"]["output"]>;
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userId: Scalars["ID"]["output"];
};

export type KonsolCreateInvoiceCompleted = {
    state: KonsolCreateInvoiceState;
};

export type KonsolCreateInvoiceState = {
    bookingIds: Array<Scalars["ID"]["output"]>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    invoiceId?: Maybe<Scalars["ID"]["output"]>;
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type KonsolPayInvoiceCompleted = {
    state: KonsolPayInvoiceState;
};

export type KonsolPayInvoiceNotCancellable = {
    message: Scalars["String"]["output"];
};

export type KonsolPayInvoiceState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    capturePaymentWorkflowId: Scalars["ID"]["output"];
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    expired?: Maybe<Scalars["Time"]["output"]>;
    id: Scalars["ID"]["output"];
    invoiceId: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type LhdnInvoiceSubmitted = {
    acceptedDocuments: Array<AcceptedDocument>;
    rejectedDocuments: Array<RejectedDocument>;
    responseData: Scalars["String"]["output"];
    submissionUid: Scalars["String"]["output"];
};

export type LhdnSubmission = {
    created: Scalars["DateTime"]["output"];
    data: Scalars["String"]["output"];
    invoiceId: Scalars["ID"]["output"];
    status: LhdnSubmissionStatus;
    submissionId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

/** An enumeration. */
export type LhdnSubmissionStatus =
    | "INPROGRESS"
    | "INVALID"
    | "PARTIALLYVALID"
    | "VALID";

export type LhdnSubmissionStatusSet = {
    lhdnSubmissionId: Scalars["ID"]["output"];
};

export type LeaveGameRequest = {
    uid: Scalars["ID"]["input"];
};

export type LeaveGameResponse = AccessError | GameLeft | NotFoundError;

export type Light = {
    boardId: Scalars["ID"]["output"];
    cooldown: Scalars["Int"]["output"];
    created: Scalars["DateTime"]["output"];
    currentCommand?: Maybe<Command>;
    disableToggling: Scalars["Boolean"]["output"];
    isUserActivated: Scalars["Boolean"]["output"];
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    relayId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    triggers: Array<Trigger>;
    uid: Scalars["ID"]["output"];
    warmup: Scalars["Int"]["output"];
};

export type LightCreated = {
    light: Light;
};

export type LightDurationSet = {
    light: Light;
};

export type LightEventsActivated = {
    groupActivations?: Maybe<Array<GroupActivation>>;
};

export type LightEventsDeactivated = {
    groupActivations?: Maybe<Array<GroupActivation>>;
};

/** An enumeration. */
export type LightState = "OFF" | "ON";

export type LightUpdated = {
    light: Light;
};

export type LocationArchived = {
    location: SearchLocation;
};

export type LocationCreated = {
    location: SearchLocation;
};

export type LocationEdited = {
    location: SearchLocation;
};

export type LocationFilter = {
    byLatitude?: InputMaybe<Scalars["Float"]["input"]>;
    byLongitude?: InputMaybe<Scalars["Float"]["input"]>;
};

export type LocationInput = {
    tenantId: Scalars["ID"]["input"];
};

export type LogCourtsiteCheckoutCustomEventRequest = {
    checkoutId: Scalars["ID"]["input"];
    eventName: Scalars["String"]["input"];
};

export type LogCourtsiteCheckoutCustomEventResponse =
    | CourtsiteCheckoutCustomEventLogged
    | WorkflowCancelled
    | WorkflowNotFound;

export type MakeBookingsRequest = {
    bookingSource?: InputMaybe<BookingSource>;
    guest?: InputMaybe<BookingGuestRequest>;
    ignoreServiceLimits?: InputMaybe<Scalars["Boolean"]["input"]>;
    isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
    serviceId?: InputMaybe<Scalars["ID"]["input"]>;
    slots: Array<BookingSlotRequest>;
    tenantId: Scalars["ID"]["input"];
};

export type MakeBookingsResponse =
    | AccessError
    | BookingsMade
    | BookingsUnavailable
    | InternalError;

export type MandatoryUserProfileSet = {
    user: UserProfile;
};

export type MastercardSessionInitiated = {
    externalId: Scalars["ID"]["output"];
    mastercardSessionId: Scalars["String"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    successIndicator?: Maybe<Scalars["String"]["output"]>;
};

export type Member = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    capabilities: Array<Scalars["String"]["output"]>;
    fullName: Scalars["String"]["output"];
    joined: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    uid: Scalars["ID"]["output"];
};

export type MemberAlreadyAddedError = {
    message: Scalars["String"]["output"];
};

export type MemberAlreadyArchivedError = {
    message: Scalars["String"]["output"];
};

export type MemberArchived = {
    memberId: Scalars["ID"]["output"];
};

export type MemberCapabilitiesSet = {
    member: Member;
};

/** An enumeration. */
export type MemberRole = "ADMIN" | "EMPLOYEE";

export type MemberRoleSet = {
    memberId: Scalars["ID"]["output"];
    role: Scalars["String"]["output"];
};

export type MemberRoleSetResponse =
    | AccessError
    | CannotModifySelfError
    | MemberAlreadyArchivedError
    | MemberRoleSet
    | NotFoundError;

export type MetabaseDashboardType =
    | "AUDIT"
    | "CUSTOMER_TREND"
    | "CUSTOMER_TREND_WITH_CATEGORY"
    | "DAILY_UTILIZATION"
    | "RESELLER_DAILY_UTILIZATION"
    | "RESELLER_REVENUE"
    | "RESELLER_UTILIZATION"
    | "REVENUE"
    | "UTILIZATION";

export type MissingPriceRules = {
    message: Scalars["String"]["output"];
};

export type MissingService = {
    message: Scalars["String"]["output"];
};

export type ModificationInvalidError = {
    invalidBookings: Array<InvalidBooking>;
};

export type ModifyAddOnRequest = {
    addOnId: Scalars["ID"]["input"];
    maxQuantity?: InputMaybe<Scalars["Int"]["input"]>;
    name: Scalars["String"]["input"];
    price: Scalars["Int"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ModifyAddOnResponse =
    | AccessError
    | AddOnAlreadyArchived
    | AddOnModified
    | NotFoundError;

export type ModifyBookingGuestRequest = {
    bookingId: Scalars["ID"]["input"];
    guestId: Scalars["ID"]["input"];
    newEmail?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    newGuestName: Scalars["String"]["input"];
    newPhoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type ModifyBookingGuestResponse =
    | AccessError
    | BookingGuestModified
    | NotFoundError;

export type ModifyBookingsRequest = {
    bookingGroupModifications?: InputMaybe<Array<BookingGroupModification>>;
    tenantId: Scalars["ID"]["input"];
};

export type ModifyBookingsResponse =
    | AccessError
    | BookingsModified
    | ModificationInvalidError;

export type ModifyCancelledReasonRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    cancelledReason: CancelledReason;
    tenantId: Scalars["ID"]["input"];
};

export type ModifyCancelledReasonResponse =
    | AccessError
    | BookingCancelledReasonModfied
    | BookingNotCancelledError
    | NotFoundError;

export type ModifyInvoiceItemsRequest = {
    invoiceId: Scalars["ID"]["input"];
    items: Array<CreateInvoiceItemRequest>;
    tenantId: Scalars["ID"]["input"];
};

export type ModifyInvoiceItemsResponse =
    | AccessError
    | InvoiceAlreadyPaidError
    | InvoiceItemsModified
    | NotFoundError;

export type Mutation = {
    activateLightEvents?: Maybe<ActivateLightEventsResponse>;
    activatePromoCode: ActivatePromoCodeResponse;
    addAssociatedUser: AddAssociatedUserResponse;
    addBookingGuest: AddBookingGuestResponse;
    addBookingGuestToBookings: AddBookingGuestToBookingsResponse;
    addMemberToOrganisation: AddMemberToOrganisationResponse;
    addMemberToOrganisationWithoutFirebase: AddMemberToOrganisationWithoutFirebaseResponse;
    addPaymentMethod: AddPaymentMethodResponse;
    addServiceResource: AddServiceResourceResponse;
    approveOrganisation: ApproveOrganisationResponse;
    archiveAddOn: ArchiveAddOnResponse;
    archiveCustomer: ArchiveCustomerResponse;
    archiveEvents?: Maybe<ArchiveEventsResponse>;
    archiveGame: ArchiveGameResponse;
    archiveLocation: ArchiveLocationResponse;
    archiveMember: ArchiveMemberResponse;
    archivePaymentMethod: ArchivePaymentMethodResponse;
    archivePromoCodeV2: ArchivePromoCodeResponseV2;
    archiveResource: ArchiveResourceResponse;
    archiveResourceLinks: ArchiveResourceLinksResponse;
    archiveResourceType: ArchiveResourceTypeResponse;
    archiveSchedule: ArchiveScheduleResponse;
    archiveService?: Maybe<ArchiveServiceResponse>;
    archiveServiceResource: ArchiveServiceResourceResponse;
    archiveSettlement: ArchiveSettlementResponse;
    archiveTag: ArchiveTagResponse;
    archiveTrigger?: Maybe<ArchiveTriggerResponse>;
    assignServiceToCategory: AssignServiceToCategoryResponse;
    cancelBooking: CancelBookingResponse;
    cancelBookings: CancelBookingsResponse;
    cancelCapturePaymentWorkflow: CancelCapturePaymentWorkflowResponse;
    cancelCourtsiteCheckout: CancelCourtsiteCheckoutResponse;
    cancelInvoice: CancelInvoiceResponse;
    cancelKonsolCheckout: CancelKonsolCheckoutResponse;
    cancelKonsolPayInvoice: CancelKonsolPayInvoiceResponse;
    cancelPayment: CancelPaymentResponse;
    cancelPaymentSession: CancelPaymentSessionResponse;
    changeResourceType: ChangeResourceTypeResponse;
    checkPaymentSession: CheckPaymentSessionResponse;
    confirmBooking: ConfirmBookingResponse;
    confirmBookings: ConfirmBookingsResponse;
    confirmCapturePayment: ConfirmCapturePaymentResponse;
    confirmPaymentSession: ConfirmPaymentSessionResponse;
    createAddOn: CreateAddOnResponse;
    createCategory: CreateCategoryResponse;
    createCustomer: CreateCustomerResponse;
    createCustomers: CreateCustomersResponse;
    createDailyService?: Maybe<CreateDailyServiceResponse>;
    createGame: CreateGameResponse;
    createInvoice: CreateInvoiceResponse;
    createLight?: Maybe<CreateLightResponse>;
    createLocation: CreateLocationResponse;
    createOrganisation: CreateOrganisationResponse;
    createOverride?: Maybe<CreateOverrideResponse>;
    createPriceRulesetsForServices?: Maybe<CreateRulesetsForServicesResponse>;
    createPromoCode: CreatePromoCodeResponse;
    createPromoCodeV2: CreatePromoCodeResponseV2;
    createPsmGrpCashSalesTx: CreatePsmgrpCashSalesTxResponse;
    createResource: CreateResourceResponse;
    createResourceLinks: CreateResourceLinksResponse;
    createResourceType: CreateResourceTypeResponse;
    createSchedule: CreateScheduleResponse;
    createService: CreateServiceResponse;
    createSettlement: CreateSettlementResponse;
    createSettlementConfiguration: CreateSettlementConfigurationResponse;
    createSettlementConfigurationWithApproval: CreateSettlementConfigurationWithApprovalResponse;
    createTag: CreateTagResponse;
    createTrigger?: Maybe<CreateTriggerResponse>;
    createUserProfile: CreateUserProfileResponse;
    createUserProfileManual: CreateUserProfileManualResponse;
    deactivateLightEvents?: Maybe<DeactivateLightEventsResponse>;
    editCustomerInfo: EditCustomerInfoResponse;
    editGame: EditGameResponse;
    editGameOrganizer: EditGameOrganizerResponse;
    editLocation: EditLocationResponse;
    editPaymentMethodAvailability: EditPaymentMethodAvailabilityResponse;
    editPaymentMethodMetadata: EditPaymentMethodMetadataResponse;
    editPaymentMethodName: EditPaymentMethodNameResponse;
    editPromoCodeV2: EditPromoCodeResponseV2;
    editSettlementConfiguration: EditSettlementConfigurationResponse;
    editSettlementConfigurationWithApproval: EditSettlementConfigurationWithApprovalResponse;
    editTag: EditTagResponse;
    expirePromoCodeRedemption: ExpirePromoCodeRedemptionResponse;
    forceRetryCapturePayment: ForceRetryCapturePaymentResponse;
    generateSettlementDownloadLink: GenerateSettlementDownloadLinkResponse;
    initiatePaymentSession: InitiatePaymentSessionResponse;
    joinGame: JoinGameResponse;
    leaveGame: LeaveGameResponse;
    logCourtsiteCheckoutCustomEvent: LogCourtsiteCheckoutCustomEventResponse;
    makeBookings: MakeBookingsResponse;
    modifyAddOn: ModifyAddOnResponse;
    modifyBookingGuest: ModifyBookingGuestResponse;
    modifyBookings: ModifyBookingsResponse;
    modifyCancelledReason: ModifyCancelledReasonResponse;
    modifyInvoiceItems: ModifyInvoiceItemsResponse;
    payInvoice: PayInvoiceResponse;
    provideCapturePaymentProviderDetails: ProvideCapturePaymentProviderDetailsResponse;
    provideCourtsiteCheckoutPaymentDetails: ProvideCourtsiteCheckoutPaymentDetailsResponse;
    provideCourtsiteReschedulePaymentDetails: ProvideCourtsiteReschedulePaymentDetailsResponse;
    redeemPromoCode: RedeemPromoCodeResponse;
    removeBookingGuest: RemoveBookingGuestResponse;
    renameCategory: RenameCategoryResponse;
    renameResource: RenameResourceResponse;
    renameService: RenameServiceResponse;
    rescheduleBooking: RescheduleBookingResponse;
    reviewSettlementConfigurationWithApproval: ReviewSettlementConfigurationWithApprovalResponse;
    reviveBookings: ReviveBookingsResponse;
    setAddOnIsInStock: SetAddOnIsInStockResponse;
    setDailyServiceTimes: SetDailyServiceTimesResponse;
    setLhdnSubmissionStatus: SetLhdnSubmissionStatusResponse;
    setLightDuration?: Maybe<SetLightDurationResponse>;
    setMandatoryUserProfile: SetMandatoryUserProfileResponse;
    setMemberCapabilities: SetMemberCapabilitiesResponse;
    setMemberRole: MemberRoleSetResponse;
    setOrganisationIsCourtsitePartner: SetOrganisationIsCourtsitePartnerResponse;
    setOrganisationWeightages: SetOrganisationWeightagesResponse;
    setPromoCodeCriteria: SetPromoCodeCriteriaResponse;
    setPromoCodeDiscount: SetPromoCodeDiscountResponse;
    setServiceBookingLimits: SetServiceBookingLimitsResponse;
    setServiceDurationOptions: SetServiceDurationOptionsResponse;
    setServiceTags: SetServiceTagsResponse;
    setUserProfile: SetUserProfileResponse;
    setUserProfileManual: SetUserProfileManualResponse;
    startCancelBookingWorkflow: StartCancelBookingWorkflowResponse;
    startCancelBookings: StartCancelBookingsResponse;
    startCancelPaymentWorkflow: StartCancelPaymentWorkflowResponse;
    startCourtsiteCheckout: StartCourtsiteCheckoutResponse;
    startCourtsiteGameWorkflow: StartCourtsiteGameWorkflowResponse;
    startCourtsiteReschedule: StartCourtsiteRescheduleResponse;
    startGeneratePsmGrpCashSaleTx: StartGeneratePsmGrpCashSaleTxResponse;
    startGenerateSettlementReport: GenerateSettlementReportResponse;
    startKonsolCancelInvoice: StartKonsolCancelInvoiceResponse;
    startKonsolCheckout: StartKonsolCheckoutResponse;
    startKonsolCreateInvoice: StartKonsolCreateInvoiceResponse;
    startKonsolPayInvoice: StartKonsolPayInvoiceResponse;
    startRecoverPaymentSession: StartRecoverPaymentSessionResponse;
    startRescheduleRecurringBookings: StartRescheduleRecurringBookingsResponse;
    startSettlementWatcher: StartSettlementWatcherResponse;
    startSubmitLHDNEInvoices: StartSubmitLhdneInvoicesResponse;
    submitLhdnInvoice: SubmitLhdnInvoiceResponse;
    syncEvents?: Maybe<SyncEventsResponse>;
    syncUserProfileToFirebase: SyncUserProfileToFirebaseResponse;
    unapproveOrganisation: UnapproveOrganisationResponse;
    uncancelInvoice: UncancelInvoiceResponse;
    uncancelPayment: UncancelPaymentResponse;
    unconfirmBooking: UnconfirmBookingResponse;
    updateBookingMetadata: UpdateBookingMetadataResponse;
    updateBookingReferenceId: UpdateBookingReferenceIdResponse;
    updateBookingsReferenceId: UpdateBookingsReferenceIdResponse;
    updateCategoryMetadata: UpdateCategoryMetadataResponse;
    updateCustomerMetadata: UpdateCustomerMetadataResponse;
    updateFixedDurationOptions?: Maybe<UpdateFixedDurationOptionsResponse>;
    updateInvoiceCourtsiteOwnership: UpdateInvoiceCourtsiteOwnershipResponse;
    updateInvoiceItemRelatedEntity: UpdateInvoiceItemRelatedEntityResponse;
    updateInvoiceMetadata: UpdateInvoiceMetadataResponse;
    updateLight?: Maybe<UpdateLightResponse>;
    updateMyProfileEmail: UpdateMyProfileEmailResponse;
    updateMyProfilePhoneNumber: UpdateMyProfilePhoneNumberResponse;
    updateOrganisation: UpdateOrganisationResponse;
    updateOverride?: Maybe<UpdateOverrideResponse>;
    updateResourceTypesMetadata: UpdateResourceTypesMetadataResponse;
    updateSchedule: UpdateScheduleResponse;
    updateServiceMetadata: UpdateServiceMetadataResponse;
    updateServiceVisibility: UpdateServiceVisibilityResponse;
    updateUser: UpdateUserResponse;
};

export type MutationActivateLightEventsArgs = {
    request: ActivateLightEventsRequest;
};

export type MutationActivatePromoCodeArgs = {
    request: ActivatePromoCodeRequest;
};

export type MutationAddAssociatedUserArgs = {
    request: AddAssociatedUserRequest;
};

export type MutationAddBookingGuestArgs = {
    request: AddBookingGuestRequest;
};

export type MutationAddBookingGuestToBookingsArgs = {
    request: AddBookingGuestToBookingsRequest;
};

export type MutationAddMemberToOrganisationArgs = {
    request: AddMemberToOrganisationRequest;
};

export type MutationAddMemberToOrganisationWithoutFirebaseArgs = {
    request: AddMemberToOrganisationWithoutFirebaseRequest;
};

export type MutationAddPaymentMethodArgs = {
    request: AddPaymentMethodRequest;
};

export type MutationAddServiceResourceArgs = {
    request: AddServiceResourceRequest;
};

export type MutationApproveOrganisationArgs = {
    request: ApproveOrganisationRequest;
};

export type MutationArchiveAddOnArgs = {
    request: ArchiveAddOnRequest;
};

export type MutationArchiveCustomerArgs = {
    request: ArchiveCustomerRequest;
};

export type MutationArchiveEventsArgs = {
    request: ArchiveEventsRequest;
};

export type MutationArchiveGameArgs = {
    request: ArchiveGameRequest;
};

export type MutationArchiveLocationArgs = {
    request: ArchiveLocationRequest;
};

export type MutationArchiveMemberArgs = {
    request: ArchiveMemberRequest;
};

export type MutationArchivePaymentMethodArgs = {
    request: ArchivePaymentMethodRequest;
};

export type MutationArchivePromoCodeV2Args = {
    request: ArchivePromoCodeRequestV2;
};

export type MutationArchiveResourceArgs = {
    request: ArchiveResourceRequest;
};

export type MutationArchiveResourceLinksArgs = {
    request: ArchiveResourceLinksRequest;
};

export type MutationArchiveResourceTypeArgs = {
    request: ArchiveResourceTypeRequest;
};

export type MutationArchiveScheduleArgs = {
    request: ArchiveScheduleRequest;
};

export type MutationArchiveServiceArgs = {
    request: ArchiveServiceRequest;
};

export type MutationArchiveServiceResourceArgs = {
    request: ArchiveServiceResourceRequest;
};

export type MutationArchiveSettlementArgs = {
    request: ArchiveSettlementRequest;
};

export type MutationArchiveTagArgs = {
    request: ArchiveTagRequest;
};

export type MutationArchiveTriggerArgs = {
    request: ArchiveTriggerRequest;
};

export type MutationAssignServiceToCategoryArgs = {
    request: AssignServiceToCategoryRequest;
};

export type MutationCancelBookingArgs = {
    request: CancelBookingRequest;
};

export type MutationCancelBookingsArgs = {
    request: CancelBookingsRequest;
};

export type MutationCancelCapturePaymentWorkflowArgs = {
    request: CancelCapturePaymentWorkflowRequest;
};

export type MutationCancelCourtsiteCheckoutArgs = {
    request: CancelCourtsiteCheckoutRequest;
};

export type MutationCancelInvoiceArgs = {
    request: CancelInvoiceRequest;
};

export type MutationCancelKonsolCheckoutArgs = {
    request: CancelKonsolCheckoutRequest;
};

export type MutationCancelKonsolPayInvoiceArgs = {
    request: CancelKonsolPayInvoiceRequest;
};

export type MutationCancelPaymentArgs = {
    request: CancelPaymentRequest;
};

export type MutationCancelPaymentSessionArgs = {
    request: CancelPaymentSessionRequest;
};

export type MutationChangeResourceTypeArgs = {
    request: ChangeResourceTypeRequest;
};

export type MutationCheckPaymentSessionArgs = {
    request: CheckPaymentSessionRequest;
};

export type MutationConfirmBookingArgs = {
    request: ConfirmBookingRequest;
};

export type MutationConfirmBookingsArgs = {
    request: ConfirmBookingsRequest;
};

export type MutationConfirmCapturePaymentArgs = {
    request: ConfirmCapturePaymentRequest;
};

export type MutationConfirmPaymentSessionArgs = {
    request: ConfirmPaymentSessionRequest;
};

export type MutationCreateAddOnArgs = {
    request: CreateAddOnRequest;
};

export type MutationCreateCategoryArgs = {
    request: CreateCategoryRequest;
};

export type MutationCreateCustomerArgs = {
    request: CreateCustomerRequest;
};

export type MutationCreateCustomersArgs = {
    request: CreateCustomersRequest;
};

export type MutationCreateDailyServiceArgs = {
    request: CreateDailyServiceRequest;
};

export type MutationCreateGameArgs = {
    request: CreateGameRequest;
};

export type MutationCreateInvoiceArgs = {
    request: CreateInvoiceRequest;
};

export type MutationCreateLightArgs = {
    request: CreateLightRequest;
};

export type MutationCreateLocationArgs = {
    request: CreateLocationRequest;
};

export type MutationCreateOrganisationArgs = {
    request: CreateOrganisationRequest;
};

export type MutationCreateOverrideArgs = {
    request: CreateOverrideRequest;
};

export type MutationCreatePriceRulesetsForServicesArgs = {
    request: CreatePriceRulesetsForServicesRequest;
};

export type MutationCreatePromoCodeArgs = {
    request: CreatePromoCodeRequest;
};

export type MutationCreatePromoCodeV2Args = {
    request: CreatePromoCodeRequestV2;
};

export type MutationCreatePsmGrpCashSalesTxArgs = {
    request: CreatePsmgrpCashSalesTxRequest;
};

export type MutationCreateResourceArgs = {
    request: CreateResourceRequest;
};

export type MutationCreateResourceLinksArgs = {
    request: CreateResourceLinksRequest;
};

export type MutationCreateResourceTypeArgs = {
    request: CreateResourceTypeRequest;
};

export type MutationCreateScheduleArgs = {
    request: CreateScheduleRequest;
};

export type MutationCreateServiceArgs = {
    request: CreateServiceRequest;
};

export type MutationCreateSettlementArgs = {
    request: CreateSettlementRequest;
};

export type MutationCreateSettlementConfigurationArgs = {
    request: CreateSettlementConfigurationRequest;
};

export type MutationCreateSettlementConfigurationWithApprovalArgs = {
    request: CreateSettlementConfigurationWithApprovalRequest;
};

export type MutationCreateTagArgs = {
    request: CreateTagRequest;
};

export type MutationCreateTriggerArgs = {
    request: CreateTriggerRequest;
};

export type MutationCreateUserProfileArgs = {
    request: CreateUserProfileRequest;
};

export type MutationCreateUserProfileManualArgs = {
    request: CreateUserProfileManualRequest;
};

export type MutationDeactivateLightEventsArgs = {
    request: DeactivateLightEventsRequest;
};

export type MutationEditCustomerInfoArgs = {
    request: EditCustomerInfoRequest;
};

export type MutationEditGameArgs = {
    request: EditGameRequest;
};

export type MutationEditGameOrganizerArgs = {
    request: EditGameOrganizerRequest;
};

export type MutationEditLocationArgs = {
    request: EditLocationRequest;
};

export type MutationEditPaymentMethodAvailabilityArgs = {
    request: EditPaymentMethodAvailabilityRequest;
};

export type MutationEditPaymentMethodMetadataArgs = {
    request: EditPaymentMethodMetadataRequest;
};

export type MutationEditPaymentMethodNameArgs = {
    request: EditPaymentMethodNameRequest;
};

export type MutationEditPromoCodeV2Args = {
    request: EditPromoCodeRequestV2;
};

export type MutationEditSettlementConfigurationArgs = {
    request: EditSettlementConfigurationRequest;
};

export type MutationEditSettlementConfigurationWithApprovalArgs = {
    request: EditSettlementConfigurationWithApprovalRequest;
};

export type MutationEditTagArgs = {
    request: EditTagRequest;
};

export type MutationExpirePromoCodeRedemptionArgs = {
    request: ExpirePromoCodeRedemptionRequest;
};

export type MutationForceRetryCapturePaymentArgs = {
    request: ForceRetryCapturePaymentRequest;
};

export type MutationGenerateSettlementDownloadLinkArgs = {
    request: GenerateSettlementDownloadLinkRequest;
};

export type MutationInitiatePaymentSessionArgs = {
    request: InitiatePaymentSessionRequest;
};

export type MutationJoinGameArgs = {
    request: JoinGameRequest;
};

export type MutationLeaveGameArgs = {
    request: LeaveGameRequest;
};

export type MutationLogCourtsiteCheckoutCustomEventArgs = {
    request: LogCourtsiteCheckoutCustomEventRequest;
};

export type MutationMakeBookingsArgs = {
    request: MakeBookingsRequest;
};

export type MutationModifyAddOnArgs = {
    request: ModifyAddOnRequest;
};

export type MutationModifyBookingGuestArgs = {
    request: ModifyBookingGuestRequest;
};

export type MutationModifyBookingsArgs = {
    request: ModifyBookingsRequest;
};

export type MutationModifyCancelledReasonArgs = {
    request: ModifyCancelledReasonRequest;
};

export type MutationModifyInvoiceItemsArgs = {
    request: ModifyInvoiceItemsRequest;
};

export type MutationPayInvoiceArgs = {
    request: PayInvoiceRequest;
};

export type MutationProvideCapturePaymentProviderDetailsArgs = {
    request: ProvideCapturePaymentProviderDetailsRequest;
};

export type MutationProvideCourtsiteCheckoutPaymentDetailsArgs = {
    request: ProvideCourtsiteCheckoutPaymentDetailsRequest;
};

export type MutationProvideCourtsiteReschedulePaymentDetailsArgs = {
    request: ProvideCourtsiteReschedulePaymentDetailsRequest;
};

export type MutationRedeemPromoCodeArgs = {
    request: RedeemPromoCodeRequest;
};

export type MutationRemoveBookingGuestArgs = {
    request: RemoveBookingGuestRequest;
};

export type MutationRenameCategoryArgs = {
    request: RenameCategoryRequest;
};

export type MutationRenameResourceArgs = {
    request: RenameResourceRequest;
};

export type MutationRenameServiceArgs = {
    request: RenameServiceRequest;
};

export type MutationRescheduleBookingArgs = {
    request: RescheduleBookingRequest;
};

export type MutationReviewSettlementConfigurationWithApprovalArgs = {
    request: ReviewSettlementConfigurationWithApprovalRequest;
};

export type MutationReviveBookingsArgs = {
    request: ReviveBookingsRequest;
};

export type MutationSetAddOnIsInStockArgs = {
    request: SetAddOnIsInStockRequest;
};

export type MutationSetDailyServiceTimesArgs = {
    request: SetDailyServiceTimesRequest;
};

export type MutationSetLhdnSubmissionStatusArgs = {
    request: SetLhdnSubmissionStatusRequest;
};

export type MutationSetLightDurationArgs = {
    request: SetLightDurationRequest;
};

export type MutationSetMandatoryUserProfileArgs = {
    request: SetMandatoryUserProfileRequest;
};

export type MutationSetMemberCapabilitiesArgs = {
    request: SetMemberCapabilitiesRequest;
};

export type MutationSetMemberRoleArgs = {
    request: SetMemberRoleRequest;
};

export type MutationSetOrganisationIsCourtsitePartnerArgs = {
    request: SetOrganisationIsCourtsitePartnerRequest;
};

export type MutationSetOrganisationWeightagesArgs = {
    request: SetOrganisationWeightagesRequest;
};

export type MutationSetPromoCodeCriteriaArgs = {
    request: SetPromoCodeCriteriaRequest;
};

export type MutationSetPromoCodeDiscountArgs = {
    request: SetPromoCodeDiscountRequest;
};

export type MutationSetServiceBookingLimitsArgs = {
    request: SetServiceBookingLimitsRequest;
};

export type MutationSetServiceDurationOptionsArgs = {
    request: SetServiceDurationOptionsRequest;
};

export type MutationSetServiceTagsArgs = {
    request: SetServiceTagsRequest;
};

export type MutationSetUserProfileArgs = {
    request: SetUserProfileRequest;
};

export type MutationSetUserProfileManualArgs = {
    request: SetUserProfileManualRequest;
};

export type MutationStartCancelBookingWorkflowArgs = {
    request: StartCancelBookingWorkflowRequest;
};

export type MutationStartCancelBookingsArgs = {
    request: StartCancelBookingsRequest;
};

export type MutationStartCancelPaymentWorkflowArgs = {
    request: StartCancelPaymentWorkflowRequest;
};

export type MutationStartCourtsiteCheckoutArgs = {
    request: StartCourtsiteCheckoutRequest;
};

export type MutationStartCourtsiteGameWorkflowArgs = {
    request: StartCourtsiteGameWorkflowRequest;
};

export type MutationStartCourtsiteRescheduleArgs = {
    request: StartCourtsiteRescheduleRequest;
};

export type MutationStartGeneratePsmGrpCashSaleTxArgs = {
    request: StartGeneratePsmGrpCashSaleTxRequest;
};

export type MutationStartGenerateSettlementReportArgs = {
    request: GenerateSettlementReportRequest;
};

export type MutationStartKonsolCancelInvoiceArgs = {
    request: StartKonsolCancelInvoiceRequest;
};

export type MutationStartKonsolCheckoutArgs = {
    request: StartKonsolCheckoutRequest;
};

export type MutationStartKonsolCreateInvoiceArgs = {
    request: StartKonsolCreateInvoiceRequest;
};

export type MutationStartKonsolPayInvoiceArgs = {
    request: StartKonsolPayInvoiceRequest;
};

export type MutationStartRecoverPaymentSessionArgs = {
    request: StartRecoverPaymentSessionRequest;
};

export type MutationStartRescheduleRecurringBookingsArgs = {
    request: StartRescheduleRecurringBookingsRequest;
};

export type MutationStartSettlementWatcherArgs = {
    request: StartSettlementWatcherRequest;
};

export type MutationStartSubmitLhdneInvoicesArgs = {
    request: StartSubmitLhdneInvoicesRequest;
};

export type MutationSubmitLhdnInvoiceArgs = {
    request: SubmitLhdnInvoiceRequest;
};

export type MutationSyncEventsArgs = {
    request: SyncEventsRequest;
};

export type MutationSyncUserProfileToFirebaseArgs = {
    firebaseId: Scalars["ID"]["input"];
};

export type MutationUnapproveOrganisationArgs = {
    request: UnapproveOrganisationRequest;
};

export type MutationUncancelInvoiceArgs = {
    request: UncancelInvoiceRequest;
};

export type MutationUncancelPaymentArgs = {
    request: UncancelPaymentRequest;
};

export type MutationUnconfirmBookingArgs = {
    request: UnconfirmBookingRequest;
};

export type MutationUpdateBookingMetadataArgs = {
    request: UpdateBookingMetadataRequest;
};

export type MutationUpdateBookingReferenceIdArgs = {
    request: UpdateBookingReferenceIdRequest;
};

export type MutationUpdateBookingsReferenceIdArgs = {
    request: UpdateBookingsReferenceIdRequest;
};

export type MutationUpdateCategoryMetadataArgs = {
    request: UpdateCategoryMetadataRequest;
};

export type MutationUpdateCustomerMetadataArgs = {
    request: UpdateCustomerMetadataRequest;
};

export type MutationUpdateFixedDurationOptionsArgs = {
    request: UpdateFixedDurationOptionsRequest;
};

export type MutationUpdateInvoiceCourtsiteOwnershipArgs = {
    request: UpdateInvoiceCourtsiteOwnershipRequest;
};

export type MutationUpdateInvoiceItemRelatedEntityArgs = {
    request: UpdateInvoiceItemRelatedEntityRequest;
};

export type MutationUpdateInvoiceMetadataArgs = {
    request: UpdateInvoiceMetadataRequest;
};

export type MutationUpdateLightArgs = {
    request: UpdateLightRequest;
};

export type MutationUpdateMyProfileEmailArgs = {
    request: UpdateMyProfileEmailRequest;
};

export type MutationUpdateMyProfilePhoneNumberArgs = {
    request: UpdateMyProfilePhoneNumberRequest;
};

export type MutationUpdateOrganisationArgs = {
    request: UpdateOrganisationRequest;
};

export type MutationUpdateOverrideArgs = {
    request: UpdateOverrideRequest;
};

export type MutationUpdateResourceTypesMetadataArgs = {
    request: UpdateResourceTypesMetadataRequests;
};

export type MutationUpdateScheduleArgs = {
    request: UpdatedScheduleRequest;
};

export type MutationUpdateServiceMetadataArgs = {
    request: UpdateServiceMetadataRequest;
};

export type MutationUpdateServiceVisibilityArgs = {
    request: UpdateServiceVisibilityRequest;
};

export type MutationUpdateUserArgs = {
    request: UpdateUserRequest;
};

export type MyBookingsFilter = {
    byCancelReason?: InputMaybe<CancelledReason>;
    byEndDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byEndDateBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStartDate?: InputMaybe<DateRangeFilter>;
    byStartDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStartDateBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStatus?: InputMaybe<BookingStatus>;
};

export type MyGroupedBookingsFilter = {
    byCategoryId?: InputMaybe<Scalars["ID"]["input"]>;
    byEndDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byEndDateBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStartDateAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStartDateBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
    byStatus?: InputMaybe<BookingStatus>;
};

export type MyInvoiceStatisticFilter = {
    byCreatedAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byCreatedBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MyInvoicesFilter = {
    byInvoiceIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    byRelatedEntityId?: InputMaybe<Scalars["String"]["input"]>;
    byRelatedEntityIds?: InputMaybe<
        Array<InputMaybe<Scalars["String"]["input"]>>
    >;
    byStatus?: InputMaybe<InvoiceStatus>;
};

export type MyInvoicesPagedFilter = {
    byStatus?: InputMaybe<InvoiceStatus>;
};

export type NoPlayerSlotError = {
    message: Scalars["String"]["output"];
};

export type NotFoundError = {
    message: Scalars["String"]["output"];
};

export type Organisation = {
    amenities: Array<Amenities>;
    approved?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    isCourtsitePartner: Scalars["Boolean"]["output"];
    location: Coordinates;
    members: Array<Member>;
    metadata: Scalars["JSONString"]["output"];
    myCapabilities: Array<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    services: Array<Scalars["String"]["output"]>;
    uid: Scalars["ID"]["output"];
    weightage: Scalars["Float"]["output"];
};

export type OrganisationApproved = {
    organisation: Organisation;
};

export type OrganisationConnection = {
    edges: Array<OrganisationEdge>;
    pageInfo: PageInfo;
};

export type OrganisationCreated = {
    organisationId: Scalars["ID"]["output"];
    ownerId: Scalars["ID"]["output"];
};

export type OrganisationEdge = {
    cursor: Scalars["String"]["output"];
    node: Organisation;
};

export type OrganisationIsCourtsitePartnerSet = {
    organisation: Organisation;
};

export type OrganisationMemberAdded = {
    memberId: Scalars["ID"]["output"];
    organisationId: Scalars["ID"]["output"];
};

export type OrganisationSortOrder =
    | "BY_NAME_ASC"
    | "BY_NAME_DESC"
    | "BY_UID_ASC"
    | "BY_UID_DESC"
    | "BY_WEIGHTAGE_ASC"
    | "BY_WEIGHTAGE_DESC";

export type OrganisationUnapproved = {
    organisation: Organisation;
};

export type OrganisationUpdated = {
    organisationId: Scalars["ID"]["output"];
};

export type OrganisationWeightage = {
    organisationId: Scalars["ID"]["output"];
    weightage: Scalars["Float"]["output"];
};

export type OrganisationWeightageInput = {
    organisationId: Scalars["ID"]["input"];
    weightage: Scalars["Float"]["input"];
};

export type OrganisationWeightagesSet = {
    organisationWeightages?: Maybe<Array<OrganisationWeightage>>;
};

export type Organizer = {
    displayPicture?: Maybe<Scalars["String"]["output"]>;
    email?: Maybe<Scalars["EmailAddress"]["output"]>;
    name: Scalars["String"]["output"];
    organizerId: Scalars["ID"]["output"];
    phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
};

export type Override = Command & {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    end: Scalars["DateTime"]["output"];
    light?: Maybe<Light>;
    lightId: Scalars["ID"]["output"];
    lightState: LightState;
    metadata: Scalars["JSONString"]["output"];
    start: Scalars["DateTime"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type OverrideCreated = {
    override: Override;
};

export type OverrideUpdated = {
    override: Override;
};

export type PsmgrpCashSalesTxCreated = {
    txId: Scalars["String"]["output"];
};

export type PageInfo = {
    endCursor?: Maybe<Scalars["String"]["output"]>;
    hasNextPage?: Maybe<Scalars["Boolean"]["output"]>;
    hasPreviousPage?: Maybe<Scalars["Boolean"]["output"]>;
    startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PageInput = {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PartyDetails = {
    address: Address;
    email: Scalars["EmailAddress"]["input"];
    idNumber: Scalars["String"]["input"];
    idType: IdType;
    name: Scalars["String"]["input"];
    phoneNumber: Scalars["String"]["input"];
    sstId?: InputMaybe<Scalars["String"]["input"]>;
    tin: Scalars["String"]["input"];
};

export type PayInvoiceRequest = {
    amount: Scalars["Int"]["input"];
    externalReference?: InputMaybe<Scalars["String"]["input"]>;
    idempotentKey?: InputMaybe<Scalars["String"]["input"]>;
    invoiceId: Scalars["ID"]["input"];
    paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
    paymentType?: InputMaybe<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
    uncancel?: InputMaybe<Scalars["Boolean"]["input"]>;
    userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PayInvoiceResponse =
    | AccessError
    | InternalError
    | InvoicePaid
    | NotFoundError;

export type Payment = {
    amount: Scalars["Int"]["output"];
    cancelled?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    externalReference?: Maybe<Scalars["String"]["output"]>;
    idempotentKey: Scalars["String"]["output"];
    invoice: Invoice;
    metadata: Scalars["JSONString"]["output"];
    paymentMethod?: Maybe<PaymentMethod>;
    paymentMethodId?: Maybe<Scalars["String"]["output"]>;
    paymentType?: Maybe<Scalars["String"]["output"]>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type PaymentAlreadyCancelled = {
    message: Scalars["String"]["output"];
};

export type PaymentCancelled = {
    invoiceId: Scalars["ID"]["output"];
    paymentId: Scalars["ID"]["output"];
};

export type PaymentFilter = {
    byCreatedDate?: InputMaybe<DateRangeFilter>;
    byInvoiceeName?: InputMaybe<Scalars["String"]["input"]>;
    byPaymentType?: InputMaybe<Scalars["String"]["input"]>;
};

export type PaymentMethod = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    availability: Scalars["Boolean"]["output"];
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PaymentMethodAdded = {
    paymentMethod: PaymentMethod;
};

export type PaymentMethodAlreadyArchived = {
    message: Scalars["String"]["output"];
};

export type PaymentMethodArchived = {
    paymentMethod: PaymentMethod;
};

export type PaymentMethodAvailabilityEdited = {
    availability: Scalars["Boolean"]["output"];
    paymentMethodId: Scalars["ID"]["output"];
};

export type PaymentMethodMetadataEdited = {
    metadata: Scalars["JSONString"]["output"];
    paymentMethodId: Scalars["ID"]["output"];
};

export type PaymentMethodNameEdited = {
    name: Scalars["String"]["output"];
    paymentMethodId: Scalars["ID"]["output"];
};

/** An enumeration. */
export type PaymentProvider =
    | "BILLPLZ"
    | "IPAY88"
    | "KIPLEPAY"
    | "MASTERCARD"
    | "RAZORPAY"
    | "SPAY";

export type PaymentSession = {
    amount: Scalars["Int"]["output"];
    created: Scalars["DateTime"]["output"];
    currentState: PaymentState;
    customReferenceId?: Maybe<Scalars["String"]["output"]>;
    description: Scalars["String"]["output"];
    expiryInMinutes: Scalars["Int"]["output"];
    externalId?: Maybe<Scalars["String"]["output"]>;
    isExpired: Scalars["Boolean"]["output"];
    metadata: Scalars["JSONString"]["output"];
    payerEmail?: Maybe<Scalars["EmailAddress"]["output"]>;
    payerName: Scalars["String"]["output"];
    payerPhoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    provider: PaymentProvider;
    providerPaymentMethod: Scalars["String"]["output"];
    stateTransitions: Array<PaymentStateTransition>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type PaymentSessionCancelled = {
    message: Scalars["String"]["output"];
};

export type PaymentSessionConfirmed = {
    message: Scalars["String"]["output"];
    paymentSession: PaymentSession;
};

export type PaymentSessionNotCancellable = {
    message: Scalars["String"]["output"];
};

export type PaymentSessionRecovered = {
    state: RecoverPaymentSessionState;
};

export type PaymentSessionUnconfirmed = {
    message: Scalars["String"]["output"];
    paymentSession: PaymentSession;
};

/** An enumeration. */
export type PaymentState =
    | "CANCELLED"
    | "CONFIRMED"
    | "CREATED"
    | "ERROR"
    | "REJECTED"
    | "UNCONFIRMED"
    | "WAITING";

export type PaymentStateTransition = {
    created: Scalars["DateTime"]["output"];
    currentState: PaymentState;
    data: Scalars["String"]["output"];
    metadata: Scalars["JSONString"]["output"];
    previousState?: Maybe<PaymentState>;
    uid: Scalars["ID"]["output"];
};

export type PaymentUncancelled = {
    invoice: Invoice;
};

export type PlayerAddedEvent = GameEvent & {
    created: Scalars["DateTime"]["output"];
    player: GamePlayer;
    uid: Scalars["ID"]["output"];
};

export type PlayerRemovedEvent = GameEvent & {
    created: Scalars["DateTime"]["output"];
    playerId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type PriceRate = {
    minimumDurationMinutes: Scalars["Int"]["output"];
    perHourRate: Scalars["Int"]["output"];
};

export type PriceRateInput = {
    minimumDurationMinutes: Scalars["Int"]["input"];
    perHourRate: Scalars["Int"]["input"];
};

export type PriceRule = {
    created: Scalars["DateTime"]["output"];
    deposit: Scalars["Int"]["output"];
    depositType: DepositType;
    end: DayTime;
    metadata: Scalars["JSONString"]["output"];
    rulesetId: Scalars["ID"]["output"];
    start: DayTime;
    uid: Scalars["ID"]["output"];
};

export type PriceRuleDurationRate = PriceRule & {
    created: Scalars["DateTime"]["output"];
    deposit: Scalars["Int"]["output"];
    depositType: DepositType;
    end: DayTime;
    metadata: Scalars["JSONString"]["output"];
    rates: Array<PriceRate>;
    rulesetId: Scalars["ID"]["output"];
    start: DayTime;
    uid: Scalars["ID"]["output"];
};

export type PriceRulePerDayRate = PriceRule & {
    created: Scalars["DateTime"]["output"];
    deposit: Scalars["Int"]["output"];
    depositType: DepositType;
    end: DayTime;
    metadata: Scalars["JSONString"]["output"];
    perDayRate: Scalars["Int"]["output"];
    rulesetId: Scalars["ID"]["output"];
    start: DayTime;
    uid: Scalars["ID"]["output"];
};

export type PriceRulePerUnitRate = PriceRule & {
    created: Scalars["DateTime"]["output"];
    deposit: Scalars["Int"]["output"];
    depositType: DepositType;
    end: DayTime;
    metadata: Scalars["JSONString"]["output"];
    perUnitRate: Scalars["Int"]["output"];
    rulesetId: Scalars["ID"]["output"];
    start: DayTime;
    uid: Scalars["ID"]["output"];
};

export type PriceRuleset = {
    appliesTo: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    effectiveFrom: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    rules: Array<PriceRule>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type PriceRulesetsForServicesCreated = {
    rulesets: Array<PriceRuleset>;
};

export type PromoCode = {
    code: Scalars["ID"]["output"];
    discount: Scalars["Int"]["output"];
    endDt: Scalars["DateTime"]["output"];
    isAvailable: Scalars["Boolean"]["output"];
    itemTypeIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
    metadata?: Maybe<Scalars["JSONString"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
    tenantIds?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
    tenants: Array<Organisation>;
    type: DiscountType;
};

export type PromoCodeActivated = {
    promocodeCode: Scalars["ID"]["output"];
};

export type PromoCodeAlreadyExists = {
    message: Scalars["String"]["output"];
};

export type PromoCodeArchivedV2 = {
    promoCode: PromoCodeV2;
};

export type PromoCodeCreated = {
    promocodeCode: Scalars["ID"]["output"];
};

export type PromoCodeCreatedV2 = {
    promoCode: PromoCodeV2;
};

export type PromoCodeDiscount = {
    discountedAmount: Scalars["Int"]["output"];
    items: Array<PromoCodeDiscountItemBreakdown>;
    promoCode: PromoCodeV2;
    subtotal: Scalars["Int"]["output"];
    total: Scalars["Int"]["output"];
};

export type PromoCodeDiscountItem = {
    bookingStart?: InputMaybe<Scalars["DateTime"]["input"]>;
    perUnitPrice: Scalars["Int"]["input"];
    quantity: Scalars["Int"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type PromoCodeDiscountItemBreakdown = {
    bookingStart?: Maybe<Scalars["DateTime"]["output"]>;
    discountAmount: Scalars["Int"]["output"];
    discountType: DiscountTypeV2;
    discountedAmount: Scalars["Int"]["output"];
    perUnitPrice: Scalars["Int"]["output"];
    quantity: Scalars["Int"]["output"];
    serviceId: Scalars["ID"]["output"];
    subtotal: Scalars["Int"]["output"];
    tenantId: Scalars["ID"]["output"];
    total: Scalars["Int"]["output"];
};

export type PromoCodeDiscountSet = {
    promocodeCode: Scalars["ID"]["output"];
};

export type PromoCodeEditedV2 = {
    promoCode: PromoCodeV2;
};

export type PromoCodeExpired = {
    message: Scalars["String"]["output"];
};

export type PromoCodeFilter = {
    byActivePeriodAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    byActivePeriodBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
    byAvailability?: InputMaybe<Scalars["Boolean"]["input"]>;
    byItemTypeId?: InputMaybe<Scalars["ID"]["input"]>;
    byTenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PromoCodeInactive = {
    message: Scalars["String"]["output"];
};

export type PromoCodeRedeemed = {
    discount: PromoCodeDiscount;
    redemptionId: Scalars["ID"]["output"];
};

export type PromoCodeRedemptionExpired = {
    promoCodeRedemptionId: Scalars["ID"]["output"];
};

export type PromoCodeRedemptionLimitExceeded = {
    message: Scalars["String"]["output"];
};

export type PromoCodeV2 = {
    applicableServiceIds: Array<Scalars["String"]["output"]>;
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    bookingEnd?: Maybe<Scalars["DateTime"]["output"]>;
    bookingStart?: Maybe<Scalars["DateTime"]["output"]>;
    code: Scalars["String"]["output"];
    created: Scalars["DateTime"]["output"];
    discountAmount: Scalars["Int"]["output"];
    discountType: DiscountTypeV2;
    endDt?: Maybe<Scalars["DateTime"]["output"]>;
    name: Scalars["String"]["output"];
    redemptionCount: Scalars["Int"]["output"];
    redemptionLimit: Scalars["Int"]["output"];
    startDt: Scalars["DateTime"]["output"];
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ProvideCapturePaymentProviderDetailsRequest = {
    payerDetailsEmail?: InputMaybe<Scalars["String"]["input"]>;
    payerDetailsName?: InputMaybe<Scalars["String"]["input"]>;
    payerDetailsPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
    paymentProvider?: InputMaybe<PaymentProvider>;
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    workflowId: Scalars["ID"]["input"];
};

export type ProvideCapturePaymentProviderDetailsResponse =
    | CapturePaymentProviderDetailsProvided
    | WorkflowCancelled
    | WorkflowNotFound
    | WorkflowNotReady;

export type ProvideCourtsiteCheckoutPaymentDetailsRequest = {
    checkoutId: Scalars["ID"]["input"];
    email: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    paymentProvider: PaymentProvider;
    phoneNumber: Scalars["String"]["input"];
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProvideCourtsiteCheckoutPaymentDetailsResponse =
    | CourtsiteCheckoutExpired
    | CourtsiteCheckoutPaymentDetailsProvided
    | WorkflowCancelled
    | WorkflowNotFound
    | WorkflowNotReady;

export type ProvideCourtsiteReschedulePaymentDetailsRequest = {
    email: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    paymentProvider?: InputMaybe<PaymentProvider>;
    phoneNumber: Scalars["String"]["input"];
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    workflowId: Scalars["ID"]["input"];
};

export type ProvideCourtsiteReschedulePaymentDetailsResponse =
    | CourtsiteRescheduleExpired
    | CourtsiteReschedulePaymentDetailsProvided
    | InvalidCourtsiteReschedulePaymentDetailsRequest
    | WorkflowCancelled
    | WorkflowNotFound
    | WorkflowNotReady;

export type PublicGameCount = {
    categoryId: Scalars["ID"]["output"];
    gameCount: Scalars["Int"]["output"];
    locationTenantIds: Array<Maybe<Scalars["ID"]["output"]>>;
};

export type PublicGamesFilter = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    dayOfGames?: InputMaybe<Scalars["Int"]["input"]>;
    locationTenantIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
    startsAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    startsBefore?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Query = {
    addOns: Array<AddOn>;
    approvedOrganisations?: Maybe<OrganisationConnection>;
    booking: Booking;
    bookings: BookingConnection;
    bookingsByIds: Array<Booking>;
    bookingsWithAddons?: Maybe<Array<Booking>>;
    capturePaymentStateById: CapturePaymentState;
    categories: Array<Category>;
    checkPromoCodeDiscount: PromoCodeDiscount;
    checkoutDiscount: CheckoutDiscount;
    confirmedBookingsWithAddons?: Maybe<Array<Booking>>;
    coreGatewayVersion: Scalars["String"]["output"];
    courtsiteCheckoutStateById: CourtsiteCheckoutState;
    courtsitePartners: Array<Organisation>;
    courtsiteRescheduleStateById: CourtsiteRescheduleState;
    courtsiteSearch: Array<Organisation>;
    currentCommands: Array<Command>;
    customer: Customer;
    customers: Array<Customer>;
    effectivePriceRuleset: PriceRuleset;
    fixedDurationOptionsStartingBetween: Array<ServiceFixedDurationOptions>;
    game: Game;
    groupIdEventsActivationStatus?: Maybe<Array<GroupIdEventsActivationStatus>>;
    invoice: Invoice;
    invoices: Array<Invoice>;
    invoicesByUids?: Maybe<Array<Invoice>>;
    kiplepaySettlementEnquiry?: Maybe<KiplepaySettlementDetailsResult>;
    kiplepaySettlementNotification?: Maybe<KiplepaySettlementNotification>;
    kiplepaySettlementNotifications?: Maybe<
        Array<Maybe<KiplepaySettlementNotification>>
    >;
    konsolCheckoutStateById: KonsolCheckoutState;
    latestSettlementConfigurationsChange: Array<
        Maybe<SettlementConfigurationChange>
    >;
    lhdnSubmissionByInvoiceId?: Maybe<LhdnSubmission>;
    lhdnSubmissionStatus?: Maybe<GetLhdnSubmissionStatus>;
    lights: Array<Light>;
    location: SearchLocation;
    locations: Array<SearchLocation>;
    metabaseDashboardUrl: Scalars["String"]["output"];
    myBooking: Booking;
    myBookingFilterCount: Array<BookingFilterCount>;
    myBookingStatistics: BookingStatistics;
    myBookings: BookingConnection;
    myBookingsByIds: Array<Booking>;
    myGameFilterCount: Array<GameFilterCount>;
    myGameStatistics: GameStatistics;
    myGames: GameConnection;
    myGroupedBookings: GroupedBookingsConnection;
    myInvoice: Invoice;
    myInvoiceStatistics: InvoiceStatistics;
    myInvoices?: Maybe<Array<Invoice>>;
    myInvoicesByIds?: Maybe<Array<Invoice>>;
    myInvoicesByRelatedEntityIds?: Maybe<Array<Invoice>>;
    myInvoicesPaged?: Maybe<InvoiceConnection>;
    myMemberServiceIds: Array<Scalars["String"]["output"]>;
    myOrganisations: Array<Organisation>;
    myProfile?: Maybe<UserProfile>;
    onlineServices: Array<Service>;
    onlineServicesByCategory: Array<Service>;
    organisation: Organisation;
    /** Not for public use */
    organisations: OrganisationConnection;
    organisationsWithoutPagination: Array<Organisation>;
    paidInvoices: Array<Invoice>;
    paymentMethods: Array<PaymentMethod>;
    paymentSession: PaymentSession;
    paymentSessionsByExternalIds?: Maybe<Array<PaymentSession>>;
    payments: Array<Payment>;
    promoCodeV2: PromoCodeV2;
    promoCodesV2: Array<PromoCodeV2>;
    promocodes?: Maybe<Array<PromoCode>>;
    psmGrpHealth: Scalars["String"]["output"];
    publicGameCount: Array<PublicGameCount>;
    publicGames: GameConnection;
    quotation: Quotation;
    quotations?: Maybe<Array<Quotation>>;
    resellerTenant: ResellerTenant;
    resellerTenants?: Maybe<Array<ResellerTenant>>;
    resourceTypes: Array<ResourceType>;
    resources: Array<Resource>;
    runningSettlementWatchers: WorkflowListPage;
    schedule: Schedule;
    schedules: Array<Schedule>;
    serviceAvailabilities?: Maybe<Array<ServiceAvailabilityResult>>;
    serviceAvailability: ServiceAvailabilityResult;
    serviceById?: Maybe<Service>;
    serviceTags: Array<ServiceTags>;
    services: Array<Service>;
    settlementConfiguration: SettlementConfiguration;
    settlementConfigurationChanges: SettlementConfigurationChangeConnection;
    settlementConfigurations: Array<Maybe<SettlementConfiguration>>;
    settlements: SettlementConnection;
    submitLHDNEInvoicesStateById: SubmitLhdneInvoicesState;
    tag: Tag;
    tags: Array<Tag>;
    tenantIdsFromPastBookings?: Maybe<Array<Maybe<Scalars["ID"]["output"]>>>;
    unavailableResourceSlotsBetween?: Maybe<Array<UnavailableResourceSlot>>;
    userProfileFromEmailPhoneNumber?: Maybe<UserProfile>;
    userProfileFromFirebaseId?: Maybe<UserProfile>;
    validateTaxpayerTin?: Maybe<ValidateTaxPayerTinResponse>;
    workflowApiVersion: Scalars["String"]["output"];
};

export type QueryAddOnsArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryApprovedOrganisationsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<ApprovedOrganisationsFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    sortOrder?: InputMaybe<OrganisationSortOrder>;
};

export type QueryBookingArgs = {
    bookingId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryBookingsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<BookingFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryBookingsByIdsArgs = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryBookingsWithAddonsArgs = {
    byStatus?: InputMaybe<BookingStatus>;
    startDateAfter: Scalars["DateTime"]["input"];
    startDateBefore: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryCapturePaymentStateByIdArgs = {
    workflowId: Scalars["ID"]["input"];
};

export type QueryCategoriesArgs = {
    filter?: InputMaybe<CategoryFilter>;
};

export type QueryCheckPromoCodeDiscountArgs = {
    items: Array<PromoCodeDiscountItem>;
    promoCodeCode: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryCheckoutDiscountArgs = {
    items: Array<CheckoutDiscountItem>;
    promocodeId: Scalars["ID"]["input"];
};

export type QueryConfirmedBookingsWithAddonsArgs = {
    startDateAfter: Scalars["DateTime"]["input"];
    startDateBefore: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryCourtsiteCheckoutStateByIdArgs = {
    checkoutId: Scalars["ID"]["input"];
};

export type QueryCourtsitePartnersArgs = {
    filter?: InputMaybe<CourtsitePartnersFilter>;
    sortOrder?: InputMaybe<OrganisationSortOrder>;
};

export type QueryCourtsiteRescheduleStateByIdArgs = {
    workflowId: Scalars["ID"]["input"];
};

export type QueryCourtsiteSearchArgs = {
    request: CourtsiteSearchRequest;
};

export type QueryCurrentCommandsArgs = {
    lightIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryCustomerArgs = {
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
};

export type QueryCustomersArgs = {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    filter?: InputMaybe<CustomerFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryEffectivePriceRulesetArgs = {
    at: Scalars["DateTime"]["input"];
    setFor: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryFixedDurationOptionsStartingBetweenArgs = {
    end: Scalars["DateTime"]["input"];
    serviceIds: Array<Scalars["ID"]["input"]>;
    start: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryGameArgs = {
    uid: Scalars["ID"]["input"];
};

export type QueryGroupIdEventsActivationStatusArgs = {
    groupIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryInvoiceArgs = {
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
};

export type QueryInvoicesArgs = {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    filter?: InputMaybe<InvoiceFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryInvoicesByUidsArgs = {
    invoiceIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryKiplepaySettlementEnquiryArgs = {
    page?: InputMaybe<Scalars["Int"]["input"]>;
    settlementId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryKiplepaySettlementNotificationArgs = {
    notificationId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryKiplepaySettlementNotificationsArgs = {
    after: Scalars["DateTime"]["input"];
};

export type QueryKonsolCheckoutStateByIdArgs = {
    checkoutId: Scalars["ID"]["input"];
};

export type QueryLatestSettlementConfigurationsChangeArgs = {
    reviewState?: InputMaybe<ReviewState>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryLhdnSubmissionByInvoiceIdArgs = {
    invoiceId: Scalars["ID"]["input"];
};

export type QueryLhdnSubmissionStatusArgs = {
    pageNo?: InputMaybe<Scalars["Int"]["input"]>;
    pageSize?: InputMaybe<Scalars["Int"]["input"]>;
    submissionId: Scalars["ID"]["input"];
};

export type QueryLightsArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryLocationArgs = {
    uid: Scalars["String"]["input"];
};

export type QueryLocationsArgs = {
    city?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryMetabaseDashboardUrlArgs = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    dashboardType: MetabaseDashboardType;
    end: Scalars["DateTime"]["input"];
    serviceIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
    start: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryMyBookingArgs = {
    bookingId: Scalars["ID"]["input"];
};

export type QueryMyBookingFilterCountArgs = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    startsAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryMyBookingsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<MyBookingsFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    order?: InputMaybe<SortOrderEnum>;
};

export type QueryMyBookingsByIdsArgs = {
    bookingIds: Array<Scalars["ID"]["input"]>;
};

export type QueryMyGameFilterCountArgs = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    startsAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryMyGamesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<GameFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    order?: InputMaybe<SortOrderEnum>;
};

export type QueryMyGroupedBookingsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<MyGroupedBookingsFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    order?: InputMaybe<SortOrderEnum>;
};

export type QueryMyInvoiceArgs = {
    invoiceId: Scalars["ID"]["input"];
};

export type QueryMyInvoiceStatisticsArgs = {
    filter?: InputMaybe<MyInvoiceStatisticFilter>;
};

export type QueryMyInvoicesArgs = {
    filter?: InputMaybe<MyInvoicesFilter>;
};

export type QueryMyInvoicesByIdsArgs = {
    invoiceIds: Array<Scalars["ID"]["input"]>;
};

export type QueryMyInvoicesByRelatedEntityIdsArgs = {
    relatedEntityIds: Array<Scalars["ID"]["input"]>;
};

export type QueryMyInvoicesPagedArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<MyInvoicesPagedFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    order?: InputMaybe<SortOrderEnum>;
};

export type QueryMyMemberServiceIdsArgs = {
    onDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryOnlineServicesArgs = {
    filter?: InputMaybe<ServiceFilter>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryOnlineServicesByCategoryArgs = {
    categoryId: Scalars["ID"]["input"];
};

export type QueryOrganisationArgs = {
    uid: Scalars["ID"]["input"];
};

export type QueryOrganisationsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryOrganisationsWithoutPaginationArgs = {
    sortOrder?: InputMaybe<OrganisationSortOrder>;
};

export type QueryPaidInvoicesArgs = {
    excludeCancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
    isCourtsiteOwned?: InputMaybe<Scalars["Boolean"]["input"]>;
    paidBetween: DateRangeFilter;
    tenantId: Scalars["ID"]["input"];
};

export type QueryPaymentMethodsArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryPaymentSessionArgs = {
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
};

export type QueryPaymentSessionsByExternalIdsArgs = {
    externalIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryPaymentsArgs = {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    filter?: InputMaybe<PaymentFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryPromoCodeV2Args = {
    promoCodeId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryPromoCodesV2Args = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryPromocodesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<PromoCodeFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryPublicGameCountArgs = {
    categoryId?: InputMaybe<Scalars["ID"]["input"]>;
    startsAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryPublicGamesArgs = {
    filter?: InputMaybe<PublicGamesFilter>;
    page?: InputMaybe<PageInput>;
};

export type QueryQuotationArgs = {
    end: Scalars["DateTime"]["input"];
    setFor: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryQuotationsArgs = {
    request: Array<QuotationRequest>;
};

export type QueryResellerTenantArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryResourceTypesArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryResourcesArgs = {
    filter?: InputMaybe<ResourceFilter>;
    tenantId: Scalars["ID"]["input"];
};

export type QueryRunningSettlementWatchersArgs = {
    nextPageToken?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryScheduleArgs = {
    scheduleId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QuerySchedulesArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryServiceAvailabilitiesArgs = {
    request: Array<ServiceAvailabilityRequest>;
};

export type QueryServiceAvailabilityArgs = {
    request: ServiceAvailabilityRequest;
};

export type QueryServiceByIdArgs = {
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryServiceTagsArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryServicesArgs = {
    filter?: InputMaybe<ServiceFilter>;
    tenantId: Scalars["ID"]["input"];
};

export type QuerySettlementConfigurationArgs = {
    configurationId: Scalars["ID"]["input"];
};

export type QuerySettlementConfigurationChangesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    reviewState?: InputMaybe<ReviewState>;
    sortBy?: InputMaybe<ConfigurationChangeSortOrderEnum>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QuerySettlementsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filters?: InputMaybe<SettlementsFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    sortBy?: InputMaybe<SettlementSortOrderEnum>;
    tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QuerySubmitLhdneInvoicesStateByIdArgs = {
    workflowId: Scalars["ID"]["input"];
};

export type QueryTagArgs = {
    tagId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type QueryTagsArgs = {
    tenantId: Scalars["ID"]["input"];
};

export type QueryTenantIdsFromPastBookingsArgs = {
    filter?: InputMaybe<ServiceModeFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryUnavailableResourceSlotsBetweenArgs = {
    categoryId: Scalars["ID"]["input"];
    dtRange: DateRangeFilter;
    tenantId: Scalars["ID"]["input"];
};

export type QueryUserProfileFromEmailPhoneNumberArgs = {
    query: Scalars["String"]["input"];
};

export type QueryUserProfileFromFirebaseIdArgs = {
    firebaseId: Scalars["ID"]["input"];
};

export type QueryValidateTaxpayerTinArgs = {
    idType: IdType;
    idValue: Scalars["String"]["input"];
    tin: Scalars["String"]["input"];
};

export type Quotation = {
    deposit: Scalars["Int"]["output"];
    price: Scalars["Int"]["output"];
    quotationFor: Scalars["ID"]["output"];
    remainder: Scalars["Int"]["output"];
    satisfiesMinimumDuration: Scalars["Boolean"]["output"];
    timeFullyAccounted: Scalars["Boolean"]["output"];
};

export type QuotationRequest = {
    end: Scalars["DateTime"]["input"];
    setFor: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type RazorpaySessionInitiated = {
    externalId: Scalars["ID"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    responseUrl: Scalars["String"]["output"];
};

export type RecoverPaymentSessionState = {
    bookingIds: Array<Scalars["ID"]["output"]>;
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    failureReason: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    invoiceId: Scalars["ID"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userEmail: Scalars["String"]["output"];
    userId: Scalars["ID"]["output"];
};

export type RecurringBookingsRescheduled = {
    state: RescheduleRecurringBookingsState;
};

/** An enumeration. */
export type RecurringSeriesModification = "MAINTAIN" | "NEW" | "REMOVE";

export type RedeemPromoCodeRequest = {
    items: Array<PromoCodeDiscountItem>;
    promoCodeCode: Scalars["String"]["input"];
    redeemerId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type RedeemPromoCodeResponse =
    | AccessError
    | NotFoundError
    | PromoCodeExpired
    | PromoCodeInactive
    | PromoCodeRedeemed
    | PromoCodeRedemptionLimitExceeded;

export type RejectedDocument = {
    error: Error;
    invoiceCode: Scalars["String"]["output"];
};

export type RemoveBookingGuestRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
    userId: Scalars["ID"]["input"];
};

export type RemoveBookingGuestResponse =
    | AccessError
    | BookingGuestRemoved
    | NotFoundError;

export type RenameCategoryRequest = {
    categoryId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
};

export type RenameCategoryResponse =
    | AccessError
    | CategoryRenamed
    | NotFoundError;

export type RenameResourceRequest = {
    name: Scalars["String"]["input"];
    resourceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type RenameResourceResponse =
    | AccessError
    | NotFoundError
    | ResourceAlreadyArchived
    | ResourceRenamed;

export type RenameServiceRequest = {
    newName: Scalars["String"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type RenameServiceResponse =
    | AccessError
    | NotFoundError
    | ServiceRenamed;

export type RescheduleBookingRequest = {
    bookingId: Scalars["ID"]["input"];
    endDt: Scalars["DateTime"]["input"];
    metadata: Scalars["JSONString"]["input"];
    selectedResourceIds: Array<Scalars["ID"]["input"]>;
    serviceId: Scalars["ID"]["input"];
    startDt: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type RescheduleBookingResponse =
    | AccessError
    | BookingRescheduled
    | BookingUnavailable
    | NotFoundError;

export type RescheduleBookingWorkflowBookingNotReschedulable = {
    message: Scalars["String"]["output"];
};

export type RescheduleBookingWorkflowBookingUnavailable = {
    message: Scalars["String"]["output"];
};

export type RescheduleBookingWorkflowCompleted = {
    state: RescheduleBookingWorkflowState;
};

export type RescheduleBookingWorkflowResourceChangeInput = {
    newResourceId: Scalars["String"]["input"];
    targetResourceId: Scalars["String"]["input"];
};

export type RescheduleBookingWorkflowState = {
    bookingId: Scalars["ID"]["output"];
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type RescheduleRecurringBookingGuestModificationInput = {
    email?: InputMaybe<Scalars["String"]["input"]>;
    guestId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type RescheduleRecurringBookingModificationInput = {
    bookingId: Scalars["ID"]["input"];
    endDt: Scalars["Time"]["input"];
    guestChanges: Array<RescheduleRecurringBookingGuestModificationInput>;
    metadata: Scalars["JSONString"]["input"];
    resourceChanges: Array<RescheduleBookingWorkflowResourceChangeInput>;
    startDt: Scalars["Time"]["input"];
};

export type RescheduleRecurringBookingsModificationsInput = {
    bookingModifications: Array<RescheduleRecurringBookingModificationInput>;
    recurringSeriesModification?: InputMaybe<RescheduleRecurringBookingsSeriesModifications>;
};

export type RescheduleRecurringBookingsSeriesModifications =
    | "MAINTAIN"
    | "NEW"
    | "REMOVE";

export type RescheduleRecurringBookingsState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    rescheduleError?: Maybe<Scalars["String"]["output"]>;
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    userId: Scalars["ID"]["output"];
};

export type ResellerTenant = {
    activeAfter?: Maybe<Scalars["DateTime"]["output"]>;
    isActive: Scalars["Boolean"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type Resource = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    bookings: Array<Booking>;
    created: Scalars["DateTime"]["output"];
    linkedBookings: Array<Booking>;
    linkedResources: Array<Resource>;
    links: Array<Maybe<ResourceLink>>;
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    resourceType: ResourceType;
    resourceTypeId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ResourceBookingsArgs = {
    endDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    includeBeforeStartDt?: InputMaybe<Scalars["Boolean"]["input"]>;
    startDt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ResourceLinkedBookingsArgs = {
    endDt?: InputMaybe<Scalars["DateTime"]["input"]>;
    includeBeforeStartDt?: InputMaybe<Scalars["Boolean"]["input"]>;
    startDt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ResourceAlreadyArchived = {
    reason: Scalars["String"]["output"];
};

export type ResourceArchived = {
    resourceId: Scalars["ID"]["output"];
};

export type ResourceBookingSummary = {
    created: Scalars["DateTime"]["output"];
    resourceId: Scalars["ID"]["output"];
    resourceName: Scalars["String"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ResourceCreated = {
    resourceId: Scalars["ID"]["output"];
};

export type ResourceFilter = {
    byResourceTypeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ResourceLink = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    resourceIds: Array<Maybe<Scalars["ID"]["output"]>>;
    resources: Array<Maybe<Resource>>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ResourceLinksAlreadyArchived = {
    message: Scalars["String"]["output"];
};

export type ResourceLinksAlreadyExist = {
    message: Scalars["String"]["output"];
};

export type ResourceLinksArchived = {
    resourceLinkIds: Array<Maybe<Scalars["ID"]["output"]>>;
};

export type ResourceLinksCreated = {
    resourceLinks?: Maybe<Array<Maybe<ResourceLink>>>;
};

export type ResourceRenamed = {
    name: Scalars["String"]["output"];
    resourceId: Scalars["ID"]["output"];
};

export type ResourceType = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    resources: Array<Resource>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ResourceTypeArchived = {
    resourceTypeId: Scalars["ID"]["output"];
};

export type ResourceTypeChanged = {
    resourceId: Scalars["ID"]["output"];
    resourceTypeId: Scalars["ID"]["output"];
};

export type ResourceTypeCreated = {
    resourceTypeId: Scalars["ID"]["output"];
};

export type ResourceTypesMetadataInput = {
    metadata: Scalars["JSONString"]["input"];
    resourceTypeId: Scalars["ID"]["input"];
};

export type ResourceTypesMetadataUpdated = {
    resourceTypes: Array<Maybe<ResourceType>>;
};

export type ReviewSettlementConfigurationWithApprovalRequest = {
    configurationChangeId: Scalars["ID"]["input"];
    reviewMessage?: InputMaybe<Scalars["String"]["input"]>;
    reviewState: ReviewState;
};

export type ReviewSettlementConfigurationWithApprovalResponse =
    | AccessError
    | NotFoundError
    | SettlementConfigurationWithApprovalReviewed;

/** An enumeration. */
export type ReviewState = "APPROVED" | "PENDING" | "REJECTED";

export type ReviveBookingFailure = {
    bookingId: Scalars["ID"]["output"];
    reason: ServiceUnavailabilityReason;
};

export type ReviveBookingsFailure = {
    failures: Array<ReviveBookingFailure>;
};

export type ReviveBookingsRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type ReviveBookingsResponse =
    | AccessError
    | BookingsRevived
    | NotFoundError
    | ReviveBookingsFailure;

export type Rule = {
    created: Scalars["DateTime"]["output"];
    uid: Scalars["ID"]["output"];
};

export type RuleInput = {
    dates?: InputMaybe<Array<Scalars["Date"]["input"]>>;
    effectiveFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
    effectiveTo?: InputMaybe<Scalars["DateTime"]["input"]>;
    maxAdvancedBookingDays?: InputMaybe<Scalars["Int"]["input"]>;
    minAdvancedBookingMinutes?: InputMaybe<Scalars["Int"]["input"]>;
    ruleType: RuleType;
};

export type RuleType =
    | "ALLOW_DATES"
    | "ALLOW_FROM_DATETIME"
    | "ALLOW_MAXIMUM_ADVANCED_BOOKING_TIME"
    | "ALLOW_MINIMUM_ADVANCED_BOOKING_TIME"
    | "ALLOW_TO_DATETIME";

export type SPaySessionInitiated = {
    backendUrl: Scalars["String"]["output"];
    encryptedPayload: Scalars["String"]["output"];
    externalId: Scalars["ID"]["output"];
    paymentSessionCreated: Scalars["DateTime"]["output"];
    paymentSessionId: Scalars["ID"]["output"];
    qrCode: Scalars["String"]["output"];
    responseUrl: Scalars["String"]["output"];
};

export type Schedule = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    daytimeSlots: Array<DayTimeSlot>;
    metadata: Scalars["JSONString"]["output"];
    name: Scalars["String"]["output"];
    rules: Array<Rule>;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ScheduleArchived = {
    scheduleId: Scalars["ID"]["output"];
};

export type ScheduleCreated = {
    schedule: Schedule;
};

export type ScheduleUpdated = {
    schedule: Schedule;
};

export type SearchLocation = {
    city?: Maybe<Scalars["String"]["output"]>;
    coordinates: Coordinates;
    created: Scalars["DateTime"]["output"];
    name: Scalars["String"]["output"];
    uid: Scalars["ID"]["output"];
};

export type Service = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    bookingWindow?: Maybe<Scalars["Int"]["output"]>;
    category?: Maybe<Category>;
    created: Scalars["DateTime"]["output"];
    customDurationOptions?: Maybe<Array<Scalars["Int"]["output"]>>;
    disallowBookingGap: Scalars["Boolean"]["output"];
    endTime?: Maybe<Scalars["Time"]["output"]>;
    metadata: Scalars["JSONString"]["output"];
    minBookingWindowMinutes?: Maybe<Scalars["Int"]["output"]>;
    minRescheduleNotice?: Maybe<Scalars["Int"]["output"]>;
    name: Scalars["String"]["output"];
    options: Array<DurationOption>;
    resources: Array<ServiceResource>;
    serviceCategory?: Maybe<ServiceCategory>;
    serviceMode: Scalars["String"]["output"];
    startTime?: Maybe<Scalars["Time"]["output"]>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
    visibility: ServiceVisibility;
};

export type ServiceAlreadyArchived = {
    message: Scalars["String"]["output"];
};

export type ServiceArchived = {
    service: Service;
};

export type ServiceAvailabilityRequest = {
    endDt: Scalars["DateTime"]["input"];
    resourceId?: InputMaybe<Scalars["ID"]["input"]>;
    serviceId: Scalars["ID"]["input"];
    startDt: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type ServiceAvailabilityResult = {
    availableSelectableResourceIds: Array<Scalars["ID"]["output"]>;
    availableSelectableResources: Array<Resource>;
    availableUnselectableResourceIds: Array<Scalars["ID"]["output"]>;
    availableUnselectableResources: Array<Resource>;
    isAvailable: Scalars["Boolean"]["output"];
    reason: ServiceUnavailabilityReason;
    serviceId: Scalars["ID"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type ServiceBookingLimitsSet = {
    serviceId: Scalars["ID"]["output"];
};

export type ServiceBookingSummary = {
    categoryId: Scalars["ID"]["output"];
    categoryName: Scalars["String"]["output"];
    created: Scalars["DateTime"]["output"];
    serviceMode: Scalars["String"]["output"];
    uid: Scalars["ID"]["output"];
};

export type ServiceCategory = {
    categoryId: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ServiceCategoryAssigned = {
    categoryId: Scalars["ID"]["output"];
    serviceId: Scalars["ID"]["output"];
};

export type ServiceCreated = {
    serviceId: Scalars["ID"]["output"];
};

export type ServiceDurationOptionsSet = {
    service: Service;
};

export type ServiceFilter = {
    byCategoryId?: InputMaybe<Scalars["ID"]["input"]>;
    includeArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceFixedDurationOptions = {
    options: Array<DatetimeRange>;
    serviceId: Scalars["ID"]["output"];
};

export type ServiceMetadataUpdated = {
    metadata: Scalars["JSONString"]["output"];
    serviceId: Scalars["ID"]["output"];
};

export type ServiceModeFilter = {
    includeDailyServices?: InputMaybe<Scalars["Boolean"]["input"]>;
    includeHourlyServices?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ServiceRenamed = {
    serviceId: Scalars["ID"]["output"];
};

export type ServiceResource = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    optionKey: Scalars["String"]["output"];
    resource: Resource;
    resourceId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
    updated?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ServiceResourceAdded = {
    resourceId: Scalars["ID"]["output"];
    serviceId: Scalars["ID"]["output"];
};

export type ServiceResourceArchived = {
    resourceId: Scalars["ID"]["output"];
    serviceId: Scalars["ID"]["output"];
};

export type ServiceResourceInput = {
    optionKey: Scalars["String"]["input"];
    resourceId: Scalars["ID"]["input"];
};

export type ServiceTags = {
    created: Scalars["DateTime"]["output"];
    serviceId: Scalars["ID"]["output"];
    tagIds: Array<Scalars["ID"]["output"]>;
    tags: Array<Tag>;
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
};

export type ServiceTagsSet = {
    serviceTag: ServiceTags;
};

/** An enumeration. */
export type ServiceUnavailabilityReason =
    | "BOOKING_NOT_FOUND"
    | "BOOKING_WINDOW_CONFLICT"
    | "DURATION_OPTION_CONFLICT"
    | "INVALID_DATE_RANGE"
    | "NONE"
    | "NO_SERVICE_ID_PROVIDED"
    | "NO_SERVICE_SCHEDULE"
    | "RESCHEDULE_CONFLICT"
    | "RESCHEDULE_ISSUE"
    | "SELECTABLE_RESOURCES_UNAVAILABLE"
    | "SERVICE_ARCHIVED"
    | "SERVICE_NOT_FOUND"
    | "SERVICE_UNAVAILABLE"
    | "UNSELECTABLE_RESOURCES";

/** An enumeration. */
export type ServiceVisibility = "OFFLINE" | "ONLINE";

export type ServiceVisibilityUpdated = {
    serviceId: Scalars["ID"]["output"];
    visibility: Scalars["String"]["output"];
};

export type SetAddOnIsInStockRequest = {
    addOnId: Scalars["ID"]["input"];
    isInStock: Scalars["Boolean"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type SetAddOnIsInStockResponse =
    | AccessError
    | AddOnAlreadyArchived
    | AddOnIsInStockSet
    | NotFoundError;

export type SetDailyServiceTimesRequest = {
    endTime: Scalars["Time"]["input"];
    serviceId: Scalars["ID"]["input"];
    startTime: Scalars["Time"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type SetDailyServiceTimesResponse =
    | AccessError
    | DailyServiceTimesSet
    | InvalidServiceModeError
    | NotFoundError;

export type SetLhdnSubmissionStatusRequest = {
    lhdnSubmissionId: Scalars["ID"]["input"];
    status: LhdnSubmissionStatus;
};

export type SetLhdnSubmissionStatusResponse =
    | AccessError
    | LhdnSubmissionStatusSet
    | NotFoundError;

export type SetLightDurationRequest = {
    cooldown: Scalars["Int"]["input"];
    lightId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
    warmup: Scalars["Int"]["input"];
};

export type SetLightDurationResponse =
    | AccessError
    | LightDurationSet
    | NotFoundError;

export type SetMandatoryUserProfileRequest = {
    dateOfBirth: Scalars["Date"]["input"];
    email: Scalars["EmailAddress"]["input"];
    gender: Gender;
    name: Scalars["String"]["input"];
    otherPreferredSports?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber: Scalars["PhoneNumber"]["input"];
    postcode: Scalars["String"]["input"];
    preferredSports: Array<Scalars["ID"]["input"]>;
    sportsFrequency: Scalars["Int"]["input"];
};

export type SetMandatoryUserProfileResponse =
    | AccessError
    | MandatoryUserProfileSet
    | NotFoundError;

export type SetMemberCapabilitiesRequest = {
    capabilities: Array<Scalars["String"]["input"]>;
    memberId: Scalars["ID"]["input"];
    organisationId: Scalars["ID"]["input"];
};

export type SetMemberCapabilitiesResponse =
    | AccessError
    | CannotModifySelfError
    | MemberAlreadyArchivedError
    | MemberCapabilitiesSet
    | NotFoundError;

export type SetMemberRoleRequest = {
    memberId: Scalars["ID"]["input"];
    organisationId: Scalars["ID"]["input"];
    role: MemberRole;
};

export type SetOrganisationIsCourtsitePartnerRequest = {
    isCourtsitePartner: Scalars["Boolean"]["input"];
    organisationId: Scalars["ID"]["input"];
};

export type SetOrganisationIsCourtsitePartnerResponse =
    | AccessError
    | NotFoundError
    | OrganisationIsCourtsitePartnerSet;

export type SetOrganisationWeightagesRequest = {
    organisationInput?: InputMaybe<Array<OrganisationWeightageInput>>;
};

export type SetOrganisationWeightagesResponse =
    | AccessError
    | NotFoundError
    | OrganisationWeightagesSet;

export type SetPromoCodeCriteriaRequest = {
    code: Scalars["ID"]["input"];
    itemTypeIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
    tenantIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
};

export type SetPromoCodeCriteriaResponse =
    | NotFoundError
    | SetPromoCodeCriteriaSet;

export type SetPromoCodeCriteriaSet = {
    itemTypeIds: Array<Maybe<Scalars["ID"]["output"]>>;
    promocodeCode: Scalars["ID"]["output"];
    tenantIds: Array<Maybe<Scalars["ID"]["output"]>>;
};

export type SetPromoCodeDiscountRequest = {
    code: Scalars["ID"]["input"];
    discount: Scalars["Int"]["input"];
    type?: InputMaybe<DiscountType>;
};

export type SetPromoCodeDiscountResponse = NotFoundError | PromoCodeDiscountSet;

export type SetServiceBookingLimitsRequest = {
    bookingWindowByWeeks?: InputMaybe<Scalars["Int"]["input"]>;
    disallowBookingGap?: InputMaybe<Scalars["Boolean"]["input"]>;
    minBookingWindowByMinutes?: InputMaybe<Scalars["Int"]["input"]>;
    minRescheduleNotice?: InputMaybe<Scalars["Int"]["input"]>;
    selectorRange?: InputMaybe<BookingSelectorRangeInput>;
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type SetServiceBookingLimitsResponse =
    | AccessError
    | NotFoundError
    | ServiceBookingLimitsSet;

export type SetServiceDurationOptionsRequest = {
    options: Array<Scalars["Int"]["input"]>;
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type SetServiceDurationOptionsResponse =
    | AccessError
    | NotFoundError
    | ServiceDurationOptionsSet;

export type SetServiceTagsRequest = {
    serviceId: Scalars["ID"]["input"];
    tagIds: Array<InputMaybe<Scalars["ID"]["input"]>>;
    tenantId: Scalars["ID"]["input"];
};

export type SetServiceTagsResponse = AccessError | ServiceTagsSet;

export type SetUserProfileManualRequest = {
    dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    firebaseId: Scalars["ID"]["input"];
    gender?: InputMaybe<Gender>;
    name: Scalars["String"]["input"];
    otherPreferredSports?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    postcode?: InputMaybe<Scalars["String"]["input"]>;
    preferredSports?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    sportsFrequency?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SetUserProfileManualResponse =
    | AccessError
    | NotFoundError
    | UserProfileSet;

export type SetUserProfileRequest = {
    dateOfBirth?: InputMaybe<Scalars["Date"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    gender?: InputMaybe<Gender>;
    name: Scalars["String"]["input"];
    otherPreferredSports?: InputMaybe<Scalars["String"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    postcode?: InputMaybe<Scalars["String"]["input"]>;
    preferredSports?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    sportsFrequency?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SetUserProfileResponse =
    | AccessError
    | InvalidValue
    | UserProfileSet;

export type Settlement = {
    amount: Scalars["Int"]["output"];
    created: Scalars["DateTime"]["output"];
    endDate: Scalars["DateTime"]["output"];
    isAutoGen: Scalars["Boolean"]["output"];
    isResellerSettlement: Scalars["Boolean"]["output"];
    metadata: Scalars["JSONString"]["output"];
    reportUrl: Scalars["String"]["output"];
    startDate: Scalars["DateTime"]["output"];
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type SettlementArchived = {
    settlement: Settlement;
};

export type SettlementConfiguration = {
    commissionPercentage: Scalars["Float"]["output"];
    created: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    monthlyFee: Scalars["Int"]["output"];
    settlementRange: SettlementRangeConfiguration;
    slug: Scalars["String"]["output"];
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type SettlementConfigurationChange = {
    changes: Scalars["JSONString"]["output"];
    configurationId: Scalars["ID"]["output"];
    created: Scalars["DateTime"]["output"];
    createdByEmail: Scalars["String"]["output"];
    createdByUserId: Scalars["ID"]["output"];
    createdOn: Scalars["DateTime"]["output"];
    reviewMessage?: Maybe<Scalars["String"]["output"]>;
    reviewState?: Maybe<ReviewState>;
    reviewedByEmail?: Maybe<Scalars["String"]["output"]>;
    reviewedByUserId?: Maybe<Scalars["ID"]["output"]>;
    reviewedOn?: Maybe<Scalars["DateTime"]["output"]>;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type SettlementConfigurationChangeConnection = {
    edges: Array<SettlementConfigurationChangeEdge>;
    pageInfo: PageInfo;
};

export type SettlementConfigurationChangeEdge = {
    cursor: Scalars["String"]["output"];
    node: SettlementConfigurationChange;
};

export type SettlementConfigurationCreated = {
    configuration: SettlementConfiguration;
};

export type SettlementConfigurationEdited = {
    configuration: SettlementConfiguration;
};

export type SettlementConfigurationWithApprovalCreated = {
    configurationChange: SettlementConfigurationChange;
};

export type SettlementConfigurationWithApprovalEdited = {
    configurationChange: SettlementConfigurationChange;
};

export type SettlementConfigurationWithApprovalReviewed = {
    configurationChange: SettlementConfigurationChange;
};

export type SettlementConnection = {
    edges: Array<SettlementEdge>;
    pageInfo: PageInfo;
};

export type SettlementCreated = {
    settlement: Settlement;
};

export type SettlementDownloadLinkGenerated = {
    link: Scalars["String"]["output"];
};

export type SettlementEdge = {
    cursor: Scalars["String"]["output"];
    node: Settlement;
};

export type SettlementRangeByDayOfMonth = SettlementRangeConfiguration & {
    targetDays: Array<Scalars["Int"]["output"]>;
};

export type SettlementRangeByDayOfWeek = SettlementRangeConfiguration & {
    targetDays: Array<Scalars["Int"]["output"]>;
};

export type SettlementRangeConfiguration = {
    targetDays: Array<Scalars["Int"]["output"]>;
};

export type SettlementRangeConfigurationInput = {
    settlementRangeType: SettlementRangeConfigurationType;
    targetDays: Array<Scalars["Int"]["input"]>;
};

export type SettlementRangeConfigurationType = "DAY_OF_MONTH" | "DAY_OF_WEEK";

export type SettlementSortOrderEnum = "BY_SETTLEMENT_END_DATE_DESC";

export type SettlementWatcherRunning = {
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
};

export type SettlementsFilter = {
    byEndDate?: InputMaybe<DateRangeFilter>;
};

export type SortOrderEnum = "ASC" | "DESC";

export type StartCancelBookingWorkflowRequest = {
    bookingId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartCancelBookingWorkflowResponse =
    | CancelBookingWorkflowBookingNotCancellable
    | CancelBookingWorkflowCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartCancelBookingsRequest = {
    bookingIds: Array<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type StartCancelBookingsResponse =
    | CancelBookingsWorkflowBookingsNotCancellable
    | CancelBookingsWorkflowCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartCancelPaymentWorkflowRequest = {
    invoiceId: Scalars["ID"]["input"];
    paymentId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartCancelPaymentWorkflowResponse =
    | CancelPaymentWorkflowCompleted
    | CancelPaymentWorkflowPaymentNotCancellable
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartCourtsiteCheckoutRequest = {
    addOns?: InputMaybe<Array<CourtsiteCheckoutAddOnInput>>;
    guest: CourtsiteCheckoutGuestInput;
    metadata: Scalars["JSONString"]["input"];
    promocode?: InputMaybe<Scalars["String"]["input"]>;
    slots: Array<CourtsiteCheckoutSlotInput>;
    tenantId: Scalars["ID"]["input"];
};

export type StartCourtsiteCheckoutResponse =
    | CourtsiteCheckoutBookingsUnavailable
    | CourtsiteCheckoutStarted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartCourtsiteGameWorkflowLocationInput = {
    tenantId: Scalars["String"]["input"];
};

export type StartCourtsiteGameWorkflowPlayerInput = {
    displayPicture?: InputMaybe<Scalars["String"]["input"]>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type StartCourtsiteGameWorkflowRequest = {
    categoryId: Scalars["ID"]["input"];
    description: Scalars["String"]["input"];
    endDt: Scalars["Time"]["input"];
    location: StartCourtsiteGameWorkflowLocationInput;
    maxPlayers: Scalars["Int"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    organizerDetails: StartCourtsiteGameWorkflowPlayerInput;
    private?: InputMaybe<Scalars["Boolean"]["input"]>;
    reservedPlayers?: InputMaybe<Scalars["Int"]["input"]>;
    startDt: Scalars["Time"]["input"];
};

export type StartCourtsiteGameWorkflowResponse =
    | CourtsiteGameWorkflowStarted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartCourtsiteRescheduleRequest = {
    bookingId: Scalars["ID"]["input"];
    newSlot: CourtsiteBookingSlotInput;
    tenantId: Scalars["ID"]["input"];
};

export type StartCourtsiteRescheduleResponse =
    | CourtsiteRescheduleBookingsConflictingWorkflow
    | CourtsiteRescheduleBookingsUnavailable
    | CourtsiteRescheduleStarted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartGeneratePsmGrpCashSaleTxRequest = {
    kiplePaySettlementNotificationId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartGeneratePsmGrpCashSaleTxResponse =
    | GeneratePsmGrpCashSaleTxCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartKonsolCancelInvoiceRequest = {
    invoiceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartKonsolCancelInvoiceResponse =
    | KonsolCancelInvoiceCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartKonsolCheckoutRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["String"]["input"]>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    isOnlinePayment: Scalars["Boolean"]["input"];
    paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type StartKonsolCheckoutResponse =
    | KonsolCheckoutInvalidBooking
    | KonsolCheckoutStarted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartKonsolCreateInvoiceRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    invoiceeEmail?: InputMaybe<Scalars["String"]["input"]>;
    invoiceeName: Scalars["String"]["input"];
    invoiceePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type StartKonsolCreateInvoiceResponse =
    | KonsolCreateInvoiceCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartKonsolPayInvoiceRequest = {
    invoiceId: Scalars["ID"]["input"];
    isOnlinePayment: Scalars["Boolean"]["input"];
    paymentMethodId: Scalars["ID"]["input"];
    redirectUrl: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartKonsolPayInvoiceResponse =
    | KonsolPayInvoiceCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartRecoverPaymentSessionRequest = {
    paymentSessionId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartRecoverPaymentSessionResponse =
    | PaymentSessionRecovered
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartRescheduleBookingWorkflowRequest = {
    bookingId: Scalars["ID"]["input"];
    endDt: Scalars["Time"]["input"];
    resourceChanges: Array<RescheduleBookingWorkflowResourceChangeInput>;
    startDt: Scalars["Time"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type StartRescheduleBookingWorkflowResponse =
    | RescheduleBookingWorkflowBookingNotReschedulable
    | RescheduleBookingWorkflowBookingUnavailable
    | RescheduleBookingWorkflowCompleted
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartRescheduleRecurringBookingsRequest = {
    bookingGroupModifications: Array<RescheduleRecurringBookingsModificationsInput>;
    tenantId: Scalars["String"]["input"];
};

export type StartRescheduleRecurringBookingsResponse =
    | RecurringBookingsRescheduled
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartSettlementWatcherRequest = {
    startDt?: InputMaybe<Scalars["Time"]["input"]>;
    triggerHours?: InputMaybe<Array<Scalars["Int"]["input"]>>;
    watchDuration?: InputMaybe<Scalars["Int"]["input"]>;
    willContinueOnEnd: Scalars["Boolean"]["input"];
};

export type StartSettlementWatcherResponse =
    | SettlementWatcherRunning
    | WorkflowCancelled
    | WorkflowNotReady;

export type StartSubmitLhdneInvoicesRequest = {
    addressLine1: Scalars["String"]["input"];
    addressLine2?: InputMaybe<Scalars["String"]["input"]>;
    addressLine3?: InputMaybe<Scalars["String"]["input"]>;
    businessOrigin?: InputMaybe<Scalars["String"]["input"]>;
    city: Scalars["String"]["input"];
    country: Scalars["String"]["input"];
    email: Scalars["String"]["input"];
    idType: Scalars["String"]["input"];
    idValue: Scalars["String"]["input"];
    invoiceIds: Array<Scalars["ID"]["input"]>;
    invoiceType: Scalars["String"]["input"];
    mobileNumber: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    postcode: Scalars["String"]["input"];
    sstNumber?: InputMaybe<Scalars["String"]["input"]>;
    state: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
    tin: Scalars["String"]["input"];
};

export type StartSubmitLhdneInvoicesResponse =
    | SubmitLhdneInvoicesStarted
    | WorkflowCancelled
    | WorkflowNotReady;

export type SubmitLhdneInvoicesStarted = {
    state: SubmitLhdneInvoicesState;
};

export type SubmitLhdneInvoicesState = {
    cancelled?: Maybe<Scalars["Time"]["output"]>;
    completed?: Maybe<Scalars["Time"]["output"]>;
    created: Scalars["Time"]["output"];
    id: Scalars["ID"]["output"];
    runId: Scalars["ID"]["output"];
    state: Scalars["String"]["output"];
};

export type SubmitLhdnInvoiceRequest = {
    invoiceDetails: Array<InvoiceDetail>;
    invoiceId: Scalars["ID"]["input"];
    partyDetails: PartyDetails;
};

export type SubmitLhdnInvoiceResponse =
    | AccessError
    | InternalError
    | LhdnInvoiceSubmitted;

export type SyncEventsRequest = {
    events: Array<EventInput>;
    tenantId: Scalars["ID"]["input"];
};

export type SyncEventsResponse = AccessError | EventsSynced;

export type SyncUserProfileToFirebaseResponse =
    | AccessError
    | NotFoundError
    | UserProfileSyncedToFirebase;

export type Tag = {
    archived?: Maybe<Scalars["DateTime"]["output"]>;
    created: Scalars["DateTime"]["output"];
    name: Scalars["String"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type TagArchived = {
    tag: Tag;
};

export type TagCreated = {
    tag: Tag;
};

export type TagEdited = {
    tag: Tag;
};

export type TaxPayerTinValidated = {
    idType: IdType;
    idValue: Scalars["String"]["output"];
    tin: Scalars["String"]["output"];
};

export type Trigger = {
    created: Scalars["DateTime"]["output"];
    light?: Maybe<Light>;
    lightId: Scalars["ID"]["output"];
    metadata: Scalars["JSONString"]["output"];
    resourceId: Scalars["ID"]["output"];
    tenant: Organisation;
    tenantId: Scalars["ID"]["output"];
    uid: Scalars["ID"]["output"];
};

export type TriggerArchived = {
    trigger: Trigger;
};

export type TriggerCreated = {
    trigger: Trigger;
};

export type UnableToAddServiceResource = {
    message: Scalars["String"]["output"];
};

export type UnableToArchiveResource = {
    reason: Scalars["String"]["output"];
};

export type UnableToArchiveResourceType = {
    reason: Scalars["String"]["output"];
};

export type UnableToChangeResourceType = {
    reason: Scalars["String"]["output"];
};

export type UnapproveOrganisationRequest = {
    organisationId: Scalars["ID"]["input"];
};

export type UnapproveOrganisationResponse =
    | AccessError
    | NotFoundError
    | OrganisationUnapproved;

export type UnavailableBookingSlot = {
    endDt: Scalars["DateTime"]["output"];
    metadata: Scalars["JSONString"]["output"];
    reason: ServiceUnavailabilityReason;
    selectedResourceIds: Array<Scalars["ID"]["output"]>;
    serviceId?: Maybe<Scalars["ID"]["output"]>;
    startDt: Scalars["DateTime"]["output"];
};

export type UnavailableResourceSlot = {
    endDt: Scalars["DateTime"]["output"];
    resourceId: Scalars["ID"]["output"];
    startDt: Scalars["DateTime"]["output"];
    tenantId: Scalars["ID"]["output"];
};

export type UncancelInvoiceRequest = {
    invoiceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UncancelInvoiceResponse =
    | AccessError
    | InvoiceUncancelled
    | NotFoundError;

export type UncancelPaymentRequest = {
    invoiceId: Scalars["ID"]["input"];
    paymentId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UncancelPaymentResponse =
    | AccessError
    | NotFoundError
    | PaymentUncancelled;

export type UnconfirmBookingRequest = {
    bookingId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UnconfirmBookingResponse =
    | AccessError
    | BookingAlreadyCancelledError
    | BookingAlreadyUnconfirmedError
    | BookingUnconfirmed
    | NotFoundError;

export type UnhandledRuleTypeError = {
    message: Scalars["String"]["output"];
};

export type UpdateBookingMetadataRequest = {
    bookingId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateBookingMetadataResponse =
    | AccessError
    | BookingMetadataUpdated
    | NotFoundError;

export type UpdateBookingReferenceIdRequest = {
    bookingId: Scalars["ID"]["input"];
    referenceId: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateBookingReferenceIdResponse =
    | AccessError
    | BookingAlreadyConfirmedError
    | BookingReferenceIdUpdated
    | NotFoundError;

export type UpdateBookingsReferenceIdRequest = {
    bookingIds: Array<Scalars["ID"]["input"]>;
    referenceId: Scalars["String"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateBookingsReferenceIdResponse =
    | AccessError
    | BookingsReferenceIdUpdated
    | NotFoundError;

export type UpdateCategoryMetadataRequest = {
    categoryId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
};

export type UpdateCategoryMetadataResponse =
    | AccessError
    | CategoryMetadataUpdated
    | NotFoundError;

export type UpdateCustomerMetadataRequest = {
    customerId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateCustomerMetadataResponse =
    | AccessError
    | CustomerMetadataUpdated
    | NotFoundError;

export type UpdateFixedDurationOptionsRequest = {
    options: Array<DurationOptionInput>;
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateFixedDurationOptionsResponse =
    | AccessError
    | FixedDurationOptionsUpdated;

export type UpdateInvoiceCourtsiteOwnershipRequest = {
    invoiceId: Scalars["ID"]["input"];
    isCourtsiteOwned: Scalars["Boolean"]["input"];
    settlementRate?: InputMaybe<Scalars["Float"]["input"]>;
    tenantId: Scalars["ID"]["input"];
};

export type UpdateInvoiceCourtsiteOwnershipResponse =
    | AccessError
    | InvoiceCourtsiteOwnershipRequestError
    | InvoiceCourtsiteOwnershipUpdated
    | NotFoundError;

export type UpdateInvoiceItemRelatedEntityRequest = {
    entityId: Scalars["ID"]["input"];
    entityType: InvoiceItemRelatedEntityType;
    invoiceId: Scalars["ID"]["input"];
    invoiceItemId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateInvoiceItemRelatedEntityResponse =
    | AccessError
    | InvoiceItemRelatedEntityUpdated
    | NotFoundError;

export type UpdateInvoiceMetadataRequest = {
    invoiceId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateInvoiceMetadataResponse =
    | AccessError
    | InvoiceMetadataUpdated
    | NotFoundError;

export type UpdateLightRequest = {
    boardId: Scalars["ID"]["input"];
    disableToggling: Scalars["Boolean"]["input"];
    isUserActivated: Scalars["Boolean"]["input"];
    lightId: Scalars["ID"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    relayId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateLightResponse = AccessError | LightUpdated | NotFoundError;

export type UpdateMyProfileEmailRequest = {
    email: Scalars["EmailAddress"]["input"];
};

export type UpdateMyProfileEmailResponse =
    | AccessError
    | InvalidValue
    | NotFoundError
    | UserProfileSet;

export type UpdateMyProfilePhoneNumberRequest = {
    phoneNumber: Scalars["PhoneNumber"]["input"];
};

export type UpdateMyProfilePhoneNumberResponse =
    | AccessError
    | InvalidValue
    | NotFoundError
    | UserProfileSet;

export type UpdateOrganisationRequest = {
    latitude: Scalars["Float"]["input"];
    longitude: Scalars["Float"]["input"];
    metadata: Scalars["JSONString"]["input"];
    organisationId: Scalars["ID"]["input"];
    organisationName: Scalars["String"]["input"];
    services: Array<Scalars["String"]["input"]>;
};

export type UpdateOrganisationResponse =
    | AccessError
    | NotFoundError
    | OrganisationUpdated;

export type UpdateOverrideRequest = {
    end: Scalars["DateTime"]["input"];
    overrideId: Scalars["ID"]["input"];
    start: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateOverrideResponse =
    | AccessError
    | NotFoundError
    | OverrideUpdated;

export type UpdateResourceTypesMetadataRequests = {
    requests: Array<ResourceTypesMetadataInput>;
    tenantId: Scalars["ID"]["input"];
};

export type UpdateResourceTypesMetadataResponse =
    | AccessError
    | NotFoundError
    | ResourceTypesMetadataUpdated;

export type UpdateScheduleResponse =
    | AccessError
    | NotFoundError
    | ScheduleUpdated;

export type UpdateServiceMetadataRequest = {
    metadata: Scalars["JSONString"]["input"];
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UpdateServiceMetadataResponse =
    | AccessError
    | NotFoundError
    | ServiceMetadataUpdated;

export type UpdateServiceVisibilityRequest = {
    serviceId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
    visibility: ServiceVisibility;
};

export type UpdateServiceVisibilityResponse =
    | AccessError
    | NotFoundError
    | ServiceVisibilityUpdated;

export type UpdateUserRequest = {
    displayName: Scalars["String"]["input"];
    phoneNumber: Scalars["String"]["input"];
};

export type UpdateUserResponse = AccessError | InternalError | UserUpdated;

export type UpdatedScheduleRequest = {
    daytimeSlots: Array<DayTimeSlotInput>;
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    rules: Array<RuleInput>;
    scheduleId: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
};

export type UserAlreadyExists = {
    message: Scalars["String"]["output"];
};

export type UserProfile = {
    dateOfBirth?: Maybe<Scalars["Date"]["output"]>;
    email?: Maybe<Scalars["EmailAddress"]["output"]>;
    firebaseId: Scalars["ID"]["output"];
    gender?: Maybe<Gender>;
    joined?: Maybe<Scalars["DateTime"]["output"]>;
    name: Scalars["String"]["output"];
    otherPreferredSports?: Maybe<Scalars["String"]["output"]>;
    phoneNumber?: Maybe<Scalars["PhoneNumber"]["output"]>;
    postCode?: Maybe<Scalars["String"]["output"]>;
    preferredSports?: Maybe<Array<Scalars["String"]["output"]>>;
    sportsFrequency?: Maybe<Scalars["Int"]["output"]>;
};

export type UserProfileCreated = {
    user: UserProfile;
};

export type UserProfileSet = {
    user: UserProfile;
};

export type UserProfileSyncedToFirebase = {
    user: UserProfile;
};

export type UserUpdated = {
    displayName: Scalars["String"]["output"];
    phoneNumber: Scalars["String"]["output"];
};

export type ValidateTaxPayerTinResponse =
    | AccessError
    | InternalError
    | TaxPayerTinValidated;

export type WorkflowCancelled = {
    message: Scalars["String"]["output"];
};

export type WorkflowExpired = {
    message: Scalars["String"]["output"];
};

export type WorkflowInfo = {
    runId: Scalars["ID"]["output"];
    workflowId: Scalars["ID"]["output"];
};

export type WorkflowListPage = {
    executions: Array<WorkflowInfo>;
    nextPageToken: Scalars["ID"]["output"];
};

export type WorkflowNotFound = {
    message: Scalars["String"]["output"];
};

export type WorkflowNotReady = {
    message: Scalars["String"]["output"];
};

export type UnavailableSlotsServiceQueryVariables = Exact<{
    categoryId: Scalars["ID"]["input"];
    dtRange: DateRangeFilter;
    tenantId: Scalars["ID"]["input"];
    isLoggedIn: Scalars["Boolean"]["input"];
}>;

export type UnavailableSlotsServiceQuery = {
    myMemberServiceIds?: Array<string>;
    unavailableResourceSlotsBetween?: Array<{
        resourceId: string;
        startDt: string;
        endDt: string;
    }> | null;
    onlineServices: Array<{
        uid: string;
        name: string;
        startTime?: string | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resourceId: string;
        }>;
    }>;
    serviceTags: Array<{ serviceId: string }>;
};

export type UnavailableSlotsQueryVariables = Exact<{
    categoryId: Scalars["ID"]["input"];
    dtRange: DateRangeFilter;
    tenantId: Scalars["ID"]["input"];
}>;

export type UnavailableSlotsQuery = {
    unavailableResourceSlotsBetween?: Array<{
        tenantId: string;
        resourceId: string;
        startDt: string;
        endDt: string;
    }> | null;
    onlineServices: Array<{
        uid: string;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
    }>;
};

export type PelangganAvailabilityViewQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    isLoggedIn: Scalars["Boolean"]["input"];
}>;

export type PelangganAvailabilityViewQuery = {
    myMemberServiceIds?: Array<string>;
    categories: Array<{ uid: string; name: string }>;
    onlineServices: Array<{
        uid: string;
        name: string;
        serviceMode: string;
        minBookingWindowMinutes?: number | null;
        bookingWindow?: number | null;
        metadata: string;
        customDurationOptions?: Array<number> | null;
        disallowBookingGap: boolean;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
        serviceCategory?: { uid: string; categoryId: string } | null;
    }>;
    serviceTags: Array<{ serviceId: string }>;
};

export type PelangganAvailabilityViewUnavailabilitiesQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    categoryId: Scalars["ID"]["input"];
    dtRange: DateRangeFilter;
}>;

export type PelangganAvailabilityViewUnavailabilitiesQuery = {
    unavailableResourceSlotsBetween?: Array<{
        resourceId: string;
        startDt: string;
        endDt: string;
    }> | null;
};

export type PelangganAvailabilityViewFixedDurationQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    serviceIds: Array<Scalars["ID"]["input"]>;
    start: Scalars["DateTime"]["input"];
    end: Scalars["DateTime"]["input"];
}>;

export type PelangganAvailabilityViewFixedDurationQuery = {
    fixedDurationOptionsStartingBetween: Array<{
        serviceId: string;
        options: Array<{ start: string; end: string }>;
    }>;
};

export type PelangganAvailabilityViewDurationFormQueryVariables = Exact<{
    req: Array<ServiceAvailabilityRequest>;
    quotReq: Array<QuotationRequest>;
}>;

export type PelangganAvailabilityViewDurationFormQuery = {
    serviceAvailabilities?: Array<{
        isAvailable: boolean;
        reason: ServiceUnavailabilityReason;
    }> | null;
    quotations?: Array<{
        deposit: number;
        remainder: number;
        price: number;
        satisfiesMinimumDuration: boolean;
        timeFullyAccounted: boolean;
    }> | null;
};

export type BookingCardQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type BookingCardQuery = { organisation: { uid: string; name: string } };

export type ReattemptBookingCardQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type ReattemptBookingCardQuery = {
    organisation: { uid: string; name: string };
};

export type ReattemptBookingCardCheckoutStateQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type ReattemptBookingCardCheckoutStateQuery = {
    courtsiteCheckoutStateById: {
        id: string;
        created: string;
        expired?: string | null;
        completed?: string | null;
        cancelled?: string | null;
        tenantId: string;
        bookingIds?: Array<string> | null;
        capturePaymentState?:
            | ({
                  id: string;
                  state: string;
                  completed?: string | null;
                  expired?: string | null;
                  cancelled?: string | null;
              } & {
                  " $fragmentRefs"?: {
                      PaymentHandlerDataFragment: PaymentHandlerDataFragment;
                  };
              })
            | null;
    };
};

export type ActivateLightEventsMutationVariables = Exact<{
    request: ActivateLightEventsRequest;
}>;

export type ActivateLightEventsMutation = {
    activateLightEvents?:
        | { __typename: "AccessError"; message: string }
        | {
              __typename: "LightEventsActivated";
              groupActivations?: Array<{
                  archived?: string | null;
                  created?: string | null;
                  groupId: string;
                  tenantId: string;
                  uid: string;
                  tenant: {
                      name: string;
                      uid: string;
                      metadata: string;
                      isCourtsitePartner: boolean;
                      services: Array<string>;
                  };
              }> | null;
          }
        | { __typename: "NotFoundError"; message: string }
        | null;
};

export type AddOnsFragment = { uid: string; name: string } & {
    " $fragmentName"?: "AddOnsFragment";
};

export type BookingDetailsCategoriesFragment = {
    uid: string;
    name: string;
    metadata: string;
} & { " $fragmentName"?: "BookingDetailsCategoriesFragment" };

export type BookingDetailsCentreFragment = {
    uid: string;
    name: string;
    metadata: string;
    isCourtsitePartner: boolean;
} & { " $fragmentName"?: "BookingDetailsCentreFragment" };

export type BookingDetailsFragment = {
    uid: string;
    startDt: string;
    endDt: string;
    metadata: string;
    service?: {
        uid: string;
        name: string;
        serviceMode: string;
        serviceCategory?: { uid: string; categoryId: string } | null;
    } | null;
    resources: Array<{
        uid: string;
        archived?: string | null;
        resource: { uid: string; name: string };
    }>;
    addons?: Array<{
        uid: string;
        addonId: string;
        perUnitPrice: number;
        quantity: number;
    } | null> | null;
} & { " $fragmentName"?: "BookingDetailsFragment" };

export type BookingSummaryBookingDetailsFragment = {
    uid: string;
    metadata: string;
    addons?: Array<{
        uid: string;
        perUnitPrice: number;
        quantity: number;
    } | null> | null;
} & { " $fragmentName"?: "BookingSummaryBookingDetailsFragment" };

export type PelangganUserBookingsCardWebCheckoutQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type PelangganUserBookingsCardWebCheckoutQuery = {
    courtsiteCheckoutStateById: {
        id: string;
        created: string;
        hasDeposit: boolean;
        state: string;
    };
};

export type PelangganUserBookingsCardWebCheckoutRescheduleQueryVariables =
    Exact<{
        workflowId: Scalars["ID"]["input"];
    }>;

export type PelangganUserBookingsCardWebCheckoutRescheduleQuery = {
    courtsiteRescheduleStateById: { created: string; state: string };
};

export type BottomBarProfileQueryVariables = Exact<{ [key: string]: never }>;

export type BottomBarProfileQuery = {
    myProfile?: {
        firebaseId: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
    } | null;
};

export type JoinGameMutationVariables = Exact<{
    gameID: Scalars["ID"]["input"];
    displayPicture?: InputMaybe<Scalars["String"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
}>;

export type JoinGameMutation = {
    joinGame:
        | { __typename: "AccessError"; message: string }
        | { __typename: "GameJoined" }
        | { __typename: "NoPlayerSlotError"; message: string }
        | { __typename: "NotFoundError"; message: string };
};

export type LeaveGameMutationVariables = Exact<{
    gameID: Scalars["ID"]["input"];
}>;

export type LeaveGameMutation = {
    leaveGame:
        | { __typename: "AccessError"; message: string }
        | { __typename: "GameLeft" }
        | { __typename: "NotFoundError"; message: string };
};

export type CategoryListCategoriesFragment = { uid: string; name: string } & {
    " $fragmentName"?: "CategoryListCategoriesFragment";
};

export type CategoryListVenuesFragment = {
    uid: string;
    services: Array<string>;
} & { " $fragmentName"?: "CategoryListVenuesFragment" };

export type DesktopNavProfileQueryVariables = Exact<{ [key: string]: never }>;

export type DesktopNavProfileQuery = {
    myProfile?: {
        firebaseId: string;
        name: string;
        email?: string | null;
    } | null;
};

export type DownloadInvoiceQueryVariables = Exact<{
    invoiceId: Scalars["ID"]["input"];
}>;

export type DownloadInvoiceQuery = {
    myInvoice: {
        uid: string;
        invoiceeName: string;
        outstandingBalance: number;
        total: number;
        printNumber?: number | null;
        created: string;
        isCourtsiteOwned: boolean;
        tenant: { uid: string; name: string; metadata: string };
        payments: Array<{
            uid: string;
            paymentType?: string | null;
            created: string;
            paymentMethod?: { uid: string; name: string } | null;
        }>;
        items: Array<{
            uid: string;
            description: string;
            quantity: number;
            subtotal: number;
            metadata: string;
            relatedEntity?: {
                uid: string;
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
    };
};

export type DownloadInvoiceBookingsQueryVariables = Exact<{
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type DownloadInvoiceBookingsQuery = {
    myBookingsByIds: Array<{
        uid: string;
        startDt: string;
        endDt: string;
        service?: { uid: string; name: string; serviceMode: string } | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
    }>;
};

export type FeatureVenueCategoriesFragment = { uid: string; name: string } & {
    " $fragmentName"?: "FeatureVenueCategoriesFragment";
};

export type FeatureVenueOrganisationsQueryVariables = Exact<{
    filter?: InputMaybe<ApprovedOrganisationsFilter>;
    cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FeatureVenueOrganisationsQuery = {
    approvedOrganisations?: {
        edges: Array<{
            node: {
                uid: string;
                name: string;
                metadata: string;
                services: Array<string>;
            };
        }>;
    } | null;
};

export type CreateUserProfileMutationVariables = Exact<{
    name: Scalars["String"]["input"];
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
}>;

export type CreateUserProfileMutation = {
    createUserProfile:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InvalidValue"; message: string }
        | { __typename: "UserAlreadyExists" }
        | { __typename: "UserProfileCreated" };
};

export type BookingCategorySelectQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type BookingCategorySelectQuery = {
    myMemberServiceIds: Array<string>;
    onlineServices: Array<{
        uid: string;
        name: string;
        minBookingWindowMinutes?: number | null;
        bookingWindow?: number | null;
        disallowBookingGap: boolean;
        metadata: string;
        serviceMode: string;
        customDurationOptions?: Array<number> | null;
        category?: { uid: string; name: string; metadata: string } | null;
        resources: Array<{
            uid: string;
            resourceId: string;
            archived?: string | null;
        }>;
    }>;
    addOns: Array<{
        uid: string;
        name: string;
        price: number;
        isInStock: boolean;
    }>;
    serviceTags: Array<{ serviceId: string }>;
};

export type PelangganBookingFormOnlineServicesQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    categoryId: Scalars["ID"]["input"];
}>;

export type PelangganBookingFormOnlineServicesQuery = {
    onlineServices: Array<{
        uid: string;
        name: string;
        serviceMode: string;
        minBookingWindowMinutes?: number | null;
        bookingWindow?: number | null;
        disallowBookingGap: boolean;
        customDurationOptions?: Array<number> | null;
        startTime?: string | null;
        endTime?: string | null;
        category?: { uid: string } | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: {
                uid: string;
                name: string;
                linkedResources: Array<{ uid: string }>;
            };
        }>;
    }>;
    organisation: { uid: string; metadata: string };
};

export type BookingSelectStartDurationSelectQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    serviceIds: Array<Scalars["ID"]["input"]>;
    startDt: Scalars["DateTime"]["input"];
    endDt: Scalars["DateTime"]["input"];
    categoryId: Scalars["ID"]["input"];
}>;

export type BookingSelectStartDurationSelectQuery = {
    onlineServices: Array<{
        uid: string;
        minBookingWindowMinutes?: number | null;
        disallowBookingGap: boolean;
        metadata: string;
        customDurationOptions?: Array<number> | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resourceId: string;
        }>;
    }>;
    fixedDurationOptionsStartingBetween: Array<{
        serviceId: string;
        options: Array<{ start: string; end: string }>;
    }>;
    unavailableResourceSlotsBetween?: Array<{
        endDt: string;
        resourceId: string;
        startDt: string;
    }> | null;
};

export type DailyBookingStepsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type DailyBookingStepsQuery = {
    onlineServices: Array<{
        uid: string;
        name: string;
        serviceMode: string;
        minBookingWindowMinutes?: number | null;
        bookingWindow?: number | null;
        disallowBookingGap: boolean;
        startTime?: string | null;
        endTime?: string | null;
        category?: { uid: string } | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: {
                uid: string;
                name: string;
                linkedResources: Array<{ uid: string }>;
            };
        }>;
    }>;
};

export type BookingSelectAvailabilityQueryVariables = Exact<{
    availabilitiesRequest: Array<ServiceAvailabilityRequest>;
    quotationsRequest: Array<QuotationRequest>;
    tenantId: Scalars["ID"]["input"];
    startDt: Scalars["DateTime"]["input"];
    endDt: Scalars["DateTime"]["input"];
    categoryId: Scalars["ID"]["input"];
}>;

export type BookingSelectAvailabilityQuery = {
    serviceAvailabilities?: Array<{
        availableSelectableResourceIds: Array<string>;
        serviceId: string;
    }> | null;
    quotations?: Array<{
        deposit: number;
        price: number;
        quotationFor: string;
        remainder: number;
        timeFullyAccounted: boolean;
    }> | null;
    unavailableResourceSlotsBetween?: Array<{
        endDt: string;
        resourceId: string;
        startDt: string;
    }> | null;
    onlineServices: Array<{ uid: string; category?: { uid: string } | null }>;
};

export type PelangganBookingSelectFormQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    categoryId: Scalars["ID"]["input"];
}>;

export type PelangganBookingSelectFormQuery = {
    onlineServices: Array<{
        uid: string;
        name: string;
        serviceMode: string;
        minBookingWindowMinutes?: number | null;
        bookingWindow?: number | null;
        disallowBookingGap: boolean;
        customDurationOptions?: Array<number> | null;
        startTime?: string | null;
        endTime?: string | null;
        category?: { uid: string } | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: {
                uid: string;
                name: string;
                linkedResources: Array<{ uid: string }>;
            };
        }>;
    }>;
    organisation: { uid: string; metadata: string } & {
        " $fragmentRefs"?: {
            FooterBookingDetailsCentreFragment: FooterBookingDetailsCentreFragment;
        };
    };
    categories: Array<{
        " $fragmentRefs"?: {
            FooterBookingDetailsCategoriesFragment: FooterBookingDetailsCategoriesFragment;
        };
    }>;
};

export type CartCentreDetailsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type CartCentreDetailsQuery = {
    organisation: {
        uid: string;
        name: string;
        services: Array<string>;
        metadata: string;
    };
    categories: Array<{ uid: string; name: string }>;
};

export type PelangganCheckDiscountQueryVariables = Exact<{
    items: Array<CheckoutDiscountItem>;
    promocode: Scalars["ID"]["input"];
}>;

export type PelangganCheckDiscountQuery = {
    checkoutDiscount: { discountedAmount: number };
};

export type PelangganCheckDiscountV2QueryVariables = Exact<{
    promocode: Scalars["ID"]["input"];
    tenantId: Scalars["ID"]["input"];
    items: Array<PromoCodeDiscountItem>;
}>;

export type PelangganCheckDiscountV2Query = {
    checkPromoCodeDiscount: { discountedAmount: number };
};

export type FooterBookingDetailsCentreFragment = {
    uid: string;
    name: string;
    metadata: string;
} & { " $fragmentName"?: "FooterBookingDetailsCentreFragment" };

export type FooterBookingDetailsCategoriesFragment = {
    uid: string;
    name: string;
} & { " $fragmentName"?: "FooterBookingDetailsCategoriesFragment" };

export type BookingFormAddOnSelectQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type BookingFormAddOnSelectQuery = {
    addOns: Array<{
        uid: string;
        name: string;
        price: number;
        isInStock: boolean;
        maxQuantity?: number | null;
    }>;
};

export type PelangganGameFormGameCreateFromInvoiceFormQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
}>;

export type PelangganGameFormGameCreateFromInvoiceFormQuery = {
    invoice: {
        uid: string;
        items: Array<{
            uid: string;
            relatedEntity?: {
                uid: string;
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
    };
    organisation: { uid: string; name: string; metadata: string };
};

export type EditGameMutationVariables = Exact<{
    uid: Scalars["ID"]["input"];
    startDt: Scalars["DateTime"]["input"];
    endDt: Scalars["DateTime"]["input"];
    description: Scalars["String"]["input"];
    location: LocationInput;
    maxPlayers: Scalars["Int"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    private?: InputMaybe<Scalars["Boolean"]["input"]>;
    reservedPlayers?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type EditGameMutation = {
    editGame:
        | { __typename: "AccessError"; message: string }
        | { __typename: "GameEdited"; game: { uid: string } }
        | { __typename: "NotFoundError"; message: string };
};

export type EditGameOrganizerMutationVariables = Exact<{
    gameId: Scalars["ID"]["input"];
    organizerDetails: GameOrganizerInput;
}>;

export type EditGameOrganizerMutation = {
    editGameOrganizer:
        | { __typename: "AccessError"; message: string }
        | { __typename: "GameOrganizerEdited"; game: { uid: string } }
        | { __typename: "NotFoundError"; message: string };
};

export type PelangganGameFormDateTimeFormQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
}>;

export type PelangganGameFormDateTimeFormQuery = {
    invoice: {
        uid: string;
        items: Array<{
            uid: string;
            relatedEntity?: {
                uid: string;
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
    };
    organisation: { uid: string; name: string; metadata: string };
};

export type PelangganGameFormDateTimeFormBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    uids: Array<Scalars["ID"]["input"]>;
}>;

export type PelangganGameFormDateTimeFormBookingsQuery = {
    bookingsByIds: Array<{ uid: string; startDt: string; endDt: string }>;
};

export type PelangganUserDataUseUpdateUserProfileMutationVariables = Exact<{
    request: SetUserProfileRequest;
}>;

export type PelangganUserDataUseUpdateUserProfileMutation = {
    setUserProfile:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InvalidValue"; message: string }
        | {
              __typename: "UserProfileSet";
              user: {
                  firebaseId: string;
                  name: string;
                  email?: string | null;
                  phoneNumber?: string | null;
              };
          };
};

export type UserDataCollectionFormQueryVariables = Exact<{
    [key: string]: never;
}>;

export type UserDataCollectionFormQuery = {
    myProfile?: {
        firebaseId: string;
        name: string;
        email?: string | null;
        phoneNumber?: string | null;
        gender?: Gender | null;
        dateOfBirth?: string | null;
        postCode?: string | null;
        preferredSports?: Array<string> | null;
        otherPreferredSports?: string | null;
        sportsFrequency?: number | null;
    } | null;
    categories: Array<{ uid: string; name: string }>;
};

export type PendingPaymentsQueryVariables = Exact<{
    startAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type PendingPaymentsQuery = {
    myInvoices?: Array<{
        uid: string;
        created: string;
        items: Array<{
            uid: string;
            metadata: string;
            relatedEntity?: {
                uid: string;
                entityType: InvoiceItemRelatedEntityType;
                entityId: string;
            } | null;
        }>;
    }> | null;
    myBookings: {
        edges: Array<{
            node: {
                uid: string;
                tenantId: string;
                referenceId: string;
                created: string;
                startDt: string;
                endDt: string;
                confirmed?: string | null;
                cancelled?: string | null;
                metadata: string;
                resources: Array<{
                    uid: string;
                    resource: {
                        uid: string;
                        name: string;
                        archived?: string | null;
                    };
                }>;
                service?: {
                    uid: string;
                    serviceMode: string;
                    category?: { uid: string; name: string } | null;
                } | null;
            };
        }>;
    };
};

export type HomepageUpcomingCardWebCheckoutQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type HomepageUpcomingCardWebCheckoutQuery = {
    courtsiteCheckoutStateById: {
        id: string;
        cancelled?: string | null;
        expired?: string | null;
        created: string;
        hasDeposit: boolean;
        state: string;
    };
};

export type HomepageUpcomingCardWebCheckoutRescheduleQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type HomepageUpcomingCardWebCheckoutRescheduleQuery = {
    courtsiteRescheduleStateById: {
        id: string;
        cancelled?: string | null;
        expired?: string | null;
        created: string;
        state: string;
    };
};

export type LocationAccordionOptionFragment = {
    uid: string;
    name: string;
    city?: string | null;
    coordinates: { latitude: number; longitude: number };
} & { " $fragmentName"?: "LocationAccordionOptionFragment" };

export type PelangganUserDetailsModalSetUserProfileMutationVariables = Exact<{
    request: SetUserProfileRequest;
}>;

export type PelangganUserDetailsModalSetUserProfileMutation = {
    setUserProfile:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InvalidValue"; message: string }
        | { __typename: "UserProfileSet"; user: { firebaseId: string } };
};

export type NavigationMenuProfileQueryVariables = Exact<{
    [key: string]: never;
}>;

export type NavigationMenuProfileQuery = {
    myProfile?: {
        firebaseId: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
    } | null;
};

export type ConfirmCheckoutAvailabilitiesQueryVariables = Exact<{
    serviceAvailabilitiesRequest: Array<ServiceAvailabilityRequest>;
}>;

export type ConfirmCheckoutAvailabilitiesQuery = {
    serviceAvailabilities?: Array<{ isAvailable: boolean }> | null;
};

export type StartRescheduleCheckoutMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingId: Scalars["ID"]["input"];
    newSlot: CourtsiteBookingSlotInput;
}>;

export type StartRescheduleCheckoutMutation = {
    startCourtsiteReschedule:
        | {
              __typename: "CourtsiteRescheduleBookingsConflictingWorkflow";
              message: string;
          }
        | {
              __typename: "CourtsiteRescheduleBookingsUnavailable";
              message: string;
          }
        | {
              __typename: "CourtsiteRescheduleStarted";
              state: {
                  id: string;
                  runId: string;
                  state: string;
                  completed?: string | null;
                  expired?: string | null;
                  cancelled?: string | null;
                  created: string;
                  priceDifference?: number | null;
                  newBookingId?: string | null;
                  newInvoiceId?: string | null;
                  redirectUrl?: string | null;
                  capturePaymentWorkflowId?: string | null;
                  capturePaymentState?: {
                      id: string;
                      tenantId: string;
                      invoiceId: string;
                      state: string;
                      amountToCapture?: number | null;
                      paymentSessionId?: string | null;
                      completed?: string | null;
                      expired?: string | null;
                      cancelled?: string | null;
                      created: string;
                  } | null;
              };
          }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type SearchCentreCardQueryQueryVariables = Exact<{
    [key: string]: never;
}>;

export type SearchCentreCardQueryQuery = {
    categories: Array<{ uid: string; name: string }>;
};

export type SearchBarCategoriesFragment = ({ uid: string; name: string } & {
    " $fragmentRefs"?: {
        CategoryListCategoriesFragment: CategoryListCategoriesFragment;
    };
}) & { " $fragmentName"?: "SearchBarCategoriesFragment" };

export type SearchBarLocationsFragment = {
    uid: string;
    name: string;
    city?: string | null;
    coordinates: { latitude: number; longitude: number };
} & { " $fragmentName"?: "SearchBarLocationsFragment" };

export type SearchBarVenuesFragment = ({ uid: string; name: string } & {
    " $fragmentRefs"?: {
        CategoryListVenuesFragment: CategoryListVenuesFragment;
    };
}) & { " $fragmentName"?: "SearchBarVenuesFragment" };

export type SearchMobileCategoriesFragment = ({ uid: string; name: string } & {
    " $fragmentRefs"?: {
        CategoryListCategoriesFragment: CategoryListCategoriesFragment;
    };
}) & { " $fragmentName"?: "SearchMobileCategoriesFragment" };

export type SearchMobileLocationsFragment = {
    uid: string;
    name: string;
    city?: string | null;
    coordinates: { latitude: number; longitude: number };
} & { " $fragmentName"?: "SearchMobileLocationsFragment" };

export type SearchMobileVenuesFragment = ({ uid: string; name: string } & {
    " $fragmentRefs"?: {
        CategoryListVenuesFragment: CategoryListVenuesFragment;
    };
}) & { " $fragmentName"?: "SearchMobileVenuesFragment" };

export type PelangganSearchResultsQueryVariables = Exact<{
    request: CourtsiteSearchRequest;
}>;

export type PelangganSearchResultsQuery = {
    courtsiteSearch: Array<{
        uid: string;
        name: string;
        services: Array<string>;
        metadata: string;
    }>;
};

export type PelangganSearchResultLocationQueryVariables = Exact<{
    [key: string]: never;
}>;

export type PelangganSearchResultLocationQuery = {
    locations: Array<{
        uid: string;
        name: string;
        city?: string | null;
        coordinates: { latitude: number; longitude: number };
    }>;
};

export type PelangganSearchResultServiceQueryVariables = Exact<{
    categoryId: Scalars["ID"]["input"];
}>;

export type PelangganSearchResultServiceQuery = {
    onlineServicesByCategory: Array<{ uid: string; tenantId: string }>;
};

export type PelangganCentreSearchUseAvailabilitiesQueryVariables = Exact<{
    request: Array<ServiceAvailabilityRequest>;
    quotationsRequest: Array<QuotationRequest>;
}>;

export type PelangganCentreSearchUseAvailabilitiesQuery = {
    serviceAvailabilities?: Array<{
        isAvailable: boolean;
        tenantId: string;
        serviceId: string;
    }> | null;
    quotations?: Array<{
        quotationFor: string;
        timeFullyAccounted: boolean;
    }> | null;
};

export type ViewLiveAvailabilityQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type ViewLiveAvailabilityQuery = {
    myMemberServiceIds: Array<string>;
    onlineServices: Array<{
        uid: string;
        name: string;
        minBookingWindowMinutes?: number | null;
        bookingWindow?: number | null;
        disallowBookingGap: boolean;
        metadata: string;
        serviceMode: string;
        customDurationOptions?: Array<number> | null;
        category?: { uid: string; name: string; metadata: string } | null;
        resources: Array<{
            uid: string;
            resourceId: string;
            archived?: string | null;
        }>;
    }>;
    addOns: Array<{
        uid: string;
        name: string;
        price: number;
        isInStock: boolean;
    }>;
    serviceTags: Array<{ serviceId: string }>;
};

export type PelangganUseBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type PelangganUseBookingsQuery = {
    bookingsByIds: Array<{
        uid: string;
        startDt: string;
        endDt: string;
        metadata: string;
        cancelled?: string | null;
        referenceId: string;
        service?: {
            uid: string;
            name: string;
            serviceMode: string;
            minRescheduleNotice?: number | null;
            serviceCategory?: { uid: string; categoryId: string } | null;
        } | null;
        guests?: Array<{ uid: string; userId?: string | null } | null> | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
    }>;
};

export type GetGameQueryVariables = Exact<{
    gameId: Scalars["ID"]["input"];
}>;

export type GetGameQuery = {
    game: {
        categoryId: string;
        description: string;
        endDt: string;
        maxPlayers: number;
        metadata: string;
        name: string;
        startDt: string;
        uid: string;
        isPrivate: boolean;
        reservedPlayers?: number | null;
        location: { tenant: { uid: string; name: string; metadata: string } };
        organizer: {
            displayPicture?: string | null;
            name: string;
            organizerId: string;
        };
        players?: Array<{
            displayPicture?: string | null;
            name: string;
            playerId: string;
        }> | null;
    };
    categories: Array<{ uid: string; name: string }>;
};

export type StartCheckoutMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    slots: Array<CourtsiteCheckoutSlotInput>;
    name: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
    email: Scalars["String"]["input"];
    addOns?: InputMaybe<Array<CourtsiteCheckoutAddOnInput>>;
    promocode?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type StartCheckoutMutation = {
    startCourtsiteCheckout:
        | {
              __typename: "CourtsiteCheckoutBookingsUnavailable";
              message: string;
          }
        | {
              __typename: "CourtsiteCheckoutStarted";
              state: { id: string; runId: string; state: string };
          }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type PublicGamesQueryVariables = Exact<{
    filter?: InputMaybe<PublicGamesFilter>;
    page?: InputMaybe<PageInput>;
}>;

export type PublicGamesQuery = {
    publicGames: {
        edges: Array<{
            node: {
                uid: string;
                name: string;
                categoryId: string;
                startDt: string;
                endDt: string;
                maxPlayers: number;
                metadata: string;
                reservedPlayers?: number | null;
                location: { tenant: { uid: string; name: string } };
                organizer: { name: string };
                players?: Array<{ displayPicture?: string | null }> | null;
            };
        }>;
        pageInfo: {
            startCursor?: string | null;
            endCursor?: string | null;
            hasNextPage?: boolean | null;
        };
    };
    categories: Array<{ uid: string; name: string }>;
};

export type BookingFieldsFragment = {
    uid: string;
    startDt: string;
    endDt: string;
    referenceId: string;
    tenantId: string;
    metadata: string;
    service?: {
        uid: string;
        name: string;
        serviceMode: string;
        minRescheduleNotice?: number | null;
        category?: { uid: string; name: string; metadata: string } | null;
    } | null;
    resources: Array<{
        uid: string;
        archived?: string | null;
        resource: { uid: string; name: string };
    }>;
} & { " $fragmentName"?: "BookingFieldsFragment" };

export type CourtsiteRescheduleStateByIdQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type CourtsiteRescheduleStateByIdQuery = {
    courtsiteRescheduleStateById: {
        id: string;
        runId: string;
        state: string;
        completed?: string | null;
        expired?: string | null;
        cancelled?: string | null;
        created: string;
        priceDifference?: number | null;
        oldBookingId: string;
        newBookingId?: string | null;
        newInvoiceId?: string | null;
        redirectUrl?: string | null;
        capturePaymentWorkflowId?: string | null;
        capturePaymentState?:
            | ({
                  id: string;
                  runId: string;
                  tenantId: string;
                  state: string;
                  amountToCapture?: number | null;
                  paymentSessionId?: string | null;
                  completed?: string | null;
                  expired?: string | null;
                  cancelled?: string | null;
                  created: string;
                  isCancellable: boolean;
                  attempts: number;
              } & {
                  " $fragmentRefs"?: {
                      PaymentHandlerDataFragment: PaymentHandlerDataFragment;
                  };
              })
            | null;
    };
};

export type PelangganUseRescheduleCheckoutWorkflowBookingsQueryVariables =
    Exact<{
        oldBookingId: Scalars["ID"]["input"];
        newBookingId: Scalars["ID"]["input"];
    }>;

export type PelangganUseRescheduleCheckoutWorkflowBookingsQuery = {
    oldBooking: {
        " $fragmentRefs"?: { BookingFieldsFragment: BookingFieldsFragment };
    };
    newBooking: {
        " $fragmentRefs"?: { BookingFieldsFragment: BookingFieldsFragment };
    };
};

export type UserGamesQueryVariables = Exact<{
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<GameFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    order?: InputMaybe<SortOrderEnum>;
}>;

export type UserGamesQuery = {
    myGames: {
        edges: Array<{
            node: {
                name: string;
                startDt: string;
                endDt: string;
                archived?: string | null;
                categoryId: string;
                metadata: string;
                uid: string;
                maxPlayers: number;
                reservedPlayers?: number | null;
                location: { tenantId: string };
                players?: Array<{ displayPicture?: string | null }> | null;
                organizer: { organizerId: string; name: string };
            };
        }>;
        pageInfo: {
            startCursor?: string | null;
            endCursor?: string | null;
            hasNextPage?: boolean | null;
        };
    };
};

export type GetUserGroupedBookingsQueryVariables = Exact<{
    filter?: InputMaybe<MyGroupedBookingsFilter>;
    after?: InputMaybe<Scalars["String"]["input"]>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    order?: InputMaybe<SortOrderEnum>;
}>;

export type GetUserGroupedBookingsQuery = {
    myGroupedBookings: {
        edges: Array<{
            node: {
                referenceId: string;
                startDt: string;
                endDt: string;
                bookings: Array<{
                    uid: string;
                    created: string;
                    tenantId: string;
                    referenceId: string;
                    startDt: string;
                    endDt: string;
                    confirmed?: string | null;
                    cancelled?: string | null;
                    metadata: string;
                    service: {
                        uid: string;
                        serviceMode: string;
                        categoryId: string;
                        categoryName: string;
                    };
                    resources: Array<{
                        uid: string;
                        resourceId: string;
                        resourceName: string;
                    }>;
                }>;
            };
        }>;
        pageInfo: {
            startCursor?: string | null;
            endCursor?: string | null;
            hasNextPage?: boolean | null;
        };
    };
};

export type MyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type MyProfileQuery = {
    myProfile?: {
        firebaseId: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
        gender?: Gender | null;
        dateOfBirth?: string | null;
        postCode?: string | null;
        preferredSports?: Array<string> | null;
        otherPreferredSports?: string | null;
        sportsFrequency?: number | null;
    } | null;
};

export type PaymentHandlerDataFragment = {
    invoiceId: string;
    billplzPaymentUrl?: string | null;
    ipay88BackendUrl?: string | null;
    ipay88ResponseUrl?: string | null;
    ipay88RequestSignature?: string | null;
    razorpayOrderId?: string | null;
    razorpayResponseUrl?: string | null;
    kiplepayReferenceNo?: string | null;
    kiplepayHashValue?: string | null;
    kiplepayCallbackUrl?: string | null;
    kiplepayReturnUrl?: string | null;
    kiplepayMerchantId?: string | null;
    spayOrderNo?: string | null;
    spayReturnUrl?: string | null;
    spayCallbackUrl?: string | null;
    spayEncryptedPayload?: string | null;
    spayQrCode?: string | null;
} & { " $fragmentName"?: "PaymentHandlerDataFragment" };

export type ForceRetryCapturePaymentSPayCheckoutMutationVariables = Exact<{
    request: ForceRetryCapturePaymentRequest;
}>;

export type ForceRetryCapturePaymentSPayCheckoutMutation = {
    forceRetryCapturePayment:
        | {
              __typename: "CapturePaymentForceRetried";
              state: {
                  id: string;
                  tenantId: string;
                  invoiceId: string;
                  state: string;
                  amountToCapture?: number | null;
                  completed?: string | null;
                  expired?: string | null;
                  cancelled?: string | null;
                  created: string;
              } & {
                  " $fragmentRefs"?: {
                      SPayDataCheckoutFragment: SPayDataCheckoutFragment;
                  };
              };
          }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type SPayDataCheckoutFragment = {
    id: string;
    spayQrCode?: string | null;
    paymentSessionCreated?: string | null;
} & { " $fragmentName"?: "SPayDataCheckoutFragment" };

export type PelangganCentreActivitiesCategoriesQueryVariables = Exact<{
    startsAfter: Scalars["DateTime"]["input"];
    tenantId: Scalars["ID"]["input"];
}>;

export type PelangganCentreActivitiesCategoriesQuery = {
    publicGameCount: Array<{
        categoryId: string;
        gameCount: number;
        locationTenantIds: Array<string | null>;
    }>;
    categories: Array<{ uid: string; name: string }>;
    organisation: {
        uid: string;
        name: string;
        services: Array<string>;
        metadata: string;
        isCourtsitePartner: boolean;
        location: { longitude: number; latitude: number };
    };
};

export type PelangganCentreQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    isLoggedIn: Scalars["Boolean"]["input"];
}>;

export type PelangganCentreQuery = {
    myMemberServiceIds?: Array<string>;
    organisation: {
        uid: string;
        name: string;
        services: Array<string>;
        metadata: string;
        isCourtsitePartner: boolean;
        location: { longitude: number; latitude: number };
    };
    onlineServices: Array<{
        uid: string;
        name: string;
        serviceMode: string;
        metadata: string;
        serviceCategory?: { uid: string; categoryId: string } | null;
        resources: Array<{
            uid: string;
            resourceId: string;
            archived?: string | null;
        }>;
    }>;
    categories: Array<{ uid: string; name: string; metadata: string }>;
    serviceTags: Array<{ serviceId: string }>;
};

export type PelangganCentreNearbyQueryVariables = Exact<{
    req: CourtsiteSearchRequest;
}>;

export type PelangganCentreNearbyQuery = {
    courtsiteSearch: Array<
        { uid: string } & {
            " $fragmentRefs"?: { VenueCardOrgFragment: VenueCardOrgFragment };
        }
    >;
};

export type VenueCardOrgFragment = {
    uid: string;
    name: string;
    services: Array<string>;
    metadata: string;
} & { " $fragmentName"?: "VenueCardOrgFragment" };

export type PelangganCentreBookingSelectHourlyQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type PelangganCentreBookingSelectHourlyQuery = {
    organisation: { uid: string; name: string } & {
        " $fragmentRefs"?: {
            FooterBookingDetailsCentreFragment: FooterBookingDetailsCentreFragment;
        };
    };
    categories: Array<
        { uid: string; name: string; metadata: string } & {
            " $fragmentRefs"?: {
                FooterBookingDetailsCategoriesFragment: FooterBookingDetailsCategoriesFragment;
            };
        }
    >;
};

export type PelangganCentreCategoriesQueryVariables = Exact<{
    [key: string]: never;
}>;

export type PelangganCentreCategoriesQuery = {
    categories: Array<{ uid: string; name: string; metadata: string }>;
};

export type PelangganCentreBookingSelectQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type PelangganCentreBookingSelectQuery = {
    myMemberServiceIds: Array<string>;
    organisation: { uid: string; name: string } & {
        " $fragmentRefs"?: {
            FooterBookingDetailsCentreFragment: FooterBookingDetailsCentreFragment;
        };
    };
    onlineServices: Array<{
        uid: string;
        name: string;
        minBookingWindowMinutes?: number | null;
        bookingWindow?: number | null;
        disallowBookingGap: boolean;
        metadata: string;
        serviceMode: string;
        customDurationOptions?: Array<number> | null;
        category?: { uid: string; name: string; metadata: string } | null;
    }>;
    addOns: Array<{
        uid: string;
        name: string;
        price: number;
        isInStock: boolean;
    }>;
    serviceTags: Array<{ serviceId: string }>;
    categories: Array<{
        " $fragmentRefs"?: {
            FooterBookingDetailsCategoriesFragment: FooterBookingDetailsCategoriesFragment;
        };
    }>;
};

export type PelangganCheckoutWorkflowPaymentQueryVariables = Exact<{
    workflowID: Scalars["ID"]["input"];
}>;

export type PelangganCheckoutWorkflowPaymentQuery = {
    courtsiteCheckoutStateById: {
        id: string;
        created: string;
        expired?: string | null;
        completed?: string | null;
        cancelled?: string | null;
        tenantId: string;
        bookingIds?: Array<string> | null;
        promocode?: string | null;
        discountedAmount?: number | null;
        capturePaymentState?:
            | ({
                  id: string;
                  state: string;
                  completed?: string | null;
                  expired?: string | null;
                  cancelled?: string | null;
                  invoiceId: string;
              } & {
                  " $fragmentRefs"?: {
                      PaymentHandlerDataFragment: PaymentHandlerDataFragment;
                  };
              })
            | null;
    };
};

export type CourtsiteCheckoutWorkflowPaymentBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type CourtsiteCheckoutWorkflowPaymentBookingsQuery = {
    bookingsByIds: Array<
        { uid: string; referenceId: string } & {
            " $fragmentRefs"?: {
                BookingDetailsFragment: BookingDetailsFragment;
                BookingSummaryBookingDetailsFragment: BookingSummaryBookingDetailsFragment;
            };
        }
    >;
    organisation: { uid: string; metadata: string } & {
        " $fragmentRefs"?: {
            BookingDetailsCentreFragment: BookingDetailsCentreFragment;
        };
    };
    categories: Array<
        { uid: string; name: string; metadata: string } & {
            " $fragmentRefs"?: {
                BookingDetailsCategoriesFragment: BookingDetailsCategoriesFragment;
            };
        }
    >;
    addOns: Array<{ " $fragmentRefs"?: { AddOnsFragment: AddOnsFragment } }>;
};

export type CheckoutInvoiceQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    invoiceId: Scalars["ID"]["input"];
}>;

export type CheckoutInvoiceQuery = {
    invoice: {
        uid: string;
        invoiceeName: string;
        invoiceePhoneNumber?: string | null;
        invoiceeEmail?: string | null;
    };
};

export type CancelCourtsiteCheckoutMutationVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type CancelCourtsiteCheckoutMutation = {
    cancelCourtsiteCheckout:
        | { __typename: "CourtsiteCheckoutNotCancellable"; message: string }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotFound"; message: string };
};

export type ProvideCourtsiteCheckoutPaymentDetailsMutationVariables = Exact<{
    checkoutId: Scalars["ID"]["input"];
    name: Scalars["String"]["input"];
    email: Scalars["String"]["input"];
    phoneNumber: Scalars["String"]["input"];
    paymentProvider: PaymentProvider;
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ProvideCourtsiteCheckoutPaymentDetailsMutation = {
    provideCourtsiteCheckoutPaymentDetails:
        | { __typename: "CourtsiteCheckoutExpired"; message: string }
        | {
              __typename: "CourtsiteCheckoutPaymentDetailsProvided";
              state: {
                  id: string;
                  runId: string;
                  state: string;
                  capturePaymentState?:
                      | ({ id: string; amountToCapture?: number | null } & {
                            " $fragmentRefs"?: {
                                PaymentHandlerDataFragment: PaymentHandlerDataFragment;
                            };
                        })
                      | null;
              };
          }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type ForceRetryCapturePaymentMutationVariables = Exact<{
    request: ForceRetryCapturePaymentRequest;
}>;

export type ForceRetryCapturePaymentMutation = {
    forceRetryCapturePayment:
        | {
              __typename: "CapturePaymentForceRetried";
              state: { id: string; amountToCapture?: number | null } & {
                  " $fragmentRefs"?: {
                      PaymentHandlerDataFragment: PaymentHandlerDataFragment;
                  };
              };
          }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type CheckoutResultCourtsiteCheckoutStateByIdQueryVariables = Exact<{
    workflowID: Scalars["ID"]["input"];
}>;

export type CheckoutResultCourtsiteCheckoutStateByIdQuery = {
    courtsiteCheckoutStateById: {
        id: string;
        runId: string;
        tenantId: string;
        completed?: string | null;
        expired?: string | null;
        cancelled?: string | null;
        created: string;
        referenceId?: string | null;
        bookingIds?: Array<string> | null;
        invoiceIds: Array<string>;
        capturePaymentState?: {
            id: string;
            completed?: string | null;
            expired?: string | null;
            cancelled?: string | null;
            state: string;
        } | null;
    };
};

export type CheckoutResultBookingsCentreQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
    invoiceIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
    showInvoice: Scalars["Boolean"]["input"];
}>;

export type CheckoutResultBookingsCentreQuery = {
    bookingsByIds: Array<{
        uid: string;
        startDt: string;
        endDt: string;
        referenceId: string;
        metadata: string;
        cancelled?: string | null;
        confirmed?: string | null;
        service?: {
            uid: string;
            name: string;
            serviceMode: string;
            minRescheduleNotice?: number | null;
            serviceCategory?: { uid: string; categoryId: string } | null;
        } | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
    }>;
    organisation: {
        uid: string;
        name: string;
        metadata: string;
        location: { latitude: number; longitude: number };
    };
    myInvoices?: Array<{
        uid: string;
        total: number;
        outstandingBalance: number;
        cancelled?: string | null;
        items: Array<{
            uid: string;
            created: string;
            description: string;
            perUnitPrice: number;
            quantity: number;
            subtotal: number;
            metadata: string;
            relatedEntity?: {
                uid: string;
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
    }> | null;
    groupIdEventsActivationStatus?: Array<{
        groupId: string;
        isActivated: boolean;
        requiresUserActivation: boolean;
        tenantId: string;
    }> | null;
};

export type PelangganCheckoutWorkflowVerifyQueryVariables = Exact<{
    workflowID: Scalars["ID"]["input"];
}>;

export type PelangganCheckoutWorkflowVerifyQuery = {
    courtsiteCheckoutStateById: {
        id: string;
        created: string;
        expired?: string | null;
        completed?: string | null;
        cancelled?: string | null;
        capturePaymentState?: {
            id: string;
            completed?: string | null;
            expired?: string | null;
            cancelled?: string | null;
            state: string;
        } | null;
    };
};

export type ConfirmCapturePaymentMutationVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
    queryParams: Scalars["JSONString"]["input"];
}>;

export type ConfirmCapturePaymentMutation = {
    confirmCapturePayment:
        | { __typename: "CapturePaymentConfirmed"; state: { id: string } }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type CourtsiteCheckoutStateByIdSpayQueryVariables = Exact<{
    checkoutId: Scalars["ID"]["input"];
}>;

export type CourtsiteCheckoutStateByIdSpayQuery = {
    courtsiteCheckoutStateById: {
        id: string;
        created: string;
        cancelled?: string | null;
        completed?: string | null;
        expired?: string | null;
        capturePaymentState?: {
            id: string;
            created: string;
            cancelled?: string | null;
            completed?: string | null;
            expired?: string | null;
            amountToCapture?: number | null;
            spayQrCode?: string | null;
            paymentSessionCreated?: string | null;
        } | null;
    };
};

export type ExploreCategoryCentreFragment = {
    uid: string;
    name: string;
    metadata: string;
} & { " $fragmentName"?: "ExploreCategoryCentreFragment" };

export type PelangganExploreByCategoryIdDataQueryVariables = Exact<{
    [key: string]: never;
}>;

export type PelangganExploreByCategoryIdDataQuery = {
    locations: Array<
        {
            uid: string;
            name: string;
            city?: string | null;
            coordinates: { latitude: number; longitude: number };
        } & {
            " $fragmentRefs"?: {
                LocationAccordionOptionFragment: LocationAccordionOptionFragment;
            };
        }
    >;
    categories: Array<{ name: string; uid: string; metadata: string }>;
};

export type PelangganExploreByCategoryIdSearchQueryVariables = Exact<{
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<ApprovedOrganisationsFilter>;
    sortOrder?: InputMaybe<OrganisationSortOrder>;
    limit: Scalars["Int"]["input"];
}>;

export type PelangganExploreByCategoryIdSearchQuery = {
    approvedOrganisations?: {
        edges: Array<{
            node: {
                uid: string;
                name: string;
                isCourtsitePartner: boolean;
                metadata: string;
                services: Array<string>;
                location: { latitude: number; longitude: number };
            } & {
                " $fragmentRefs"?: {
                    ExploreCategoryCentreFragment: ExploreCategoryCentreFragment;
                };
            };
        }>;
        pageInfo: { endCursor?: string | null; hasNextPage?: boolean | null };
    } | null;
};

export type ExploreCategoryQueryVariables = Exact<{ [key: string]: never }>;

export type ExploreCategoryQuery = {
    categories: Array<{
        " $fragmentRefs"?: {
            CategoryListCategoriesFragment: CategoryListCategoriesFragment;
            SearchBarCategoriesFragment: SearchBarCategoriesFragment;
        };
    }>;
    courtsitePartners: Array<{
        " $fragmentRefs"?: {
            CategoryListVenuesFragment: CategoryListVenuesFragment;
            SearchBarVenuesFragment: SearchBarVenuesFragment;
        };
    }>;
    locations: Array<{
        " $fragmentRefs"?: {
            SearchBarLocationsFragment: SearchBarLocationsFragment;
        };
    }>;
};

export type ExploreGamesQueryVariables = Exact<{
    startsAfter: Scalars["DateTime"]["input"];
}>;

export type ExploreGamesQuery = {
    publicGameCount: Array<{
        categoryId: string;
        gameCount: number;
        locationTenantIds: Array<string | null>;
    }>;
    categories: Array<{ uid: string; name: string; metadata: string }>;
    courtsitePartners: Array<{
        uid: string;
        name: string;
        location: { latitude: number; longitude: number };
    }>;
    locations: Array<{
        uid: string;
        name: string;
        city?: string | null;
        coordinates: { latitude: number; longitude: number };
    }>;
};

export type ExploreGamesCourtsiteSearchQueryVariables = Exact<{
    filter?: InputMaybe<ApprovedOrganisationsFilter>;
    limit?: InputMaybe<Scalars["Int"]["input"]>;
    after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ExploreGamesCourtsiteSearchQuery = {
    approvedOrganisations?: { edges: Array<{ node: { uid: string } }> } | null;
};

export type ArchiveGameMutationVariables = Exact<{
    uid: Scalars["ID"]["input"];
}>;

export type ArchiveGameMutation = {
    archiveGame:
        | { __typename: "AccessError"; message: string }
        | { __typename: "GameArchived"; game: { uid: string } }
        | { __typename: "NotFoundError"; message: string };
};

export type PelangganGameQueryVariables = Exact<{
    gameId: Scalars["ID"]["input"];
}>;

export type PelangganGameQuery = {
    game: {
        categoryId: string;
        archived?: string | null;
        description: string;
        endDt: string;
        maxPlayers: number;
        metadata: string;
        name: string;
        startDt: string;
        uid: string;
        isPrivate: boolean;
        reservedPlayers?: number | null;
        location: { tenantId: string };
        organizer: {
            displayPicture?: string | null;
            name: string;
            organizerId: string;
        };
        players?: Array<{
            displayPicture?: string | null;
            name: string;
            playerId: string;
        }> | null;
    };
    categories: Array<{ uid: string; name: string; metadata: string }>;
};

export type GetCentreQueryVariables = Exact<{
    orgID: Scalars["ID"]["input"];
}>;

export type GetCentreQuery = {
    organisation: {
        uid: string;
        name: string;
        services: Array<string>;
        metadata: string;
        location: { latitude: number; longitude: number };
    };
    onlineServices: Array<{
        uid: string;
        name: string;
        serviceMode: string;
        metadata: string;
        serviceCategory?: { uid: string; categoryId: string } | null;
        resources: Array<{
            uid: string;
            resourceId: string;
            archived?: string | null;
        }>;
    }>;
};

export type StartCourtsiteGameWorkflowMutationVariables = Exact<{
    categoryId: Scalars["ID"]["input"];
    description: Scalars["String"]["input"];
    endDt: Scalars["Time"]["input"];
    location: StartCourtsiteGameWorkflowLocationInput;
    maxPlayers: Scalars["Int"]["input"];
    metadata: Scalars["JSONString"]["input"];
    name: Scalars["String"]["input"];
    organizerDetails: StartCourtsiteGameWorkflowPlayerInput;
    startDt: Scalars["Time"]["input"];
    private?: InputMaybe<Scalars["Boolean"]["input"]>;
    reservedPlayers?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type StartCourtsiteGameWorkflowMutation = {
    startCourtsiteGameWorkflow:
        | { state: { gameId?: string | null } }
        | { message: string }
        | { message: string };
};

export type PelangganHomePageQueryVariables = Exact<{
    now: Scalars["DateTime"]["input"];
    startsAfter: Scalars["DateTime"]["input"];
    isLoggedIn: Scalars["Boolean"]["input"];
}>;

export type PelangganHomePageQuery = {
    tenantIdsFromPastBookings?: Array<string | null> | null;
    upcomingBookings?: {
        edges: Array<{
            node: {
                referenceId: string;
                startDt: string;
                endDt: string;
                bookings: Array<{
                    uid: string;
                    created: string;
                    tenantId: string;
                    startDt: string;
                    endDt: string;
                    confirmed?: string | null;
                    cancelled?: string | null;
                    metadata: string;
                    tenant: { uid: string; name: string };
                    service: {
                        uid: string;
                        serviceMode: string;
                        categoryName: string;
                    };
                    resources: Array<{
                        resourceId: string;
                        resourceName: string;
                    }>;
                }>;
            };
        }>;
    };
    publicGameCount: Array<{
        categoryId: string;
        gameCount: number;
        locationTenantIds: Array<string | null>;
    }>;
    categories: Array<{
        " $fragmentRefs"?: {
            FeatureVenueCategoriesFragment: FeatureVenueCategoriesFragment;
            SocialGamesCategoriesFragment: SocialGamesCategoriesFragment;
            SearchBarCategoriesFragment: SearchBarCategoriesFragment;
        };
    }>;
    courtsitePartners: Array<
        { uid: string; name: string; metadata: string } & {
            " $fragmentRefs"?: {
                SearchBarVenuesFragment: SearchBarVenuesFragment;
            };
        }
    >;
    locations: Array<{
        " $fragmentRefs"?: {
            SearchBarLocationsFragment: SearchBarLocationsFragment;
        };
    }>;
};

export type HomepagePublicGameQueryVariables = Exact<{
    filter?: InputMaybe<PublicGamesFilter>;
    page?: InputMaybe<PageInput>;
}>;

export type HomepagePublicGameQuery = {
    publicGames: {
        edges: Array<{
            node: {
                uid: string;
                name: string;
                categoryId: string;
                startDt: string;
                endDt: string;
                maxPlayers: number;
                metadata: string;
                reservedPlayers?: number | null;
                location: { tenant: { uid: string; name: string } };
                organizer: { name: string };
                players?: Array<{ displayPicture?: string | null }> | null;
            };
        }>;
    };
};

export type SocialGamesCategoriesFragment = { uid: string; name: string } & {
    " $fragmentName"?: "SocialGamesCategoriesFragment";
};

export type PelangganInvoiceQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
}>;

export type PelangganInvoiceQuery = {
    invoice: {
        uid: string;
        total: number;
        outstandingBalance: number;
        metadata: string;
        expiresOn?: string | null;
        printNumber?: number | null;
        cancelled?: string | null;
        created: string;
        invoiceeName: string;
        isCourtsiteOwned: boolean;
        items: Array<{
            uid: string;
            description: string;
            perUnitPrice: number;
            quantity: number;
            subtotal: number;
            metadata: string;
            created: string;
            relatedEntity?: {
                uid: string;
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
        payments: Array<{
            uid: string;
            created: string;
            amount: number;
            paymentType?: string | null;
            paymentMethod?: { uid: string; name: string } | null;
        }>;
    };
    organisation: {
        uid: string;
        name: string;
        metadata: string;
        isCourtsitePartner: boolean;
        services: Array<string>;
    } & {
        " $fragmentRefs"?: {
            BookingDetailsCentreFragment: BookingDetailsCentreFragment;
        };
    };
    categories: Array<{
        " $fragmentRefs"?: {
            BookingDetailsCategoriesFragment: BookingDetailsCategoriesFragment;
        };
    }>;
    addOns: Array<{ " $fragmentRefs"?: { AddOnsFragment: AddOnsFragment } }>;
};

export type PelangganInvoiceBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type PelangganInvoiceBookingsQuery = {
    bookingsByIds: Array<
        {
            uid: string;
            referenceId: string;
            startDt: string;
            endDt: string;
            metadata: string;
            service?: { uid: string; name: string; serviceMode: string } | null;
            resources: Array<{
                uid: string;
                archived?: string | null;
                resource: { uid: string; name: string };
            }>;
        } & {
            " $fragmentRefs"?: {
                BookingDetailsFragment: BookingDetailsFragment;
                BookingSummaryBookingDetailsFragment: BookingSummaryBookingDetailsFragment;
            };
        }
    >;
};

export type PelangganOnboardingQueryVariables = Exact<{ [key: string]: never }>;

export type PelangganOnboardingQuery = {
    myProfile?: {
        firebaseId: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
        gender?: Gender | null;
        dateOfBirth?: string | null;
        postCode?: string | null;
        preferredSports?: Array<string> | null;
        otherPreferredSports?: string | null;
        sportsFrequency?: number | null;
    } | null;
};

export type PelangganOnboardingSetUserProfileMutationVariables = Exact<{
    request: SetUserProfileRequest;
}>;

export type PelangganOnboardingSetUserProfileMutation = {
    setUserProfile:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InvalidValue"; message: string }
        | { __typename: "UserProfileSet"; user: { firebaseId: string } };
};

export type CapturePaymentResultQueryVariables = Exact<{
    capturePaymentWorkflowId: Scalars["ID"]["input"];
    checkoutId: Scalars["ID"]["input"];
    hasCheckoutId: Scalars["Boolean"]["input"];
}>;

export type CapturePaymentResultQuery = {
    capturePaymentStateById: {
        id: string;
        invoiceId: string;
        tenantId: string;
        completed?: string | null;
        expired?: string | null;
        cancelled?: string | null;
        created: string;
    };
    courtsiteCheckoutStateById?: { id: string; invoiceId?: string | null };
};

export type PaymentResultQueryVariables = Exact<{
    invoiceIds: Array<Scalars["String"]["input"]>;
    tenantId: Scalars["ID"]["input"];
    showInvoice: Scalars["Boolean"]["input"];
}>;

export type PaymentResultQuery = {
    myInvoices?: Array<{
        uid: string;
        total: number;
        outstandingBalance: number;
        cancelled?: string | null;
        items: Array<{
            uid: string;
            description: string;
            quantity: number;
            perUnitPrice: number;
            subtotal: number;
            metadata: string;
            created: string;
            relatedEntity?: {
                uid: string;
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
    }> | null;
    organisation: {
        uid: string;
        name: string;
        metadata: string;
        location: { latitude: number; longitude: number };
    };
};

export type PaymentResultBookingsQueryVariables = Exact<{
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type PaymentResultBookingsQuery = {
    myBookingsByIds: Array<{
        uid: string;
        startDt: string;
        endDt: string;
        metadata: string;
        referenceId: string;
        cancelled?: string | null;
        confirmed?: string | null;
        service?: {
            uid: string;
            name: string;
            serviceMode: string;
            minRescheduleNotice?: number | null;
            category?: { uid: string } | null;
        } | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
    }>;
};

export type CapturePaymentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CapturePaymentUserQuery = {
    myProfile?: {
        firebaseId: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
    } | null;
};

export type PaymentInvoiceQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    invoiceId: Scalars["ID"]["input"];
}>;

export type PaymentInvoiceQuery = {
    invoice: {
        uid: string;
        invoiceeName: string;
        invoiceePhoneNumber?: string | null;
        invoiceeEmail?: string | null;
        total: number;
        outstandingBalance: number;
        metadata: string;
        tenant: {
            uid: string;
            name: string;
            services: Array<string>;
            metadata: string;
            isCourtsitePartner: boolean;
        } & {
            " $fragmentRefs"?: {
                BookingDetailsCentreFragment: BookingDetailsCentreFragment;
            };
        };
        items: Array<{
            uid: string;
            description: string;
            perUnitPrice: number;
            quantity: number;
            subtotal: number;
            metadata: string;
            created: string;
            relatedEntity?: {
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
    };
};

export type PaymentBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type PaymentBookingsQuery = {
    bookingsByIds: Array<
        { uid: string; referenceId: string } & {
            " $fragmentRefs"?: {
                BookingDetailsFragment: BookingDetailsFragment;
                BookingSummaryBookingDetailsFragment: BookingSummaryBookingDetailsFragment;
            };
        }
    >;
    categories: Array<{
        " $fragmentRefs"?: {
            BookingDetailsCategoriesFragment: BookingDetailsCategoriesFragment;
        };
    }>;
    addOns: Array<{ " $fragmentRefs"?: { AddOnsFragment: AddOnsFragment } }>;
};

export type CapturePaymentStateByIdQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type CapturePaymentStateByIdQuery = {
    capturePaymentStateById: {
        id: string;
        tenantId: string;
        state: string;
        cancelled?: string | null;
        expired?: string | null;
        completed?: string | null;
        invoiceId: string;
    } & {
        " $fragmentRefs"?: {
            PaymentHandlerDataFragment: PaymentHandlerDataFragment;
        };
    };
};

export type ProvideCapturePaymentProviderDetailsMutationVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
    paymentProvider: PaymentProvider;
    redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
    payerDetailsName: Scalars["String"]["input"];
    payerDetailsPhoneNumber: Scalars["String"]["input"];
    payerDetailsEmail: Scalars["String"]["input"];
}>;

export type ProvideCapturePaymentProviderDetailsMutation = {
    provideCapturePaymentProviderDetails:
        | {
              __typename: "CapturePaymentProviderDetailsProvided";
              state: { id: string; runId: string; state: string } & {
                  " $fragmentRefs"?: {
                      PaymentHandlerDataFragment: PaymentHandlerDataFragment;
                  };
              };
          }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type ForceRetryCapturePaymentLinkMutationVariables = Exact<{
    request: ForceRetryCapturePaymentRequest;
}>;

export type ForceRetryCapturePaymentLinkMutation = {
    forceRetryCapturePayment:
        | {
              __typename: "CapturePaymentForceRetried";
              state: { id: string; runId: string; state: string } & {
                  " $fragmentRefs"?: {
                      PaymentHandlerDataFragment: PaymentHandlerDataFragment;
                  };
              };
          }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type PelangganCapturePaymentVerifyQueryVariables = Exact<{
    capturePaymentWorkflowId: Scalars["ID"]["input"];
}>;

export type PelangganCapturePaymentVerifyQuery = {
    capturePaymentStateById: {
        cancelled?: string | null;
        completed?: string | null;
        expired?: string | null;
        id: string;
    };
};

export type PelangganPaymentCaptureWorkflowVerifyConfirmCapturePaymentMutationVariables =
    Exact<{
        capturePaymentWorkflowId: Scalars["ID"]["input"];
        queryParams: Scalars["JSONString"]["input"];
    }>;

export type PelangganPaymentCaptureWorkflowVerifyConfirmCapturePaymentMutation =
    {
        confirmCapturePayment:
            | { __typename: "CapturePaymentConfirmed"; state: { id: string } }
            | { __typename: "WorkflowCancelled"; message: string }
            | { __typename: "WorkflowNotFound"; message: string }
            | { __typename: "WorkflowNotReady"; message: string };
    };

export type CapturePaymentVerificationSPayQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type CapturePaymentVerificationSPayQuery = {
    capturePaymentStateById: {
        id: string;
        created: string;
        cancelled?: string | null;
        completed?: string | null;
        expired?: string | null;
        amountToCapture?: number | null;
        spayQrCode?: string | null;
        paymentSessionCreated?: string | null;
    };
};

export type PelangganRescheduleAddonsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type PelangganRescheduleAddonsQuery = {
    addOns: Array<{ uid: string; name: string }>;
};

export type PelangganRescheduleBookingBookingDetailsQueryVariables = Exact<{
    bookingIds: Array<Scalars["ID"]["input"]>;
    relatedEntityIds: Array<InputMaybe<Scalars["String"]["input"]>>;
}>;

export type PelangganRescheduleBookingBookingDetailsQuery = {
    myBookingsByIds: Array<{
        uid: string;
        startDt: string;
        endDt: string;
        created: string;
        metadata: string;
        referenceId: string;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
        tenant: { uid: string; name: string };
        service?: {
            uid: string;
            name: string;
            serviceMode: string;
            category?: { uid: string; name: string; metadata: string } | null;
        } | null;
        addons?: Array<{
            uid: string;
            addonId: string;
            perUnitPrice: number;
            quantity: number;
        } | null> | null;
    }>;
    myInvoices?: Array<{
        uid: string;
        metadata: string;
        items: Array<{
            uid: string;
            description: string;
            perUnitPrice: number;
            quantity: number;
            subtotal: number;
            created: string;
            metadata: string;
            relatedEntity?: {
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
    }> | null;
};

export type PelangganRescheduleStateByIdResultQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type PelangganRescheduleStateByIdResultQuery = {
    courtsiteRescheduleStateById: {
        id: string;
        oldBookingId: string;
        created: string;
        completed?: string | null;
        cancelled?: string | null;
        expired?: string | null;
        priceDifference?: number | null;
        capturePaymentState?: {
            id: string;
            completed?: string | null;
            expired?: string | null;
            cancelled?: string | null;
            created: string;
        } | null;
    };
};

export type ProvideCourtsiteReschedulePaymentDetailsMutationVariables = Exact<{
    request: ProvideCourtsiteReschedulePaymentDetailsRequest;
}>;

export type ProvideCourtsiteReschedulePaymentDetailsMutation = {
    provideCourtsiteReschedulePaymentDetails:
        | { __typename: "CourtsiteRescheduleExpired"; message: string }
        | {
              __typename: "CourtsiteReschedulePaymentDetailsProvided";
              state: {
                  id: string;
                  runId: string;
                  state: string;
                  completed?: string | null;
                  expired?: string | null;
                  cancelled?: string | null;
                  created: string;
                  priceDifference?: number | null;
                  newBookingId?: string | null;
                  newInvoiceId?: string | null;
                  redirectUrl?: string | null;
                  capturePaymentWorkflowId?: string | null;
                  capturePaymentState?:
                      | ({ id: string; runId: string; state: string } & {
                            " $fragmentRefs"?: {
                                PaymentHandlerDataFragment: PaymentHandlerDataFragment;
                            };
                        })
                      | null;
              };
          }
        | {
              __typename: "InvalidCourtsiteReschedulePaymentDetailsRequest";
              message: string;
          }
        | { __typename: "WorkflowCancelled"; message: string }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type PelangganRescheduleCheckoutForceRetryPaymentMutationVariables =
    Exact<{
        request: ForceRetryCapturePaymentRequest;
    }>;

export type PelangganRescheduleCheckoutForceRetryPaymentMutation = {
    forceRetryCapturePayment:
        | {
              __typename: "CapturePaymentForceRetried";
              state: {
                  id: string;
                  runId: string;
                  state: string;
                  amountToCapture?: number | null;
              } & {
                  " $fragmentRefs"?: {
                      PaymentHandlerDataFragment: PaymentHandlerDataFragment;
                  };
              };
          }
        | { __typename: "WorkflowNotFound"; message: string }
        | { __typename: "WorkflowNotReady"; message: string };
};

export type PelangganRescheduleStateByIdPaymentQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type PelangganRescheduleStateByIdPaymentQuery = {
    courtsiteRescheduleStateById: {
        id: string;
        oldBookingId: string;
        newBookingId?: string | null;
        newInvoiceId?: string | null;
        created: string;
        completed?: string | null;
        cancelled?: string | null;
        expired?: string | null;
        priceDifference?: number | null;
        state: string;
        capturePaymentState?:
            | ({
                  id: string;
                  state: string;
                  completed?: string | null;
                  expired?: string | null;
                  cancelled?: string | null;
                  created: string;
              } & {
                  " $fragmentRefs"?: {
                      PaymentHandlerDataFragment: PaymentHandlerDataFragment;
                  };
              })
            | null;
    };
};

export type BookingFieldsReschedulePaymentFragment = {
    uid: string;
    startDt: string;
    endDt: string;
    referenceId: string;
    tenantId: string;
    metadata: string;
    service?: {
        uid: string;
        name: string;
        serviceMode: string;
        minRescheduleNotice?: number | null;
        category?: { uid: string; name: string; metadata: string } | null;
    } | null;
    resources: Array<{
        uid: string;
        archived?: string | null;
        resource: { uid: string; name: string };
    }>;
} & { " $fragmentName"?: "BookingFieldsReschedulePaymentFragment" };

export type PelangganRescheduleCheckoutPaymentBookingQueryVariables = Exact<{
    oldBookingId: Scalars["ID"]["input"];
    newBookingId: Scalars["ID"]["input"];
}>;

export type PelangganRescheduleCheckoutPaymentBookingQuery = {
    oldBooking: {
        " $fragmentRefs"?: {
            BookingFieldsReschedulePaymentFragment: BookingFieldsReschedulePaymentFragment;
        };
    };
    newBooking: {
        " $fragmentRefs"?: {
            BookingFieldsReschedulePaymentFragment: BookingFieldsReschedulePaymentFragment;
        };
    };
};

export type RescheduleCheckoutPaymentOrgQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type RescheduleCheckoutPaymentOrgQuery = {
    organisation: {
        uid: string;
        name: string;
        metadata: string;
        isCourtsitePartner: boolean;
    };
    onlineServices: Array<{
        uid: string;
        serviceCategory?: { uid: string; categoryId: string } | null;
    }>;
};

export type PelangganRescheduleStateByIdSuccessQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type PelangganRescheduleStateByIdSuccessQuery = {
    courtsiteRescheduleStateById: {
        id: string;
        newBookingId?: string | null;
        newInvoiceId?: string | null;
        created: string;
        completed?: string | null;
        cancelled?: string | null;
        expired?: string | null;
        priceDifference?: number | null;
        capturePaymentState?: {
            id: string;
            tenantId: string;
            completed?: string | null;
            expired?: string | null;
            cancelled?: string | null;
            created: string;
        } | null;
    };
};

export type PelangganRescheduleCheckoutSuccessQueryVariables = Exact<{
    bookingId: Scalars["ID"]["input"];
    invoiceId: Scalars["ID"]["input"];
}>;

export type PelangganRescheduleCheckoutSuccessQuery = {
    myBooking: {
        uid: string;
        startDt: string;
        endDt: string;
        tenantId: string;
        referenceId: string;
        cancelled?: string | null;
        confirmed?: string | null;
        metadata: string;
        service?: {
            uid: string;
            name: string;
            serviceMode: string;
            minRescheduleNotice?: number | null;
            category?: { uid: string; name: string } | null;
        } | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
    };
    myInvoice: {
        uid: string;
        total: number;
        outstandingBalance: number;
        cancelled?: string | null;
        items: Array<{
            uid: string;
            created: string;
            description: string;
            perUnitPrice: number;
            quantity: number;
            subtotal: number;
            metadata: string;
            relatedEntity?: {
                uid: string;
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
    };
};

export type RescheduleCheckoutSuccessOrgQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type RescheduleCheckoutSuccessOrgQuery = {
    organisation: {
        uid: string;
        name: string;
        metadata: string;
        location: { latitude: number; longitude: number };
    };
};

export type PelangganRescheduleStateByIdVerifyQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type PelangganRescheduleStateByIdVerifyQuery = {
    courtsiteRescheduleStateById: {
        id: string;
        completed?: string | null;
        cancelled?: string | null;
        priceDifference?: number | null;
        capturePaymentState?: {
            id: string;
            completed?: string | null;
            expired?: string | null;
            cancelled?: string | null;
        } | null;
    };
};

export type PelangganRescheduleCaptureWorkflowVerifyConfirmCapturePaymentMutationVariables =
    Exact<{
        workflowId: Scalars["ID"]["input"];
        queryParams: Scalars["JSONString"]["input"];
    }>;

export type PelangganRescheduleCaptureWorkflowVerifyConfirmCapturePaymentMutation =
    {
        confirmCapturePayment:
            | { __typename: "CapturePaymentConfirmed"; state: { id: string } }
            | { __typename: "WorkflowCancelled"; message: string }
            | { __typename: "WorkflowNotFound"; message: string }
            | { __typename: "WorkflowNotReady"; message: string };
    };

export type CourtsiteRescheduleStateByIdSpayQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type CourtsiteRescheduleStateByIdSpayQuery = {
    courtsiteRescheduleStateById: {
        id: string;
        completed?: string | null;
        expired?: string | null;
        cancelled?: string | null;
        created: string;
        capturePaymentState?: {
            id: string;
            created: string;
            cancelled?: string | null;
            completed?: string | null;
            expired?: string | null;
            amountToCapture?: number | null;
            spayQrCode?: string | null;
            paymentSessionCreated?: string | null;
        } | null;
    };
};

export type PelangganRescheduleBookingInvoiceDetailsQueryVariables = Exact<{
    bookingIds: Array<Scalars["ID"]["input"]>;
    relatedEntityIds: Array<InputMaybe<Scalars["String"]["input"]>>;
}>;

export type PelangganRescheduleBookingInvoiceDetailsQuery = {
    myBookingsByIds: Array<{
        uid: string;
        startDt: string;
        endDt: string;
        created: string;
        confirmed?: string | null;
        cancelled?: string | null;
        metadata: string;
        referenceId: string;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
        tenant: { uid: string; name: string; metadata: string };
        service?: {
            uid: string;
            name: string;
            serviceMode: string;
            minRescheduleNotice?: number | null;
            category?: { uid: string; name: string } | null;
        } | null;
        addons?: Array<{
            uid: string;
            addonId: string;
            perUnitPrice: number;
            quantity: number;
        } | null> | null;
    }>;
    myInvoices?: Array<{
        uid: string;
        printNumber?: number | null;
        outstandingBalance: number;
        metadata: string;
        cancelled?: string | null;
        associatedUsers: Array<{ userId: string }>;
        items: Array<{
            uid: string;
            subtotal: number;
            description: string;
            perUnitPrice: number;
            quantity: number;
            created: string;
            metadata: string;
            relatedEntity?: {
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
    }> | null;
};

export type RescheduleAddonsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type RescheduleAddonsQuery = {
    addOns: Array<{ uid: string; name: string }>;
};

export type PelangganSearchPageQueryVariables = Exact<{ [key: string]: never }>;

export type PelangganSearchPageQuery = {
    categories: Array<
        { uid: string; name: string } & {
            " $fragmentRefs"?: {
                FeatureVenueCategoriesFragment: FeatureVenueCategoriesFragment;
                CategoryListCategoriesFragment: CategoryListCategoriesFragment;
                SearchBarCategoriesFragment: SearchBarCategoriesFragment;
            };
        }
    >;
    locations: Array<
        {
            uid: string;
            name: string;
            city?: string | null;
            coordinates: { latitude: number; longitude: number };
        } & {
            " $fragmentRefs"?: {
                SearchBarLocationsFragment: SearchBarLocationsFragment;
            };
        }
    >;
    courtsitePartners: Array<
        { uid: string; name: string } & {
            " $fragmentRefs"?: {
                CategoryListVenuesFragment: CategoryListVenuesFragment;
                SearchBarVenuesFragment: SearchBarVenuesFragment;
            };
        }
    >;
};

export type PelangganSearchResultPageQueryVariables = Exact<{
    [key: string]: never;
}>;

export type PelangganSearchResultPageQuery = {
    categories: Array<{
        " $fragmentRefs"?: {
            SearchBarCategoriesFragment: SearchBarCategoriesFragment;
            SearchMobileCategoriesFragment: SearchMobileCategoriesFragment;
        };
    }>;
    locations: Array<{
        " $fragmentRefs"?: {
            SearchBarLocationsFragment: SearchBarLocationsFragment;
            SearchMobileLocationsFragment: SearchMobileLocationsFragment;
        };
    }>;
    courtsitePartners: Array<{
        " $fragmentRefs"?: {
            SearchBarVenuesFragment: SearchBarVenuesFragment;
            SearchMobileVenuesFragment: SearchMobileVenuesFragment;
        };
    }>;
};

export type PelangganShareInvoicePageDataQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type PelangganShareInvoicePageDataQuery = {
    organisation: {
        uid: string;
        name: string;
        metadata: string;
        isCourtsitePartner: boolean;
        location: { latitude: number; longitude: number };
    };
    categories: Array<{ uid: string; name: string; metadata: string }>;
    myProfile?: {
        firebaseId: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
    } | null;
};

export type InvoiceSharePageQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    uid: Scalars["ID"]["input"];
}>;

export type InvoiceSharePageQuery = {
    invoice: {
        uid: string;
        invoiceeName: string;
        metadata: string;
        outstandingBalance: number;
        cancelled?: string | null;
        associatedUsers: Array<{ userId: string }>;
        items: Array<{
            uid: string;
            description: string;
            quantity: number;
            perUnitPrice: number;
            subtotal: number;
            metadata: string;
            relatedEntity?: {
                uid: string;
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
    };
};

export type AddBookingGuestToBookingsDetailsMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
    guestName: Scalars["String"]["input"];
    phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
    email?: InputMaybe<Scalars["EmailAddress"]["input"]>;
    userId: Scalars["ID"]["input"];
}>;

export type AddBookingGuestToBookingsDetailsMutation = {
    addBookingGuestToBookings:
        | { __typename: "AccessError"; message: string }
        | {
              __typename: "BookingGuestAddedToBookings";
              bookingIds: Array<string>;
          }
        | { __typename: "NotFoundError"; message: string };
};

export type RemoveBookingGuestToBookingDetailsMutationVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
    userId: Scalars["ID"]["input"];
}>;

export type RemoveBookingGuestToBookingDetailsMutation = {
    removeBookingGuest:
        | { __typename: "AccessError"; message: string }
        | { __typename: "BookingGuestRemoved"; bookingIds: Array<string> }
        | { __typename: "NotFoundError"; message: string };
};

export type UserBookingsCountQueryVariables = Exact<{ [key: string]: never }>;

export type UserBookingsCountQuery = {
    myBookingFilterCount: Array<{ categoryId: string; bookingCount: number }>;
    categories: Array<{ uid: string; name: string }>;
};

export type PelangganUserBookingsDetailsNewQueryVariables = Exact<{
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type PelangganUserBookingsDetailsNewQuery = {
    myBookingsByIds: Array<{
        uid: string;
        tenantId: string;
        referenceId: string;
        startDt: string;
        endDt: string;
        confirmed?: string | null;
        cancelled?: string | null;
        cancelledReason?: CancelledReason | null;
        metadata: string;
        bookingSource?: BookingSource | null;
        service?: {
            uid: string;
            serviceMode: string;
            minRescheduleNotice?: number | null;
            category?: { uid: string; name: string } | null;
        } | null;
        resources: Array<{
            uid: string;
            archived?: string | null;
            resource: { uid: string; name: string };
        }>;
        guests?: Array<{ userId?: string | null } | null> | null;
    }>;
    myInvoicesByRelatedEntityIds?: Array<{
        uid: string;
        tenantId: string;
        printNumber?: number | null;
        total: number;
        outstandingBalance: number;
        cancelled?: string | null;
        created: string;
    }> | null;
};

export type PelangganUserBookingsDetailsOrgQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
}>;

export type PelangganUserBookingsDetailsOrgQuery = {
    organisation: {
        uid: string;
        name: string;
        metadata: string;
        location: { latitude: number; longitude: number };
    };
};

export type PelangganUserBookingsDetailsWebCheckoutQueryVariables = Exact<{
    workflowId: Scalars["ID"]["input"];
}>;

export type PelangganUserBookingsDetailsWebCheckoutQuery = {
    courtsiteCheckoutStateById: {
        id: string;
        cancelled?: string | null;
        expired?: string | null;
        created: string;
        state: string;
    };
};

export type PelangganUserBookingsDetailsWebCheckoutRescheduleQueryVariables =
    Exact<{
        workflowId: Scalars["ID"]["input"];
    }>;

export type PelangganUserBookingsDetailsWebCheckoutRescheduleQuery = {
    courtsiteRescheduleStateById: {
        id: string;
        created: string;
        cancelled?: string | null;
        expired?: string | null;
        state: string;
    };
};

export type UserGamesCountQueryVariables = Exact<{ [key: string]: never }>;

export type UserGamesCountQuery = {
    myGameFilterCount: Array<{ categoryId: string; gameCount: number }>;
    categories: Array<{ uid: string; name: string }>;
};

export type GetUserProfileQueryVariables = Exact<{
    startOfYear?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type GetUserProfileQuery = {
    myProfile?: {
        firebaseId: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
        gender?: Gender | null;
        dateOfBirth?: string | null;
        postCode?: string | null;
        joined?: string | null;
        preferredSports?: Array<string> | null;
        otherPreferredSports?: string | null;
        sportsFrequency?: number | null;
    } | null;
    categories: Array<{ uid: string; name: string }>;
    myInvoiceStatistics: { invoicePerYear: number };
    myGameStatistics: { gamesMade: number };
    myBookingStatistics: { bookingsHours: number; bookingsMade: number };
};

export type GetMyDetailsUpcomingBookingQueryVariables = Exact<{
    now?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type GetMyDetailsUpcomingBookingQuery = {
    myGroupedBookings: {
        edges: Array<{
            node: {
                referenceId: string;
                startDt: string;
                bookings: Array<{
                    uid: string;
                    created: string;
                    tenantId: string;
                    startDt: string;
                    endDt: string;
                    confirmed?: string | null;
                    cancelled?: string | null;
                    metadata: string;
                    service: {
                        uid: string;
                        serviceMode: string;
                        categoryName: string;
                    };
                    resources: Array<{
                        resourceId: string;
                        resourceName: string;
                    }>;
                }>;
            };
        }>;
    };
    myGames: {
        edges: Array<{
            node: {
                name: string;
                startDt: string;
                endDt: string;
                archived?: string | null;
                categoryId: string;
                metadata: string;
                uid: string;
                maxPlayers: number;
                reservedPlayers?: number | null;
                location: { tenantId: string };
                players?: Array<{ displayPicture?: string | null }> | null;
                organizer: { organizerId: string; name: string };
            };
        }>;
    };
};

export type GetMyDetailsLastBookingQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetMyDetailsLastBookingQuery = {
    myGroupedBookings: {
        edges: Array<{
            node: {
                referenceId: string;
                startDt: string;
                bookings: Array<{
                    uid: string;
                    created: string;
                    tenantId: string;
                    startDt: string;
                    endDt: string;
                    confirmed?: string | null;
                    cancelled?: string | null;
                    metadata: string;
                    service: {
                        uid: string;
                        serviceMode: string;
                        categoryName: string;
                    };
                    resources: Array<{
                        resourceId: string;
                        resourceName: string;
                    }>;
                }>;
            };
        }>;
    };
};

export type GetMyDetailsLastGameQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetMyDetailsLastGameQuery = {
    myGames: {
        edges: Array<{
            node: {
                name: string;
                startDt: string;
                endDt: string;
                archived?: string | null;
                categoryId: string;
                metadata: string;
                uid: string;
                maxPlayers: number;
                reservedPlayers?: number | null;
                location: { tenantId: string };
                players?: Array<{ displayPicture?: string | null }> | null;
                organizer: { organizerId: string; name: string };
            };
        }>;
    };
};

export type PelangganUserInvoiceQueryVariables = Exact<{
    invoiceId: Scalars["ID"]["input"];
}>;

export type PelangganUserInvoiceQuery = {
    myInvoice: {
        uid: string;
        total: number;
        outstandingBalance: number;
        amountPaid: number;
        cancelled?: string | null;
        created: string;
        metadata: string;
        invoiceeName: string;
        tenantId: string;
        printNumber?: number | null;
        isCourtsiteOwned: boolean;
        tenant: { uid: string; name: string; metadata: string };
        items: Array<{
            uid: string;
            description: string;
            perUnitPrice: number;
            quantity: number;
            subtotal: number;
            metadata: string;
            created: string;
            relatedEntity?: {
                uid: string;
                entityId: string;
                entityType: InvoiceItemRelatedEntityType;
            } | null;
        }>;
        payments: Array<{
            uid: string;
            created: string;
            cancelled?: string | null;
            amount: number;
            paymentType?: string | null;
            paymentMethod?: { uid: string; name: string } | null;
        }>;
    };
};

export type PelangganUserInvoiceSubmissionQueryVariables = Exact<{
    invoiceId: Scalars["ID"]["input"];
}>;

export type PelangganUserInvoiceSubmissionQuery = {
    lhdnSubmissionByInvoiceId?: {
        uid: string;
        status: LhdnSubmissionStatus;
    } | null;
};

export type PelangganUserInvoiceBookingsQueryVariables = Exact<{
    tenantId: Scalars["ID"]["input"];
    bookingIds: Array<Scalars["ID"]["input"]>;
}>;

export type PelangganUserInvoiceBookingsQuery = {
    bookingsByIds: Array<
        {
            uid: string;
            referenceId: string;
            startDt: string;
            endDt: string;
            metadata: string;
            service?: { uid: string; name: string; serviceMode: string } | null;
            resources: Array<{
                uid: string;
                archived?: string | null;
                resource: { uid: string; name: string };
            }>;
        } & {
            " $fragmentRefs"?: {
                BookingDetailsFragment: BookingDetailsFragment;
                BookingSummaryBookingDetailsFragment: BookingSummaryBookingDetailsFragment;
            };
        }
    >;
    organisation: {
        uid: string;
        name: string;
        metadata: string;
        services: Array<string>;
    } & {
        " $fragmentRefs"?: {
            BookingDetailsCentreFragment: BookingDetailsCentreFragment;
        };
    };
    categories: Array<
        { uid: string; name: string } & {
            " $fragmentRefs"?: {
                BookingDetailsCategoriesFragment: BookingDetailsCategoriesFragment;
            };
        }
    >;
    addOns: Array<{ " $fragmentRefs"?: { AddOnsFragment: AddOnsFragment } }>;
};

export type ValidateTaxpayerTinQueryVariables = Exact<{
    tin: Scalars["String"]["input"];
    idType: IdType;
    idValue: Scalars["String"]["input"];
}>;

export type ValidateTaxpayerTinQuery = {
    validateTaxpayerTin?:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InternalError"; message: string }
        | {
              __typename: "TaxPayerTINValidated";
              tin: string;
              idType: IdType;
              idValue: string;
          }
        | null;
};

export type InvoiceLhdnRequestQueryVariables = Exact<{
    invoiceId: Scalars["ID"]["input"];
}>;

export type InvoiceLhdnRequestQuery = {
    myInvoice: {
        uid: string;
        tenantId: string;
        total: number;
        outstandingBalance: number;
        payments: Array<{
            uid: string;
            created: string;
            cancelled?: string | null;
            paymentType?: string | null;
            paymentMethod?: { uid: string; name: string } | null;
        }>;
    };
};

export type PelangganStartEInvoiceSubmissionMutationVariables = Exact<{
    req: StartSubmitLhdneInvoicesRequest;
}>;

export type PelangganStartEInvoiceSubmissionMutation = {
    startSubmitLHDNEInvoices:
        | { __typename: "SubmitLHDNEInvoicesStarted" }
        | { __typename: "WorkflowCancelled" }
        | { __typename: "WorkflowNotReady" };
};

export type PelangganUserInvoicesQueryVariables = Exact<{
    status?: InputMaybe<InvoiceStatus>;
    cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PelangganUserInvoicesQuery = {
    myInvoicesPaged?: {
        edges: Array<{
            node: { uid: string; created: string } & {
                " $fragmentRefs"?: {
                    UserInvoicesDetailsFragment: UserInvoicesDetailsFragment;
                };
            };
        }>;
        pageInfo: { hasNextPage?: boolean | null; endCursor?: string | null };
    } | null;
};

export type PelangganUserInvoiceStatisticsQueryVariables = Exact<{
    startOfYear?: InputMaybe<Scalars["DateTime"]["input"]>;
    pastStartOfYear?: InputMaybe<Scalars["DateTime"]["input"]>;
    pastEndOfYear?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type PelangganUserInvoiceStatisticsQuery = {
    currentYear: { invoicePerYear: number };
    pastYear: { invoicePerYear: number };
};

export type UserInvoicesDetailsFragment = {
    uid: string;
    outstandingBalance: number;
    total: number;
    created: string;
    tenant: { uid: string; name: string };
} & { " $fragmentName"?: "UserInvoicesDetailsFragment" };

export type PelangganUserProfileSetUserProfileMutationVariables = Exact<{
    request: SetUserProfileRequest;
}>;

export type PelangganUserProfileSetUserProfileMutation = {
    setUserProfile:
        | { __typename: "AccessError"; message: string }
        | { __typename: "InvalidValue"; message: string }
        | { __typename: "UserProfileSet"; user: { firebaseId: string } };
};

export type PelangganUserProfileQueryVariables = Exact<{
    [key: string]: never;
}>;

export type PelangganUserProfileQuery = {
    myProfile?: {
        firebaseId: string;
        name: string;
        phoneNumber?: string | null;
        email?: string | null;
        gender?: Gender | null;
        dateOfBirth?: string | null;
        postCode?: string | null;
        preferredSports?: Array<string> | null;
        otherPreferredSports?: string | null;
        sportsFrequency?: number | null;
    } | null;
    categories: Array<{ uid: string; name: string }>;
};

export type PelangganUserUpcomingCategoriesQueryVariables = Exact<{
    [key: string]: never;
}>;

export type PelangganUserUpcomingCategoriesQuery = {
    categories: Array<{ uid: string; name: string }>;
};

export type BookAgainSectionUpcomingQueryQueryVariables = Exact<{
    limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type BookAgainSectionUpcomingQueryQuery = {
    tenantIdsFromPastBookings?: Array<string | null> | null;
    courtsitePartners: Array<{ uid: string; name: string; metadata: string }>;
};

export type UserUpcomingBookingsInvoicesQueryVariables = Exact<{
    filter?: InputMaybe<MyInvoicesFilter>;
}>;

export type UserUpcomingBookingsInvoicesQuery = {
    myInvoices?: Array<{
        uid: string;
        items: Array<{
            uid: string;
            relatedEntity?: { uid: string; entityId: string } | null;
        }>;
    }> | null;
};

export type UserUpcomingBookingCountQueryVariables = Exact<{
    startsAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type UserUpcomingBookingCountQuery = {
    myBookingFilterCount: Array<{ bookingCount: number; categoryId: string }>;
};

export type UserUpcomingGameCountQueryVariables = Exact<{
    startsAfter?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type UserUpcomingGameCountQuery = {
    myGameFilterCount: Array<{ gameCount: number; categoryId: string }>;
};

export const AddOnsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "AddOns" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "AddOn" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddOnsFragment, unknown>;
export const BookingDetailsCategoriesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetailsCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingDetailsCategoriesFragment, unknown>;
export const BookingDetailsCentreFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetailsCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "isCourtsitePartner" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingDetailsCentreFragment, unknown>;
export const BookingDetailsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "startDt" } },
                    { kind: "Field", name: { kind: "Name", value: "endDt" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "service" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resource" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addons" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addonId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "perUnitPrice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingDetailsFragment, unknown>;
export const BookingSummaryBookingDetailsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingSummaryBookingDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addons" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "perUnitPrice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingSummaryBookingDetailsFragment, unknown>;
export const FeatureVenueCategoriesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FeatureVenueCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FeatureVenueCategoriesFragment, unknown>;
export const FooterBookingDetailsCentreFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FooterBookingDetailsCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FooterBookingDetailsCentreFragment, unknown>;
export const FooterBookingDetailsCategoriesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FooterBookingDetailsCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FooterBookingDetailsCategoriesFragment, unknown>;
export const LocationAccordionOptionFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "LocationAccordionOption" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "SearchLocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "coordinates" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latitude" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "longitude" },
                                },
                            ],
                        },
                    },
                    { kind: "Field", name: { kind: "Name", value: "city" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LocationAccordionOptionFragment, unknown>;
export const CategoryListCategoriesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CategoryListCategoriesFragment, unknown>;
export const SearchBarCategoriesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListCategories" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchBarCategoriesFragment, unknown>;
export const SearchBarLocationsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarLocations" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "SearchLocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "city" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "coordinates" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latitude" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "longitude" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchBarLocationsFragment, unknown>;
export const CategoryListVenuesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CategoryListVenuesFragment, unknown>;
export const SearchBarVenuesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListVenues" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchBarVenuesFragment, unknown>;
export const SearchMobileCategoriesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchMobileCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListCategories" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchMobileCategoriesFragment, unknown>;
export const SearchMobileLocationsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchMobileLocations" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "SearchLocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "city" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "coordinates" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latitude" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "longitude" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchMobileLocationsFragment, unknown>;
export const SearchMobileVenuesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchMobileVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListVenues" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchMobileVenuesFragment, unknown>;
export const BookingFieldsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "bookingFields" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "startDt" } },
                    { kind: "Field", name: { kind: "Name", value: "endDt" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "referenceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "service" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minRescheduleNotice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resource" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingFieldsFragment, unknown>;
export const PaymentHandlerDataFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PaymentHandlerDataFragment, unknown>;
export const SPayDataCheckoutFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SPayDataCheckout" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentSessionCreated" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SPayDataCheckoutFragment, unknown>;
export const VenueCardOrgFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "VenueCardOrg" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<VenueCardOrgFragment, unknown>;
export const ExploreCategoryCentreFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "ExploreCategoryCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ExploreCategoryCentreFragment, unknown>;
export const SocialGamesCategoriesFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SocialGamesCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SocialGamesCategoriesFragment, unknown>;
export const BookingFieldsReschedulePaymentFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "bookingFieldsReschedulePayment" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "startDt" } },
                    { kind: "Field", name: { kind: "Name", value: "endDt" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "referenceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "service" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minRescheduleNotice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resource" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingFieldsReschedulePaymentFragment, unknown>;
export const UserInvoicesDetailsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "UserInvoicesDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Invoice" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "outstandingBalance" },
                    },
                    { kind: "Field", name: { kind: "Name", value: "total" } },
                    { kind: "Field", name: { kind: "Name", value: "created" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "tenant" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserInvoicesDetailsFragment, unknown>;
export const UnavailableSlotsServiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "unavailableSlotsService" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dtRange" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateRangeFilter" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isLoggedIn" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "unavailableResourceSlotsBetween",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "categoryId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "dtRange" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "dtRange" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resourceId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCategoryId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceTags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myMemberServiceIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: { kind: "Name", value: "include" },
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "if" },
                                        value: {
                                            kind: "Variable",
                                            name: {
                                                kind: "Name",
                                                value: "isLoggedIn",
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UnavailableSlotsServiceQuery,
    UnavailableSlotsServiceQueryVariables
>;
export const UnavailableSlotsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "unavailableSlots" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dtRange" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateRangeFilter" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "unavailableResourceSlotsBetween",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "categoryId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "dtRange" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "dtRange" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resourceId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCategoryId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UnavailableSlotsQuery,
    UnavailableSlotsQueryVariables
>;
export const PelangganAvailabilityViewDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganAvailabilityView" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isLoggedIn" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minBookingWindowMinutes",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingWindow",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "customDurationOptions",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "disallowBookingGap",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceTags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myMemberServiceIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: { kind: "Name", value: "include" },
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "if" },
                                        value: {
                                            kind: "Variable",
                                            name: {
                                                kind: "Name",
                                                value: "isLoggedIn",
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganAvailabilityViewQuery,
    PelangganAvailabilityViewQueryVariables
>;
export const PelangganAvailabilityViewUnavailabilitiesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganAvailabilityViewUnavailabilities",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "dtRange" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateRangeFilter" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "unavailableResourceSlotsBetween",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "categoryId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "dtRange" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "dtRange" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resourceId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganAvailabilityViewUnavailabilitiesQuery,
    PelangganAvailabilityViewUnavailabilitiesQueryVariables
>;
export const PelangganAvailabilityViewFixedDurationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganAvailabilityViewFixedDuration",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "serviceIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "start" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "end" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "fixedDurationOptionsStartingBetween",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "serviceIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "serviceIds" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "start" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "start" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "end" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "end" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "end",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganAvailabilityViewFixedDurationQuery,
    PelangganAvailabilityViewFixedDurationQueryVariables
>;
export const PelangganAvailabilityViewDurationFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganAvailabilityViewDurationForm",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "req" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "ServiceAvailabilityRequest",
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "quotReq" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "QuotationRequest",
                                    },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceAvailabilities" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "req" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isAvailable",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "reason" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "quotations" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "quotReq" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "deposit" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "remainder" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "price" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "satisfiesMinimumDuration",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timeFullyAccounted",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganAvailabilityViewDurationFormQuery,
    PelangganAvailabilityViewDurationFormQueryVariables
>;
export const BookingCardDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "bookingCard" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BookingCardQuery, BookingCardQueryVariables>;
export const ReattemptBookingCardDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "reattemptBookingCard" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ReattemptBookingCardQuery,
    ReattemptBookingCardQueryVariables
>;
export const ReattemptBookingCardCheckoutStateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "reattemptBookingCardCheckoutState" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteCheckoutStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "checkoutId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "FragmentSpread",
                                                name: {
                                                    kind: "Name",
                                                    value: "PaymentHandlerData",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ReattemptBookingCardCheckoutStateQuery,
    ReattemptBookingCardCheckoutStateQueryVariables
>;
export const ActivateLightEventsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "activateLightEvents" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ActivateLightEventsRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "activateLightEvents" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "LightEventsActivated",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "groupActivations",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "created",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "groupId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "tenant",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "metadata",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "isCourtsitePartner",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "services",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "tenantId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ActivateLightEventsMutation,
    ActivateLightEventsMutationVariables
>;
export const PelangganUserBookingsCardWebCheckoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganUserBookingsCardWebCheckout",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteCheckoutStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "checkoutId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "hasDeposit" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserBookingsCardWebCheckoutQuery,
    PelangganUserBookingsCardWebCheckoutQueryVariables
>;
export const PelangganUserBookingsCardWebCheckoutRescheduleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganUserBookingsCardWebCheckoutReschedule",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteRescheduleStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserBookingsCardWebCheckoutRescheduleQuery,
    PelangganUserBookingsCardWebCheckoutRescheduleQueryVariables
>;
export const BottomBarProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "bottomBarProfile" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BottomBarProfileQuery,
    BottomBarProfileQueryVariables
>;
export const JoinGameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "joinGame" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "gameID" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "displayPicture" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "EmailAddress" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PhoneNumber" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "joinGame" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "uid",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "gameID",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "playerDetails",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "displayPicture",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "displayPicture",
                                                            },
                                                        },
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "email",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "email",
                                                            },
                                                        },
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "name",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "phoneNumber",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "phoneNumber",
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NoPlayerSlotError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<JoinGameMutation, JoinGameMutationVariables>;
export const LeaveGameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "leaveGame" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "gameID" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "leaveGame" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "uid",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "gameID",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LeaveGameMutation, LeaveGameMutationVariables>;
export const DesktopNavProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "desktopNavProfile" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DesktopNavProfileQuery,
    DesktopNavProfileQueryVariables
>;
export const DownloadInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "downloadInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "invoiceId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "invoiceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeName",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "printNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsiteOwned",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenant" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "payments" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentType",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethod",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DownloadInvoiceQuery,
    DownloadInvoiceQueryVariables
>;
export const DownloadInvoiceBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "downloadInvoiceBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myBookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DownloadInvoiceBookingsQuery,
    DownloadInvoiceBookingsQueryVariables
>;
export const FeatureVenueOrganisationsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "featureVenueOrganisations" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: {
                            kind: "Name",
                            value: "ApprovedOrganisationsFilter",
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cursor" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "approvedOrganisations" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sortOrder" },
                                value: {
                                    kind: "EnumValue",
                                    value: "BY_WEIGHTAGE_DESC",
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "6" },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cursor" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "services",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    FeatureVenueOrganisationsQuery,
    FeatureVenueOrganisationsQueryVariables
>;
export const CreateUserProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "createUserProfile" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "EmailAddress" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PhoneNumber" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "email",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "phoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InvalidValue",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateUserProfileMutation,
    CreateUserProfileMutationVariables
>;
export const BookingCategorySelectDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "bookingCategorySelect" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minBookingWindowMinutes",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingWindow",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "disallowBookingGap",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "customDurationOptions",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "price" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isInStock" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myMemberServiceIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceTags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BookingCategorySelectQuery,
    BookingCategorySelectQueryVariables
>;
export const PelangganBookingFormOnlineServicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganBookingFormOnlineServices" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCategoryId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "linkedResources",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minBookingWindowMinutes",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingWindow",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "disallowBookingGap",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "customDurationOptions",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganBookingFormOnlineServicesQuery,
    PelangganBookingFormOnlineServicesQueryVariables
>;
export const BookingSelectStartDurationSelectDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "bookingSelectStartDurationSelect" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "serviceIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startDt" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endDt" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minBookingWindowMinutes",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "disallowBookingGap",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "customDurationOptions",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "fixedDurationOptionsStartingBetween",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "serviceIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "serviceIds" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "start" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "startDt" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "end" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "endDt" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "start",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "end",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "unavailableResourceSlotsBetween",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "categoryId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "dtRange" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "startDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "startDt",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "endDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "endDt",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resourceId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BookingSelectStartDurationSelectQuery,
    BookingSelectStartDurationSelectQueryVariables
>;
export const DailyBookingStepsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "dailyBookingSteps" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "linkedResources",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minBookingWindowMinutes",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingWindow",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "disallowBookingGap",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DailyBookingStepsQuery,
    DailyBookingStepsQueryVariables
>;
export const BookingSelectAvailabilityDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "bookingSelectAvailability" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "availabilitiesRequest" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "ServiceAvailabilityRequest",
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "quotationsRequest" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "QuotationRequest",
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startDt" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endDt" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceAvailabilities" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "availabilitiesRequest",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "availableSelectableResourceIds",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "quotations" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "quotationsRequest",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "deposit" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "price" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "quotationFor",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "remainder" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timeFullyAccounted",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "unavailableResourceSlotsBetween",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "categoryId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "dtRange" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "startDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "startDt",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "endDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "endDt",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resourceId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BookingSelectAvailabilityQuery,
    BookingSelectAvailabilityQueryVariables
>;
export const PelangganBookingSelectFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganBookingSelectForm" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCategoryId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "linkedResources",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minBookingWindowMinutes",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingWindow",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "disallowBookingGap",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "customDurationOptions",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startTime" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endTime" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "FooterBookingDetailsCentre",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "FooterBookingDetailsCategories",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FooterBookingDetailsCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FooterBookingDetailsCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganBookingSelectFormQuery,
    PelangganBookingSelectFormQueryVariables
>;
export const CartCentreDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "cartCentreDetails" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CartCentreDetailsQuery,
    CartCentreDetailsQueryVariables
>;
export const PelangganCheckDiscountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganCheckDiscount" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "items" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "CheckoutDiscountItem",
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "promocode" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "checkoutDiscount" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "items" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "items" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "promocodeId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "promocode" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "discountedAmount",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCheckDiscountQuery,
    PelangganCheckDiscountQueryVariables
>;
export const PelangganCheckDiscountV2Document = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganCheckDiscountV2" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "promocode" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "items" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "PromoCodeDiscountItem",
                                    },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "checkPromoCodeDiscount" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "promoCodeCode" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "promocode" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "items" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "items" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "discountedAmount",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCheckDiscountV2Query,
    PelangganCheckDiscountV2QueryVariables
>;
export const BookingFormAddOnSelectDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "bookingFormAddOnSelect" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "price" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isInStock" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "maxQuantity",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BookingFormAddOnSelectQuery,
    BookingFormAddOnSelectQueryVariables
>;
export const PelangganGameFormGameCreateFromInvoiceFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganGameFormGameCreateFromInvoiceForm",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "uid" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganGameFormGameCreateFromInvoiceFormQuery,
    PelangganGameFormGameCreateFromInvoiceFormQueryVariables
>;
export const EditGameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "editGame" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startDt" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endDt" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "location" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "LocationInput" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "maxPlayers" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Int" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "private" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "reservedPlayers" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "editGame" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "uid",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "startDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "startDt",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "endDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "endDt",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "description",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "location",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "location",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "maxPlayers",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "maxPlayers",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "private",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "private",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "reservedPlayers",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "reservedPlayers",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "GameEdited",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "game",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EditGameMutation, EditGameMutationVariables>;
export const EditGameOrganizerDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "editGameOrganizer" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "gameId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizerDetails" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "GameOrganizerInput" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "editGameOrganizer" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "gameId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "gameId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "organizerDetails",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "organizerDetails",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "GameOrganizerEdited",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "game",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditGameOrganizerMutation,
    EditGameOrganizerMutationVariables
>;
export const PelangganGameFormDateTimeFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganGameFormDateTimeForm" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "uid" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganGameFormDateTimeFormQuery,
    PelangganGameFormDateTimeFormQueryVariables
>;
export const PelangganGameFormDateTimeFormBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganGameFormDateTimeFormBookings",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uids" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "uids" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganGameFormDateTimeFormBookingsQuery,
    PelangganGameFormDateTimeFormBookingsQueryVariables
>;
export const PelangganUserDataUseUpdateUserProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "pelangganUserDataUseUpdateUserProfile",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetUserProfileRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "UserProfileSet",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "user",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firebaseId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "email",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "phoneNumber",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InvalidValue",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserDataUseUpdateUserProfileMutation,
    PelangganUserDataUseUpdateUserProfileMutationVariables
>;
export const UserDataCollectionFormDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "userDataCollectionForm" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gender" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "dateOfBirth",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postCode" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "preferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "otherPreferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "sportsFrequency",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserDataCollectionFormQuery,
    UserDataCollectionFormQueryVariables
>;
export const PendingPaymentsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pendingPayments" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startAfter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "DateTime" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byStatus",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "unpaid",
                                                        },
                                                        value: {
                                                            kind: "BooleanValue",
                                                            value: true,
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myBookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "30" },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byStatus",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "unconfirmed",
                                                        },
                                                        value: {
                                                            kind: "BooleanValue",
                                                            value: true,
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byStartDateAfter",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "startAfter",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "tenantId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "referenceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "created",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "confirmed",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "cancelled",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "resources",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "resource",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "archived",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "service",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "serviceMode",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "category",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PendingPaymentsQuery,
    PendingPaymentsQueryVariables
>;
export const HomepageUpcomingCardWebCheckoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "homepageUpcomingCardWebCheckout" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteCheckoutStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "checkoutId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "hasDeposit" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    HomepageUpcomingCardWebCheckoutQuery,
    HomepageUpcomingCardWebCheckoutQueryVariables
>;
export const HomepageUpcomingCardWebCheckoutRescheduleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "homepageUpcomingCardWebCheckoutReschedule",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteRescheduleStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    HomepageUpcomingCardWebCheckoutRescheduleQuery,
    HomepageUpcomingCardWebCheckoutRescheduleQueryVariables
>;
export const PelangganUserDetailsModalSetUserProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "pelangganUserDetailsModalSetUserProfile",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetUserProfileRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "UserProfileSet",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "user",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firebaseId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InvalidValue",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserDetailsModalSetUserProfileMutation,
    PelangganUserDetailsModalSetUserProfileMutationVariables
>;
export const NavigationMenuProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "navigationMenuProfile" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NavigationMenuProfileQuery,
    NavigationMenuProfileQueryVariables
>;
export const ConfirmCheckoutAvailabilitiesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "confirmCheckoutAvailabilities" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: {
                            kind: "Name",
                            value: "serviceAvailabilitiesRequest",
                        },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "ServiceAvailabilityRequest",
                                    },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceAvailabilities" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "serviceAvailabilitiesRequest",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isAvailable",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ConfirmCheckoutAvailabilitiesQuery,
    ConfirmCheckoutAvailabilitiesQueryVariables
>;
export const StartRescheduleCheckoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "startRescheduleCheckout" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "newSlot" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CourtsiteBookingSlotInput",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "startCourtsiteReschedule",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "newSlot",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "newSlot",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteRescheduleStarted",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "runId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "completed",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "expired",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "cancelled",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "created",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "priceDifference",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "newBookingId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "newInvoiceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "redirectUrl",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "capturePaymentWorkflowId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "capturePaymentState",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenantId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "invoiceId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "state",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "amountToCapture",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "paymentSessionId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "completed",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "expired",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "cancelled",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "created",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteRescheduleBookingsUnavailable",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteRescheduleBookingsConflictingWorkflow",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartRescheduleCheckoutMutation,
    StartRescheduleCheckoutMutationVariables
>;
export const SearchCentreCardQueryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "searchCentreCardQuery" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SearchCentreCardQueryQuery,
    SearchCentreCardQueryQueryVariables
>;
export const PelangganSearchResultsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganSearchResults" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CourtsiteSearchRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "courtsiteSearch" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganSearchResultsQuery,
    PelangganSearchResultsQueryVariables
>;
export const PelangganSearchResultLocationDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganSearchResultLocation" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "locations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "coordinates",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "city" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganSearchResultLocationQuery,
    PelangganSearchResultLocationQueryVariables
>;
export const PelangganSearchResultServiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganSearchResultService" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "onlineServicesByCategory",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "categoryId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganSearchResultServiceQuery,
    PelangganSearchResultServiceQueryVariables
>;
export const PelangganCentreSearchUseAvailabilitiesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganCentreSearchUseAvailabilities",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "ServiceAvailabilityRequest",
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "quotationsRequest" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "QuotationRequest",
                                    },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceAvailabilities" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isAvailable",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "quotations" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "quotationsRequest",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "quotationFor",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "timeFullyAccounted",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCentreSearchUseAvailabilitiesQuery,
    PelangganCentreSearchUseAvailabilitiesQueryVariables
>;
export const ViewLiveAvailabilityDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "viewLiveAvailability" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minBookingWindowMinutes",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingWindow",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "disallowBookingGap",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "customDurationOptions",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "price" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isInStock" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myMemberServiceIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceTags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ViewLiveAvailabilityQuery,
    ViewLiveAvailabilityQueryVariables
>;
export const PelangganUseBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganUseBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "minRescheduleNotice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceCategory",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "categoryId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "guests" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "userId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUseBookingsQuery,
    PelangganUseBookingsQueryVariables
>;
export const GetGameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getGame" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "gameId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "game" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "gameId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "description",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenant",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "maxPlayers" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "organizer" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "displayPicture",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "organizerId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "players" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "displayPicture",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "playerId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isPrivate" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "reservedPlayers",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetGameQuery, GetGameQueryVariables>;
export const StartCheckoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "startCheckout" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "slots" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: {
                                        kind: "Name",
                                        value: "CourtsiteCheckoutSlotInput",
                                    },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "addOns" },
                    },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: {
                                    kind: "Name",
                                    value: "CourtsiteCheckoutAddOnInput",
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "promocode" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "startCourtsiteCheckout" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "slots",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "slots",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "guest",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "name",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "phoneNumber",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "phoneNumber",
                                                            },
                                                        },
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "email",
                                                        },
                                                        value: {
                                                            kind: "Variable",
                                                            name: {
                                                                kind: "Name",
                                                                value: "email",
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "StringValue",
                                                value: "{}",
                                                block: false,
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "addOns",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "addOns",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "promocode",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "promocode",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteCheckoutStarted",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "runId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteCheckoutBookingsUnavailable",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartCheckoutMutation,
    StartCheckoutMutationVariables
>;
export const PublicGamesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "publicGames" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PublicGamesFilter" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PageInput" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "publicGames" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "page" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "page" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "categoryId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "maxPlayers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "location",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenant",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "organizer",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "players",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "displayPicture",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "reservedPlayers",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageInfo" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "hasNextPage",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PublicGamesQuery, PublicGamesQueryVariables>;
export const CourtsiteRescheduleStateByIdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CourtsiteRescheduleStateById" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteRescheduleStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "runId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "priceDifference",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "oldBookingId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "newBookingId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "newInvoiceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "redirectUrl",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentWorkflowId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "runId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "amountToCapture",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentSessionId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isCancellable",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "attempts",
                                                },
                                            },
                                            {
                                                kind: "FragmentSpread",
                                                name: {
                                                    kind: "Name",
                                                    value: "PaymentHandlerData",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CourtsiteRescheduleStateByIdQuery,
    CourtsiteRescheduleStateByIdQueryVariables
>;
export const PelangganUseRescheduleCheckoutWorkflowBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganUseRescheduleCheckoutWorkflowBookings",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "oldBookingId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "newBookingId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "oldBooking" },
                        name: { kind: "Name", value: "myBooking" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingId" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "oldBookingId",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "bookingFields",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "newBooking" },
                        name: { kind: "Name", value: "myBooking" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingId" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "newBookingId",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "bookingFields",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "bookingFields" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "startDt" } },
                    { kind: "Field", name: { kind: "Name", value: "endDt" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "referenceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "service" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minRescheduleNotice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resource" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUseRescheduleCheckoutWorkflowBookingsQuery,
    PelangganUseRescheduleCheckoutWorkflowBookingsQueryVariables
>;
export const UserGamesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "userGames" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "GameFilter" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "order" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "SortOrderEnum" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myGames" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "order" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "order" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "location",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenantId",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "categoryId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "players",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "displayPicture",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "maxPlayers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "reservedPlayers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "organizer",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "organizerId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageInfo" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "hasNextPage",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserGamesQuery, UserGamesQueryVariables>;
export const GetUserGroupedBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getUserGroupedBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: {
                            kind: "Name",
                            value: "MyGroupedBookingsFilter",
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "order" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "SortOrderEnum" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myGroupedBookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "order" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "order" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "referenceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "bookings",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "created",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenantId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "referenceId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "startDt",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "endDt",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "confirmed",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "cancelled",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "service",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "serviceMode",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "categoryId",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "categoryName",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "resources",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "resourceId",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "resourceName",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "metadata",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageInfo" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "startCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "hasNextPage",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetUserGroupedBookingsQuery,
    GetUserGroupedBookingsQueryVariables
>;
export const MyProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "myProfile" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gender" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "dateOfBirth",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postCode" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "preferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "otherPreferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "sportsFrequency",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<MyProfileQuery, MyProfileQueryVariables>;
export const ForceRetryCapturePaymentSPayCheckoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "forceRetryCapturePaymentSPayCheckout",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ForceRetryCapturePaymentRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "forceRetryCapturePayment",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CapturePaymentForceRetried",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "tenantId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "invoiceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "amountToCapture",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "completed",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "expired",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "cancelled",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "created",
                                                            },
                                                        },
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "SPayDataCheckout",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SPayDataCheckout" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentSessionCreated" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ForceRetryCapturePaymentSPayCheckoutMutation,
    ForceRetryCapturePaymentSPayCheckoutMutationVariables
>;
export const PelangganCentreActivitiesCategoriesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganCentreActivitiesCategories",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startsAfter" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "publicGameCount" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "startsAfter" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "startsAfter",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gameCount" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "locationTenantIds",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsitePartner",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCentreActivitiesCategoriesQuery,
    PelangganCentreActivitiesCategoriesQueryVariables
>;
export const PelangganCentreDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganCentre" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isLoggedIn" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsitePartner",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceTags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myMemberServiceIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: { kind: "Name", value: "include" },
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "if" },
                                        value: {
                                            kind: "Variable",
                                            name: {
                                                kind: "Name",
                                                value: "isLoggedIn",
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCentreQuery,
    PelangganCentreQueryVariables
>;
export const PelangganCentreNearbyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganCentreNearby" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "req" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "CourtsiteSearchRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "courtsiteSearch" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "req" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "VenueCardOrg",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "VenueCardOrg" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCentreNearbyQuery,
    PelangganCentreNearbyQueryVariables
>;
export const PelangganCentreBookingSelectHourlyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganCentreBookingSelectHourly" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "FooterBookingDetailsCentre",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "FooterBookingDetailsCategories",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FooterBookingDetailsCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FooterBookingDetailsCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCentreBookingSelectHourlyQuery,
    PelangganCentreBookingSelectHourlyQueryVariables
>;
export const PelangganCentreCategoriesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganCentreCategories" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCentreCategoriesQuery,
    PelangganCentreCategoriesQueryVariables
>;
export const PelangganCentreBookingSelectDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganCentreBookingSelect" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "FooterBookingDetailsCentre",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minBookingWindowMinutes",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingWindow",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "disallowBookingGap",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "customDurationOptions",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "price" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isInStock" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myMemberServiceIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceTags" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "serviceId" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "FooterBookingDetailsCategories",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FooterBookingDetailsCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FooterBookingDetailsCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCentreBookingSelectQuery,
    PelangganCentreBookingSelectQueryVariables
>;
export const PelangganCheckoutWorkflowPaymentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganCheckoutWorkflowPayment" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowID" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteCheckoutStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "checkoutId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "promocode" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "discountedAmount",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceId",
                                                },
                                            },
                                            {
                                                kind: "FragmentSpread",
                                                name: {
                                                    kind: "Name",
                                                    value: "PaymentHandlerData",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCheckoutWorkflowPaymentQuery,
    PelangganCheckoutWorkflowPaymentQueryVariables
>;
export const CourtsiteCheckoutWorkflowPaymentBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "courtsiteCheckoutWorkflowPaymentBookings",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetails",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingSummaryBookingDetails",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetailsCentre",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetailsCategories",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "AddOns" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "startDt" } },
                    { kind: "Field", name: { kind: "Name", value: "endDt" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "service" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resource" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addons" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addonId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "perUnitPrice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingSummaryBookingDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addons" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "perUnitPrice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetailsCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "isCourtsitePartner" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetailsCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "AddOns" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "AddOn" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CourtsiteCheckoutWorkflowPaymentBookingsQuery,
    CourtsiteCheckoutWorkflowPaymentBookingsQueryVariables
>;
export const CheckoutInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "checkoutInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "invoiceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeName",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceePhoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeEmail",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CheckoutInvoiceQuery,
    CheckoutInvoiceQueryVariables
>;
export const CancelCourtsiteCheckoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CancelCourtsiteCheckout" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "cancelCourtsiteCheckout",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "checkoutId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "workflowId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteCheckoutNotCancellable",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CancelCourtsiteCheckoutMutation,
    CancelCourtsiteCheckoutMutationVariables
>;
export const ProvideCourtsiteCheckoutPaymentDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "provideCourtsiteCheckoutPaymentDetails",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "checkoutId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentProvider" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "PaymentProvider" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "redirectUrl" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "provideCourtsiteCheckoutPaymentDetails",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "checkoutId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "checkoutId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "email",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "phoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentProvider",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentProvider",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "redirectUrl",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "redirectUrl",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteCheckoutPaymentDetailsProvided",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "runId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "capturePaymentState",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "amountToCapture",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "FragmentSpread",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "PaymentHandlerData",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteCheckoutExpired",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProvideCourtsiteCheckoutPaymentDetailsMutation,
    ProvideCourtsiteCheckoutPaymentDetailsMutationVariables
>;
export const ForceRetryCapturePaymentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "forceRetryCapturePayment" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ForceRetryCapturePaymentRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "forceRetryCapturePayment",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CapturePaymentForceRetried",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "amountToCapture",
                                                            },
                                                        },
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "PaymentHandlerData",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ForceRetryCapturePaymentMutation,
    ForceRetryCapturePaymentMutationVariables
>;
export const CheckoutResultCourtsiteCheckoutStateByIdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "checkoutResultCourtsiteCheckoutStateById",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowID" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteCheckoutStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "checkoutId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "runId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "invoiceIds" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CheckoutResultCourtsiteCheckoutStateByIdQuery,
    CheckoutResultCourtsiteCheckoutStateByIdQueryVariables
>;
export const CheckoutResultBookingsCentreDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "checkoutResultBookingsCentre" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceIds" },
                    },
                    type: {
                        kind: "ListType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "showInvoice" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "confirmed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "minRescheduleNotice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceCategory",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "categoryId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byInvoiceIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceIds",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: { kind: "Name", value: "include" },
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "if" },
                                        value: {
                                            kind: "Variable",
                                            name: {
                                                kind: "Name",
                                                value: "showInvoice",
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "groupIdEventsActivationStatus",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "groupIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "groupId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isActivated",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "requiresUserActivation",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CheckoutResultBookingsCentreQuery,
    CheckoutResultBookingsCentreQueryVariables
>;
export const PelangganCheckoutWorkflowVerifyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganCheckoutWorkflowVerify" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowID" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteCheckoutStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "checkoutId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCheckoutWorkflowVerifyQuery,
    PelangganCheckoutWorkflowVerifyQueryVariables
>;
export const ConfirmCapturePaymentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "confirmCapturePayment" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "queryParams" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmCapturePayment" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "workflowId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "workflowId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "queryParams",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "queryParams",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CapturePaymentConfirmed",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ConfirmCapturePaymentMutation,
    ConfirmCapturePaymentMutationVariables
>;
export const CourtsiteCheckoutStateByIdSpayDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "courtsiteCheckoutStateByIdSpay" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "checkoutId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteCheckoutStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "checkoutId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "checkoutId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "amountToCapture",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "spayQrCode",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentSessionCreated",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CourtsiteCheckoutStateByIdSpayQuery,
    CourtsiteCheckoutStateByIdSpayQueryVariables
>;
export const PelangganExploreByCategoryIdDataDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganExploreByCategoryIdData" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "locations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "coordinates",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "city" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "LocationAccordionOption",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "LocationAccordionOption" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "SearchLocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "coordinates" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latitude" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "longitude" },
                                },
                            ],
                        },
                    },
                    { kind: "Field", name: { kind: "Name", value: "city" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganExploreByCategoryIdDataQuery,
    PelangganExploreByCategoryIdDataQueryVariables
>;
export const PelangganExploreByCategoryIdSearchDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganExploreByCategoryIdSearch" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: {
                            kind: "Name",
                            value: "ApprovedOrganisationsFilter",
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sortOrder" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "OrganisationSortOrder" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Int" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "approvedOrganisations" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sortOrder" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "sortOrder" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "isCourtsitePartner",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "location",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "latitude",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "longitude",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "services",
                                                            },
                                                        },
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "ExploreCategoryCentre",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageInfo" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endCursor",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "hasNextPage",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "ExploreCategoryCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganExploreByCategoryIdSearchQuery,
    PelangganExploreByCategoryIdSearchQueryVariables
>;
export const ExploreCategoryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "exploreCategory" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "CategoryListCategories",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarCategories",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "courtsitePartners" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "CategoryListVenues",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarVenues",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "locations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarLocations",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListCategories" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListVenues" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarLocations" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "SearchLocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "city" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "coordinates" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latitude" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "longitude" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ExploreCategoryQuery,
    ExploreCategoryQueryVariables
>;
export const ExploreGamesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "exploreGames" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startsAfter" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "publicGameCount" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "startsAfter" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "startsAfter",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gameCount" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "locationTenantIds",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "courtsitePartners" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "locations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "coordinates",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "city" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ExploreGamesQuery, ExploreGamesQueryVariables>;
export const ExploreGamesCourtsiteSearchDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "exploreGamesCourtsiteSearch" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: {
                            kind: "Name",
                            value: "ApprovedOrganisationsFilter",
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "after" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "approvedOrganisations" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "after" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ExploreGamesCourtsiteSearchQuery,
    ExploreGamesCourtsiteSearchQueryVariables
>;
export const ArchiveGameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "archiveGame" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "archiveGame" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "uid",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "GameArchived",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "game",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ArchiveGameMutation, ArchiveGameMutationVariables>;
export const PelangganGameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganGame" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "gameId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "game" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "gameId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "description",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "maxPlayers" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "organizer" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "displayPicture",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "organizerId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "players" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "displayPicture",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "playerId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isPrivate" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "reservedPlayers",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PelangganGameQuery, PelangganGameQueryVariables>;
export const GetCentreDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getCentre" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "orgID" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "orgID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "orgID" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resourceId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCentreQuery, GetCentreQueryVariables>;
export const StartCourtsiteGameWorkflowDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "startCourtsiteGameWorkflow" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "categoryId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endDt" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Time" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "location" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "StartCourtsiteGameWorkflowLocationInput",
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "maxPlayers" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Int" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "metadata" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "JSONString" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "organizerDetails" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "StartCourtsiteGameWorkflowPlayerInput",
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startDt" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Time" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "private" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Boolean" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "reservedPlayers" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "startCourtsiteGameWorkflow",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "categoryId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "description",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "endDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "endDt",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "location",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "location",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "maxPlayers",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "maxPlayers",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "metadata",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "name",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "organizerDetails",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "organizerDetails",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "startDt",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "startDt",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "private",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "private",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "reservedPlayers",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "reservedPlayers",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteGameWorkflowStarted",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "gameId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    StartCourtsiteGameWorkflowMutation,
    StartCourtsiteGameWorkflowMutationVariables
>;
export const PelangganHomePageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganHomePage" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "now" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startsAfter" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "DateTime" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isLoggedIn" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "upcomingBookings" },
                        name: { kind: "Name", value: "myGroupedBookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byStatus",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "confirmed",
                                                        },
                                                        value: {
                                                            kind: "BooleanValue",
                                                            value: true,
                                                        },
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "unconfirmed",
                                                        },
                                                        value: {
                                                            kind: "BooleanValue",
                                                            value: true,
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byStartDateAfter",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "now",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "3" },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "order" },
                                value: { kind: "EnumValue", value: "ASC" },
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: { kind: "Name", value: "include" },
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "if" },
                                        value: {
                                            kind: "Variable",
                                            name: {
                                                kind: "Name",
                                                value: "isLoggedIn",
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "referenceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "bookings",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenant",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "created",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenantId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "startDt",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "endDt",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "confirmed",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "cancelled",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "service",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "serviceMode",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "categoryName",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "resources",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "resourceId",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "resourceName",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "metadata",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "publicGameCount" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "startsAfter" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "startsAfter",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gameCount" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "locationTenantIds",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "FeatureVenueCategories",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SocialGamesCategories",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarCategories",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "tenantIdsFromPastBookings",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "3" },
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: { kind: "Name", value: "include" },
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "if" },
                                        value: {
                                            kind: "Variable",
                                            name: {
                                                kind: "Name",
                                                value: "isLoggedIn",
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "courtsitePartners" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sortOrder" },
                                value: {
                                    kind: "EnumValue",
                                    value: "BY_WEIGHTAGE_DESC",
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarVenues",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "locations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarLocations",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FeatureVenueCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SocialGamesCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListCategories" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListVenues" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarLocations" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "SearchLocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "city" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "coordinates" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latitude" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "longitude" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganHomePageQuery,
    PelangganHomePageQueryVariables
>;
export const HomepagePublicGameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "homepagePublicGame" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PublicGamesFilter" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "page" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PageInput" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "publicGames" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "page" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "page" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "categoryId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "maxPlayers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "location",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenant",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "name",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "organizer",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "players",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "displayPicture",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "reservedPlayers",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    HomepagePublicGameQuery,
    HomepagePublicGameQueryVariables
>;
export const PelangganInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "uid" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expiresOn" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "printNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeName",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsiteOwned",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "payments" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "amount",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentType",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethod",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsitePartner",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetailsCentre",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetailsCategories",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "AddOns" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetailsCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "isCourtsitePartner" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetailsCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "AddOns" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "AddOn" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganInvoiceQuery,
    PelangganInvoiceQueryVariables
>;
export const PelangganInvoiceBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganInvoiceBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetails",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingSummaryBookingDetails",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "startDt" } },
                    { kind: "Field", name: { kind: "Name", value: "endDt" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "service" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resource" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addons" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addonId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "perUnitPrice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingSummaryBookingDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addons" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "perUnitPrice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganInvoiceBookingsQuery,
    PelangganInvoiceBookingsQueryVariables
>;
export const PelangganOnboardingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganOnboarding" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gender" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "dateOfBirth",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postCode" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "preferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "otherPreferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "sportsFrequency",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganOnboardingQuery,
    PelangganOnboardingQueryVariables
>;
export const PelangganOnboardingSetUserProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "pelangganOnboardingSetUserProfile" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetUserProfileRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "UserProfileSet",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "user",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firebaseId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InvalidValue",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganOnboardingSetUserProfileMutation,
    PelangganOnboardingSetUserProfileMutationVariables
>;
export const CapturePaymentResultDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "capturePaymentResult" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: {
                            kind: "Name",
                            value: "capturePaymentWorkflowId",
                        },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "checkoutId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "hasCheckoutId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "capturePaymentStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentWorkflowId",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "invoiceId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteCheckoutStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "checkoutId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "checkoutId" },
                                },
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: { kind: "Name", value: "include" },
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "if" },
                                        value: {
                                            kind: "Variable",
                                            name: {
                                                kind: "Name",
                                                value: "hasCheckoutId",
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "invoiceId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CapturePaymentResultQuery,
    CapturePaymentResultQueryVariables
>;
export const PaymentResultDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "paymentResult" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "String" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "showInvoice" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "Boolean" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byInvoiceIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "invoiceIds",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        directives: [
                            {
                                kind: "Directive",
                                name: { kind: "Name", value: "include" },
                                arguments: [
                                    {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "if" },
                                        value: {
                                            kind: "Variable",
                                            name: {
                                                kind: "Name",
                                                value: "showInvoice",
                                            },
                                        },
                                    },
                                ],
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PaymentResultQuery, PaymentResultQueryVariables>;
export const PaymentResultBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "paymentResultBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myBookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "confirmed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "minRescheduleNotice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "category",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PaymentResultBookingsQuery,
    PaymentResultBookingsQueryVariables
>;
export const CapturePaymentUserDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "capturePaymentUser" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CapturePaymentUserQuery,
    CapturePaymentUserQueryVariables
>;
export const PaymentInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "paymentInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "invoiceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeName",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceePhoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeEmail",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenant" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "services",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "isCourtsitePartner",
                                                },
                                            },
                                            {
                                                kind: "FragmentSpread",
                                                name: {
                                                    kind: "Name",
                                                    value: "BookingDetailsCentre",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetailsCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "isCourtsitePartner" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PaymentInvoiceQuery, PaymentInvoiceQueryVariables>;
export const PaymentBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "paymentBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetails",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingSummaryBookingDetails",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetailsCategories",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "AddOns" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "startDt" } },
                    { kind: "Field", name: { kind: "Name", value: "endDt" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "service" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resource" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addons" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addonId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "perUnitPrice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingSummaryBookingDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addons" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "perUnitPrice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetailsCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "AddOns" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "AddOn" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PaymentBookingsQuery,
    PaymentBookingsQueryVariables
>;
export const CapturePaymentStateByIdDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "capturePaymentStateById" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "capturePaymentStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "invoiceId" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "PaymentHandlerData",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CapturePaymentStateByIdQuery,
    CapturePaymentStateByIdQueryVariables
>;
export const ProvideCapturePaymentProviderDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "provideCapturePaymentProviderDetails",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "paymentProvider" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "PaymentProvider" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "redirectUrl" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "payerDetailsName" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: {
                            kind: "Name",
                            value: "payerDetailsPhoneNumber",
                        },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "payerDetailsEmail" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "provideCapturePaymentProviderDetails",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "workflowId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "workflowId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "paymentProvider",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentProvider",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "redirectUrl",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "redirectUrl",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "payerDetailsName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "payerDetailsName",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "payerDetailsPhoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "payerDetailsPhoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "payerDetailsEmail",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "payerDetailsEmail",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CapturePaymentProviderDetailsProvided",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "runId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "PaymentHandlerData",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProvideCapturePaymentProviderDetailsMutation,
    ProvideCapturePaymentProviderDetailsMutationVariables
>;
export const ForceRetryCapturePaymentLinkDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "forceRetryCapturePaymentLink" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ForceRetryCapturePaymentRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "forceRetryCapturePayment",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CapturePaymentForceRetried",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "runId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "PaymentHandlerData",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ForceRetryCapturePaymentLinkMutation,
    ForceRetryCapturePaymentLinkMutationVariables
>;
export const PelangganCapturePaymentVerifyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganCapturePaymentVerify" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: {
                            kind: "Name",
                            value: "capturePaymentWorkflowId",
                        },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "capturePaymentStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentWorkflowId",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganCapturePaymentVerifyQuery,
    PelangganCapturePaymentVerifyQueryVariables
>;
export const PelangganPaymentCaptureWorkflowVerifyConfirmCapturePaymentDocument =
    {
        kind: "Document",
        definitions: [
            {
                kind: "OperationDefinition",
                operation: "mutation",
                name: {
                    kind: "Name",
                    value: "pelangganPaymentCaptureWorkflowVerifyConfirmCapturePayment",
                },
                variableDefinitions: [
                    {
                        kind: "VariableDefinition",
                        variable: {
                            kind: "Variable",
                            name: {
                                kind: "Name",
                                value: "capturePaymentWorkflowId",
                            },
                        },
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ID" },
                            },
                        },
                    },
                    {
                        kind: "VariableDefinition",
                        variable: {
                            kind: "Variable",
                            name: { kind: "Name", value: "queryParams" },
                        },
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "JSONString" },
                            },
                        },
                    },
                ],
                selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                        {
                            kind: "Field",
                            name: {
                                kind: "Name",
                                value: "confirmCapturePayment",
                            },
                            arguments: [
                                {
                                    kind: "Argument",
                                    name: { kind: "Name", value: "request" },
                                    value: {
                                        kind: "ObjectValue",
                                        fields: [
                                            {
                                                kind: "ObjectField",
                                                name: {
                                                    kind: "Name",
                                                    value: "workflowId",
                                                },
                                                value: {
                                                    kind: "Variable",
                                                    name: {
                                                        kind: "Name",
                                                        value: "capturePaymentWorkflowId",
                                                    },
                                                },
                                            },
                                            {
                                                kind: "ObjectField",
                                                name: {
                                                    kind: "Name",
                                                    value: "queryParams",
                                                },
                                                value: {
                                                    kind: "Variable",
                                                    name: {
                                                        kind: "Name",
                                                        value: "queryParams",
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                            selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                    {
                                        kind: "Field",
                                        name: {
                                            kind: "Name",
                                            value: "__typename",
                                        },
                                    },
                                    {
                                        kind: "InlineFragment",
                                        typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                                kind: "Name",
                                                value: "CapturePaymentConfirmed",
                                            },
                                        },
                                        selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                                {
                                                    kind: "Field",
                                                    name: {
                                                        kind: "Name",
                                                        value: "state",
                                                    },
                                                    selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                            {
                                                                kind: "Field",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                },
                                                            },
                                                        ],
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        kind: "InlineFragment",
                                        typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                                kind: "Name",
                                                value: "WorkflowNotFound",
                                            },
                                        },
                                        selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                                {
                                                    kind: "Field",
                                                    name: {
                                                        kind: "Name",
                                                        value: "message",
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        kind: "InlineFragment",
                                        typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                                kind: "Name",
                                                value: "WorkflowCancelled",
                                            },
                                        },
                                        selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                                {
                                                    kind: "Field",
                                                    name: {
                                                        kind: "Name",
                                                        value: "message",
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        kind: "InlineFragment",
                                        typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                                kind: "Name",
                                                value: "WorkflowNotReady",
                                            },
                                        },
                                        selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                                {
                                                    kind: "Field",
                                                    name: {
                                                        kind: "Name",
                                                        value: "message",
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        ],
    } as unknown as DocumentNode<
        PelangganPaymentCaptureWorkflowVerifyConfirmCapturePaymentMutation,
        PelangganPaymentCaptureWorkflowVerifyConfirmCapturePaymentMutationVariables
    >;
export const CapturePaymentVerificationSPayDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "capturePaymentVerificationSPay" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "capturePaymentStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "amountToCapture",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "spayQrCode" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "paymentSessionCreated",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CapturePaymentVerificationSPayQuery,
    CapturePaymentVerificationSPayQueryVariables
>;
export const PelangganRescheduleAddonsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganRescheduleAddons" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganRescheduleAddonsQuery,
    PelangganRescheduleAddonsQueryVariables
>;
export const PelangganRescheduleBookingBookingDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganRescheduleBookingBookingDetails",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "relatedEntityIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "String" },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myBookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenant" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "category",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addons" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "addonId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byRelatedEntityIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntityIds",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganRescheduleBookingBookingDetailsQuery,
    PelangganRescheduleBookingBookingDetailsQueryVariables
>;
export const PelangganRescheduleStateByIdResultDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganRescheduleStateByIdResult" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteRescheduleStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "oldBookingId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "priceDifference",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganRescheduleStateByIdResultQuery,
    PelangganRescheduleStateByIdResultQueryVariables
>;
export const ProvideCourtsiteReschedulePaymentDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "ProvideCourtsiteReschedulePaymentDetails",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ProvideCourtsiteReschedulePaymentDetailsRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "provideCourtsiteReschedulePaymentDetails",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteReschedulePaymentDetailsProvided",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "runId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "completed",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "expired",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "cancelled",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "created",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "priceDifference",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "newBookingId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "newInvoiceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "redirectUrl",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "capturePaymentWorkflowId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "capturePaymentState",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "id",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "runId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "state",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "FragmentSpread",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "PaymentHandlerData",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InvalidCourtsiteReschedulePaymentDetailsRequest",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CourtsiteRescheduleExpired",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowCancelled",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProvideCourtsiteReschedulePaymentDetailsMutation,
    ProvideCourtsiteReschedulePaymentDetailsMutationVariables
>;
export const PelangganRescheduleCheckoutForceRetryPaymentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: {
                kind: "Name",
                value: "pelangganRescheduleCheckoutForceRetryPayment",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "ForceRetryCapturePaymentRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "forceRetryCapturePayment",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "CapturePaymentForceRetried",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "id",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "runId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "state",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "amountToCapture",
                                                            },
                                                        },
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "PaymentHandlerData",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotFound",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "WorkflowNotReady",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganRescheduleCheckoutForceRetryPaymentMutation,
    PelangganRescheduleCheckoutForceRetryPaymentMutationVariables
>;
export const PelangganRescheduleStateByIdPaymentDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganRescheduleStateByIdPayment",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteRescheduleStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "oldBookingId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "newBookingId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "newInvoiceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "priceDifference",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "state",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "FragmentSpread",
                                                name: {
                                                    kind: "Name",
                                                    value: "PaymentHandlerData",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PaymentHandlerData" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "CapturePaymentState" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "billplzPaymentUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88BackendUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88ResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "ipay88RequestSignature" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayOrderId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "razorpayResponseUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReferenceNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayHashValue" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "kiplepayMerchantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayOrderNo" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayReturnUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayCallbackUrl" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayEncryptedPayload" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "spayQrCode" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganRescheduleStateByIdPaymentQuery,
    PelangganRescheduleStateByIdPaymentQueryVariables
>;
export const PelangganRescheduleCheckoutPaymentBookingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganRescheduleCheckoutPaymentBooking",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "oldBookingId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "newBookingId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "oldBooking" },
                        name: { kind: "Name", value: "myBooking" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingId" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "oldBookingId",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "bookingFieldsReschedulePayment",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "newBooking" },
                        name: { kind: "Name", value: "myBooking" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingId" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "newBookingId",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "bookingFieldsReschedulePayment",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "bookingFieldsReschedulePayment" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "startDt" } },
                    { kind: "Field", name: { kind: "Name", value: "endDt" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "referenceId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "service" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "minRescheduleNotice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "category" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resource" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganRescheduleCheckoutPaymentBookingQuery,
    PelangganRescheduleCheckoutPaymentBookingQueryVariables
>;
export const RescheduleCheckoutPaymentOrgDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "rescheduleCheckoutPaymentOrg" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsitePartner",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "onlineServices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RescheduleCheckoutPaymentOrgQuery,
    RescheduleCheckoutPaymentOrgQueryVariables
>;
export const PelangganRescheduleStateByIdSuccessDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganRescheduleStateByIdSuccess",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteRescheduleStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "newBookingId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "newInvoiceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "priceDifference",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganRescheduleStateByIdSuccessQuery,
    PelangganRescheduleStateByIdSuccessQueryVariables
>;
export const PelangganRescheduleCheckoutSuccessDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganRescheduleCheckoutSuccess" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myBooking" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "confirmed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "minRescheduleNotice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "category",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "invoiceId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "invoiceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganRescheduleCheckoutSuccessQuery,
    PelangganRescheduleCheckoutSuccessQueryVariables
>;
export const RescheduleCheckoutSuccessOrgDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "rescheduleCheckoutSuccessOrg" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RescheduleCheckoutSuccessOrgQuery,
    RescheduleCheckoutSuccessOrgQueryVariables
>;
export const PelangganRescheduleStateByIdVerifyDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganRescheduleStateByIdVerify" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteRescheduleStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "priceDifference",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganRescheduleStateByIdVerifyQuery,
    PelangganRescheduleStateByIdVerifyQueryVariables
>;
export const PelangganRescheduleCaptureWorkflowVerifyConfirmCapturePaymentDocument =
    {
        kind: "Document",
        definitions: [
            {
                kind: "OperationDefinition",
                operation: "mutation",
                name: {
                    kind: "Name",
                    value: "pelangganRescheduleCaptureWorkflowVerifyConfirmCapturePayment",
                },
                variableDefinitions: [
                    {
                        kind: "VariableDefinition",
                        variable: {
                            kind: "Variable",
                            name: { kind: "Name", value: "workflowId" },
                        },
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ID" },
                            },
                        },
                    },
                    {
                        kind: "VariableDefinition",
                        variable: {
                            kind: "Variable",
                            name: { kind: "Name", value: "queryParams" },
                        },
                        type: {
                            kind: "NonNullType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "JSONString" },
                            },
                        },
                    },
                ],
                selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                        {
                            kind: "Field",
                            name: {
                                kind: "Name",
                                value: "confirmCapturePayment",
                            },
                            arguments: [
                                {
                                    kind: "Argument",
                                    name: { kind: "Name", value: "request" },
                                    value: {
                                        kind: "ObjectValue",
                                        fields: [
                                            {
                                                kind: "ObjectField",
                                                name: {
                                                    kind: "Name",
                                                    value: "workflowId",
                                                },
                                                value: {
                                                    kind: "Variable",
                                                    name: {
                                                        kind: "Name",
                                                        value: "workflowId",
                                                    },
                                                },
                                            },
                                            {
                                                kind: "ObjectField",
                                                name: {
                                                    kind: "Name",
                                                    value: "queryParams",
                                                },
                                                value: {
                                                    kind: "Variable",
                                                    name: {
                                                        kind: "Name",
                                                        value: "queryParams",
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                            selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                    {
                                        kind: "Field",
                                        name: {
                                            kind: "Name",
                                            value: "__typename",
                                        },
                                    },
                                    {
                                        kind: "InlineFragment",
                                        typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                                kind: "Name",
                                                value: "CapturePaymentConfirmed",
                                            },
                                        },
                                        selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                                {
                                                    kind: "Field",
                                                    name: {
                                                        kind: "Name",
                                                        value: "state",
                                                    },
                                                    selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                            {
                                                                kind: "Field",
                                                                name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                },
                                                            },
                                                        ],
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        kind: "InlineFragment",
                                        typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                                kind: "Name",
                                                value: "WorkflowNotFound",
                                            },
                                        },
                                        selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                                {
                                                    kind: "Field",
                                                    name: {
                                                        kind: "Name",
                                                        value: "message",
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        kind: "InlineFragment",
                                        typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                                kind: "Name",
                                                value: "WorkflowCancelled",
                                            },
                                        },
                                        selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                                {
                                                    kind: "Field",
                                                    name: {
                                                        kind: "Name",
                                                        value: "message",
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        kind: "InlineFragment",
                                        typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                                kind: "Name",
                                                value: "WorkflowNotReady",
                                            },
                                        },
                                        selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                                {
                                                    kind: "Field",
                                                    name: {
                                                        kind: "Name",
                                                        value: "message",
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        ],
    } as unknown as DocumentNode<
        PelangganRescheduleCaptureWorkflowVerifyConfirmCapturePaymentMutation,
        PelangganRescheduleCaptureWorkflowVerifyConfirmCapturePaymentMutationVariables
    >;
export const CourtsiteRescheduleStateByIdSpayDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "CourtsiteRescheduleStateByIdSpay" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteRescheduleStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "completed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "capturePaymentState",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "completed",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "expired",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "amountToCapture",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "spayQrCode",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentSessionCreated",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CourtsiteRescheduleStateByIdSpayQuery,
    CourtsiteRescheduleStateByIdSpayQueryVariables
>;
export const PelangganRescheduleBookingInvoiceDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganRescheduleBookingInvoiceDetails",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "relatedEntityIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "String" },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myBookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "confirmed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenant" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "minRescheduleNotice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "category",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addons" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "addonId",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byRelatedEntityIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntityIds",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "associatedUsers",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "userId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "printNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganRescheduleBookingInvoiceDetailsQuery,
    PelangganRescheduleBookingInvoiceDetailsQueryVariables
>;
export const RescheduleAddonsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "rescheduleAddons" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RescheduleAddonsQuery,
    RescheduleAddonsQueryVariables
>;
export const PelangganSearchPageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganSearchPage" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "FeatureVenueCategories",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "CategoryListCategories",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarCategories",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "locations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "city" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "coordinates",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarLocations",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "courtsitePartners" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "CategoryListVenues",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarVenues",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "FeatureVenueCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListCategories" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarLocations" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "SearchLocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "city" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "coordinates" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latitude" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "longitude" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListVenues" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganSearchPageQuery,
    PelangganSearchPageQueryVariables
>;
export const PelangganSearchResultPageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganSearchResultPage" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarCategories",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchMobileCategories",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "locations" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarLocations",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchMobileLocations",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "courtsitePartners" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchBarVenues",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "SearchMobileVenues",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "CategoryListVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "services" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListCategories" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchMobileCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListCategories" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarLocations" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "SearchLocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "city" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "coordinates" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latitude" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "longitude" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchMobileLocations" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "SearchLocation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "city" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "coordinates" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latitude" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "longitude" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchBarVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListVenues" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "SearchMobileVenues" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "CategoryListVenues" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganSearchResultPageQuery,
    PelangganSearchResultPageQueryVariables
>;
export const PelangganShareInvoicePageDataDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganShareInvoicePageData" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsitePartner",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganShareInvoicePageDataQuery,
    PelangganShareInvoicePageDataQueryVariables
>;
export const InvoiceSharePageDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "invoiceSharePage" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "uid" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "invoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "uid" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "associatedUsers",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "userId",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeName",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InvoiceSharePageQuery,
    InvoiceSharePageQueryVariables
>;
export const AddBookingGuestToBookingsDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "AddBookingGuestToBookingsDetails" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "guestName" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phoneNumber" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "PhoneNumber" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "EmailAddress" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "userId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "addBookingGuestToBookings",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "guestName",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "guestName",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "phoneNumber",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "phoneNumber",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "email",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "email",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "userId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "userId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "BookingGuestAddedToBookings",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AddBookingGuestToBookingsDetailsMutation,
    AddBookingGuestToBookingsDetailsMutationVariables
>;
export const RemoveBookingGuestToBookingDetailsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RemoveBookingGuestToBookingDetails" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "userId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "removeBookingGuest" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "tenantId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "tenantId",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "bookingIds",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "userId",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "userId",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "BookingGuestRemoved",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "bookingIds",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "NotFoundError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveBookingGuestToBookingDetailsMutation,
    RemoveBookingGuestToBookingDetailsMutationVariables
>;
export const UserBookingsCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "userBookingsCount" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myBookingFilterCount" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingCount",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserBookingsCountQuery,
    UserBookingsCountQueryVariables
>;
export const PelangganUserBookingsDetailsNewDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganUserBookingsDetailsNew" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myBookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "confirmed" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "cancelledReason",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingSource",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "minRescheduleNotice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "category",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "guests" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "userId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "myInvoicesByRelatedEntityIds",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: {
                                    kind: "Name",
                                    value: "relatedEntityIds",
                                },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "printNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserBookingsDetailsNewQuery,
    PelangganUserBookingsDetailsNewQueryVariables
>;
export const PelangganUserBookingsDetailsOrgDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganUserBookingsDetailsOrg" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "location" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "latitude",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "longitude",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserBookingsDetailsOrgQuery,
    PelangganUserBookingsDetailsOrgQueryVariables
>;
export const PelangganUserBookingsDetailsWebCheckoutDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganUserBookingsDetailsWebCheckout",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteCheckoutStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "checkoutId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserBookingsDetailsWebCheckoutQuery,
    PelangganUserBookingsDetailsWebCheckoutQueryVariables
>;
export const PelangganUserBookingsDetailsWebCheckoutRescheduleDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: {
                kind: "Name",
                value: "pelangganUserBookingsDetailsWebCheckoutReschedule",
            },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "workflowId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "courtsiteRescheduleStateById",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "workflowId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "workflowId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "expired" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "state" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserBookingsDetailsWebCheckoutRescheduleQuery,
    PelangganUserBookingsDetailsWebCheckoutRescheduleQueryVariables
>;
export const UserGamesCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "userGamesCount" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myGameFilterCount" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gameCount" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserGamesCountQuery, UserGamesCountQueryVariables>;
export const GetUserProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getUserProfile" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startOfYear" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "DateTime" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gender" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "dateOfBirth",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postCode" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "joined" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "preferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "otherPreferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "sportsFrequency",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoiceStatistics" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCreatedAfter",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "startOfYear",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoicePerYear",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myGameStatistics" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gamesMade" },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myBookingStatistics" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingsHours",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingsMade",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const GetMyDetailsUpcomingBookingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getMyDetailsUpcomingBooking" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "now" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "DateTime" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myGroupedBookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byStatus",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "confirmed",
                                                        },
                                                        value: {
                                                            kind: "BooleanValue",
                                                            value: true,
                                                        },
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "unconfirmed",
                                                        },
                                                        value: {
                                                            kind: "BooleanValue",
                                                            value: true,
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byStartDateAfter",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "now",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "1" },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "order" },
                                value: { kind: "EnumValue", value: "ASC" },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "referenceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "bookings",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "created",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenantId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "startDt",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "endDt",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "confirmed",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "cancelled",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "service",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "serviceMode",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "categoryName",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "resources",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "resourceId",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "resourceName",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "metadata",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myGames" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "startDateAfter",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "now",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "1" },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "order" },
                                value: { kind: "EnumValue", value: "ASC" },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "location",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenantId",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "categoryId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "players",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "displayPicture",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "maxPlayers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "reservedPlayers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "organizer",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "organizerId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetMyDetailsUpcomingBookingQuery,
    GetMyDetailsUpcomingBookingQueryVariables
>;
export const GetMyDetailsLastBookingDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getMyDetailsLastBooking" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myGroupedBookings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byStatus",
                                            },
                                            value: {
                                                kind: "ObjectValue",
                                                fields: [
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "confirmed",
                                                        },
                                                        value: {
                                                            kind: "BooleanValue",
                                                            value: true,
                                                        },
                                                    },
                                                    {
                                                        kind: "ObjectField",
                                                        name: {
                                                            kind: "Name",
                                                            value: "unconfirmed",
                                                        },
                                                        value: {
                                                            kind: "BooleanValue",
                                                            value: true,
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "1" },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "order" },
                                value: { kind: "EnumValue", value: "DESC" },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "referenceId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "bookings",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "uid",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "created",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenantId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "startDt",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "endDt",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "confirmed",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "cancelled",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "service",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "uid",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "serviceMode",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "categoryName",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "resources",
                                                                        },
                                                                        selectionSet:
                                                                            {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                    [
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "resourceId",
                                                                                            },
                                                                                        },
                                                                                        {
                                                                                            kind: "Field",
                                                                                            name: {
                                                                                                kind: "Name",
                                                                                                value: "resourceName",
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                            },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "metadata",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetMyDetailsLastBookingQuery,
    GetMyDetailsLastBookingQueryVariables
>;
export const GetMyDetailsLastGameDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "getMyDetailsLastGame" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myGames" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "1" },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "order" },
                                value: { kind: "EnumValue", value: "DESC" },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "location",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "tenantId",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "startDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "endDt",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "archived",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "categoryId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "metadata",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "players",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "displayPicture",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "maxPlayers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "reservedPlayers",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "organizer",
                                                            },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "organizerId",
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: {
                                                                            kind: "Name",
                                                                            value: "name",
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetMyDetailsLastGameQuery,
    GetMyDetailsLastGameQueryVariables
>;
export const PelangganUserInvoiceDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganUserInvoice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "invoiceId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "invoiceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "amountPaid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cancelled" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoiceeName",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "printNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "isCourtsiteOwned",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenant" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "description",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "perUnitPrice",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "quantity",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "subtotal",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityType",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "metadata",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "payments" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "amount",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentType",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethod",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserInvoiceQuery,
    PelangganUserInvoiceQueryVariables
>;
export const PelangganUserInvoiceSubmissionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganUserInvoiceSubmission" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "lhdnSubmissionByInvoiceId",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "invoiceId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "invoiceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserInvoiceSubmissionQuery,
    PelangganUserInvoiceSubmissionQueryVariables
>;
export const PelangganUserInvoiceBookingsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganUserInvoiceBookings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenantId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bookingIds" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "ListType",
                            type: {
                                kind: "NonNullType",
                                type: {
                                    kind: "NamedType",
                                    name: { kind: "Name", value: "ID" },
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "bookingsByIds" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "bookingIds" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "bookingIds" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "referenceId",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "startDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "endDt" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "service" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "serviceMode",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resources" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "archived",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "resource",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetails",
                                    },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingSummaryBookingDetails",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "organisation" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "uid" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "services" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetailsCentre",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "FragmentSpread",
                                    name: {
                                        kind: "Name",
                                        value: "BookingDetailsCategories",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addOns" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tenantId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "AddOns" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "startDt" } },
                    { kind: "Field", name: { kind: "Name", value: "endDt" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "service" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceMode",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "serviceCategory",
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "categoryId",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "resources" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "archived" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "resource" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "name",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addons" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addonId" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "perUnitPrice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingSummaryBookingDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Booking" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "addons" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "perUnitPrice",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quantity" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetailsCentre" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Organisation" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "isCourtsitePartner" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "BookingDetailsCategories" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Category" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "metadata" },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "AddOns" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "AddOn" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserInvoiceBookingsQuery,
    PelangganUserInvoiceBookingsQueryVariables
>;
export const ValidateTaxpayerTinDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "ValidateTaxpayerTin" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tin" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "idType" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "IdType" },
                        },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "idValue" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "validateTaxpayerTin" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "tin" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "tin" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "idType" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "idType" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "idValue" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "idValue" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "TaxPayerTINValidated",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "tin",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "idType",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "idValue",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InternalError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ValidateTaxpayerTinQuery,
    ValidateTaxpayerTinQueryVariables
>;
export const InvoiceLhdnRequestDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "invoiceLHDNRequest" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "invoiceId" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "ID" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "invoiceId" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "invoiceId" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tenantId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "total" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "outstandingBalance",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "payments" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "created",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "cancelled",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentType",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "paymentMethod",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "name",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InvoiceLhdnRequestQuery,
    InvoiceLhdnRequestQueryVariables
>;
export const PelangganStartEInvoiceSubmissionDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "pelangganStartEInvoiceSubmission" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "req" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "StartSubmitLHDNEInvoicesRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "startSubmitLHDNEInvoices",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "req" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganStartEInvoiceSubmissionMutation,
    PelangganStartEInvoiceSubmissionMutationVariables
>;
export const PelangganUserInvoicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganUserInvoices" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "InvoiceStatus" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "cursor" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "String" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoicesPaged" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byStatus",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "status",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: { kind: "IntValue", value: "10" },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "after" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "cursor" },
                                },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "order" },
                                value: { kind: "EnumValue", value: "DESC" },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "edges" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "node",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "created",
                                                            },
                                                        },
                                                        {
                                                            kind: "FragmentSpread",
                                                            name: {
                                                                kind: "Name",
                                                                value: "UserInvoicesDetails",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "pageInfo" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "hasNextPage",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "endCursor",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "UserInvoicesDetails" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "Invoice" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "uid" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "outstandingBalance" },
                    },
                    { kind: "Field", name: { kind: "Name", value: "total" } },
                    { kind: "Field", name: { kind: "Name", value: "created" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "tenant" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserInvoicesQuery,
    PelangganUserInvoicesQueryVariables
>;
export const PelangganUserInvoiceStatisticsDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganUserInvoiceStatistics" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startOfYear" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "DateTime" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pastStartOfYear" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "DateTime" },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "pastEndOfYear" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "DateTime" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "currentYear" },
                        name: { kind: "Name", value: "myInvoiceStatistics" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCreatedAfter",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "startOfYear",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoicePerYear",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "pastYear" },
                        name: { kind: "Name", value: "myInvoiceStatistics" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCreatedAfter",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "pastStartOfYear",
                                                },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: {
                                                kind: "Name",
                                                value: "byCreatedBefore",
                                            },
                                            value: {
                                                kind: "Variable",
                                                name: {
                                                    kind: "Name",
                                                    value: "pastEndOfYear",
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "invoicePerYear",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserInvoiceStatisticsQuery,
    PelangganUserInvoiceStatisticsQueryVariables
>;
export const PelangganUserProfileSetUserProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "pelangganUserProfileSetUserProfile" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "request" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: {
                                kind: "Name",
                                value: "SetUserProfileRequest",
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "setUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "request" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "request" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "UserProfileSet",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "user",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "firebaseId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "AccessError",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: {
                                            kind: "Name",
                                            value: "InvalidValue",
                                        },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "message",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserProfileSetUserProfileMutation,
    PelangganUserProfileSetUserProfileMutationVariables
>;
export const PelangganUserProfileDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganUserProfile" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myProfile" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firebaseId" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "phoneNumber",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gender" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "dateOfBirth",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postCode" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "preferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "otherPreferredSports",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "sportsFrequency",
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserProfileQuery,
    PelangganUserProfileQueryVariables
>;
export const PelangganUserUpcomingCategoriesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "pelangganUserUpcomingCategories" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "categories" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PelangganUserUpcomingCategoriesQuery,
    PelangganUserUpcomingCategoriesQueryVariables
>;
export const BookAgainSectionUpcomingQueryDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "bookAgainSectionUpcomingQuery" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "limit" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "Int" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: {
                            kind: "Name",
                            value: "tenantIdsFromPastBookings",
                        },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "limit" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "limit" },
                                },
                            },
                        ],
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "courtsitePartners" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "metadata" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    BookAgainSectionUpcomingQueryQuery,
    BookAgainSectionUpcomingQueryQueryVariables
>;
export const UserUpcomingBookingsInvoicesDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "userUpcomingBookingsInvoices" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "filter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "MyInvoicesFilter" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myInvoices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "filter" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "filter" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uid" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "uid",
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "relatedEntity",
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "uid",
                                                            },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: {
                                                                kind: "Name",
                                                                value: "entityId",
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserUpcomingBookingsInvoicesQuery,
    UserUpcomingBookingsInvoicesQueryVariables
>;
export const UserUpcomingBookingCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "userUpcomingBookingCount" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startsAfter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "DateTime" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myBookingFilterCount" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "startsAfter" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "startsAfter",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "bookingCount",
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserUpcomingBookingCountQuery,
    UserUpcomingBookingCountQueryVariables
>;
export const UserUpcomingGameCountDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "userUpcomingGameCount" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "startsAfter" },
                    },
                    type: {
                        kind: "NamedType",
                        name: { kind: "Name", value: "DateTime" },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "myGameFilterCount" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "startsAfter" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "startsAfter",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "gameCount" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "categoryId" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserUpcomingGameCountQuery,
    UserUpcomingGameCountQueryVariables
>;
